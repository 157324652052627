import Field from 'components/Field';
import React from 'react';
import { useController } from 'react-hook-form';

import SlateEditor from './Editor';

const Editor = ({ name, control, /* maxLength, */ ...props }) => {
	const {
		field: { ref, value, onChange },
	} = useController({
		name,
		control,
		// This somehow limits the Length but gives no error, just blocks the form submit.
		// TODO: Investigate how to focus and show an error (tried playing with ref, but nothing worked)
		// rules: { maxLength: { value: maxLength, message: `Máximo ${maxLength} carácteres` } },
	});
	/* The received <Editor> value is a "stringified" JSON. Here we must do all the
	 * JSON.stringify & JSON.parse work, as SlateEditor need the object structure.
	 */
	let editorValue =
		typeof value === 'string'
			? value !== ''
				? JSON.parse(value)
				: [{ type: 'paragraph', children: [{ text: '' }] }]
			: value;
	return (
		<Field {...props}>
			<SlateEditor value={editorValue} onChange={e => onChange(JSON.stringify(e))} inputRef={ref} />
		</Field>
	);
};

export default Editor;
