import Field from 'components/Field';
import React from 'react';
import { useController } from 'react-hook-form';

const SelectForm = ({
	name,
	control,
	register = control.register,
	options,
	placeholder,
	width = 'w-48',
	disabled = false,
	type,
	...props
}) => {
	let { field } = useController({ name, control });

	if (type === 'number') {
		field = {
			...field,
			...register(name, {
				valueAsNumber: true,
			}),
		};
	} else if (type === 'boolean') {
		field = {
			...field,
			...register(name, {
				setValueAs: v => (typeof v === 'string' ? v === 'true' : v),
			}),
		};
	}

	return (
		<Field disabled={disabled} {...props}>
			<select
				{...field}
				{...props}
				className={`border border-coral-300 rounded-md mt-1 p-2 outline-none ${width}  ${
					disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
				} ${props.className || ''}`}
				placeholder={placeholder}
				disabled={disabled}
			>
				{placeholder && <option value=''>{placeholder}</option>}
				{options.map((option, index) => (
					<option value={returnValue(option)} key={option.id || index}>
						{option.name || option.commercialName}
					</option>
				))}
			</select>
		</Field>
	);
};

const returnValue = option => {
	if (typeof option === 'object') {
		if (Object.prototype.hasOwnProperty.call(option, 'value')) return option.value;
		if (Object.prototype.hasOwnProperty.call(option, 'id')) return option.id;
	}
	return option;
};

export default SelectForm;
