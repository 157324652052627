import { gql, useMutation, useQuery } from '@apollo/client';

export const PRODUCTCATEGORY_FRAGMENT = /* GraphQL */ `
	fragment ProductCategory on ProductCategory {
		id
		name
		slug
		content
		path
		ParentId
		Image {
			id
			src
			title
			alt
		}
	}
`;

const CATEGORYINFO_FRAGMENT = gql`
	fragment CategoryInfo on ProductCategory {
		id
		name
	}
`;

const PRODUCTCATEGORIES_QUERY = gql`
	query ProductCategories($locale: String) {
		ProductCategories(locale: $locale) {
			...CategoryInfo
			Parent {
				...CategoryInfo
				Parent {
					...CategoryInfo
					Parent {
						...CategoryInfo
						Parent {
							...CategoryInfo
						}
					}
				}
			}
			Children {
				...CategoryInfo
				Children {
					...CategoryInfo
					Children {
						...CategoryInfo
						Children {
							...CategoryInfo
						}
					}
				}
			}
		}
	}
	${CATEGORYINFO_FRAGMENT}
`;

const PRODUCTCATEGORYCREATE_MUTATION = gql`
	mutation ProductCategoryCreate($input: ProductCategoryCreateInput!) {
		ProductCategoryCreate(input: $input) {
			... on ProductCategory {
				...ProductCategory
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${PRODUCTCATEGORY_FRAGMENT}
`;

const PRODUCTCATEGORYUPDATE_MUTATION = gql`
	mutation ProductCategoryUpdate($id: ID!, $input: ProductCategoryCreateInput!, $locale: String!) {
		ProductCategoryUpdate(id: $id, input: $input, locale: $locale) {
			... on ProductCategory {
				...ProductCategory
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${PRODUCTCATEGORY_FRAGMENT}
`;

const PRODUCTCATEGORYDELETE_MUTATION = gql`
	mutation ProductCategoryDelete($id: ID!) {
		ProductCategoryDelete(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const SINGLEPRODUCTCATEGORY_QUERY = gql`
	query ProductCategory($id: ID, $slug: String, $locale: String) {
		ProductCategory(id: $id, slug: $slug, locale: $locale) {
			...ProductCategory
		}
	}
	${PRODUCTCATEGORY_FRAGMENT}
`;

const MAINPRODUCTOCATEGORIES_QUERY = gql`
	query ProductCategories {
		ProductCategories {
			value: id
			name
		}
	}
`;

export const plainCategoriesChildren = (options = [], previusText = '', inner = 0) => {
	const plain = [];

	for (const op of options) {
		plain.push({ ...op, name: `${previusText && `${previusText}`}${op.name}`, value: op.id });

		if (op?.Parent !== null) {
			plain.push(
				...plainCategoriesChildren(op.Parent, `${previusText && `${previusText}`}${op.name} / `, inner + 1)
			);
		}

		if (op?.Children?.length > 0) {
			plain.push(
				...plainCategoriesChildren(op.Children, `${previusText && `${previusText}`}${op.name} / `, inner + 1)
			);
		}
	}

	return plain;
};

export const categoriesSimpleTree = (parentPath, categories = []) => {
	return categories.map(category => {
		const path = parentPath ? `${parentPath}/${category.id}` : `${category.id}`;
		return category.Children?.length > 0
			? {
					key: category.id,
					label: category.name,
					nodes: categoriesSimpleTree(path, category.Children),
					path: path,
			  }
			: { key: category.id, label: category.name, path: path };
	});
};

// refetchQueries: ['ProductCategories'],
export const useProductCategoryCreateMutation = (props, locale) =>
	useMutation(PRODUCTCATEGORYCREATE_MUTATION, { ...props, variables: { locale } });
export const useProductCategoryUpdateMutation = (props, locale) =>
	useMutation(PRODUCTCATEGORYUPDATE_MUTATION, { ...props, variables: { locale } });
export const useProductCategoryDeleteMutation = id =>
	useMutation(PRODUCTCATEGORYDELETE_MUTATION, { variables: { id }, refetchQueries: ['ProductCategories'] });
export const useProductCategory = (id, locale) =>
	useQuery(SINGLEPRODUCTCATEGORY_QUERY, { variables: { id, locale }, fetchPolicy: 'cache-and-network' });
export const useMainProductCategories = () => useQuery(MAINPRODUCTOCATEGORIES_QUERY, { fetchPolicy: 'cache-first' });
export const useProductCategories = (props, locale) =>
	useQuery(PRODUCTCATEGORIES_QUERY, { ...props, variables: { locale } });
