import { gql, useLazyQuery, useMutation } from '@apollo/client';

const CONFIGURATION_INFO_FRAGMENT = gql`
	fragment ConfigurationInfo on Configuration {
		id
		description
		enumValues
		key
		valueType
		valuePlainText
		valueTranslatableText
		ValueConfigurationImage {
			id
			src
			title
			alt
		}
	}
`;

const CONFIGURATIONS_QUERY = gql`
	query Configurations($searchByDescription: String, $locale: String) {
		Configurations(searchByDescription: $searchByDescription, locale: $locale) {
			... on Configuration {
				...ConfigurationInfo
			}
		}
	}
	${CONFIGURATION_INFO_FRAGMENT}
`;

const UPDATE_CONFIGURATION_MUTATION = gql`
	mutation ConfigurationUpdate($configurationId: ID!, $input: ConfigurationUpdateInput!, $locale: String) {
		ConfigurationUpdate(configurationId: $configurationId, input: $input, locale: $locale) {
			... on Configuration {
				...ConfigurationInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${CONFIGURATION_INFO_FRAGMENT}
`;

export const useLazyConfigurations = props =>
	useLazyQuery(CONFIGURATIONS_QUERY, {
		...props,
		fetchPolicy: 'cache-and-network',
	});

export const useUpdateConfiguration = props => useMutation(UPDATE_CONFIGURATION_MUTATION, props);
