import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const PRODUCTINFO_FRAGMENT = gql`
	fragment ProductInfo on Product {
		id
		status
		sendByMerchant
		promote
		highlight
		title
		createdAt
		updatedAt
		Variants {
			price
		}
		Categories {
			id
			value: id
			name
		}
		DefaultImage {
			id
			src
			title
			alt
		}
		Merchant {
			id
			commercialName
		}
	}
`;

const FULLPRODUCTINFO_FRAGMENT = gql`
	fragment FullProductInfo on Product {
		id
		title
		subtitle
		description
		instructions
		sendByMerchant
		promote
		highlight
		status
		handle
		CategoryId
		Variants {
			id
			barcode
			discountedPrice
			isAvailable
			outOfSeasson
			limitedProduction
			price
			title
			sku
		}
		DefaultImage {
			id
			filename
			src
			title
			alt
		}
		Images {
			id
			filename
			src
			title
			alt
		}
		Merchant {
			id
			administrativeEmail
			commercialName
		}
		vendor
		video
		unit
		seoTitle
		seoDescription
		brand
		type
		contactInstructions
		vat
		otherFeatures
		createdAt
		updatedAt
	}
`;

const EXPORT_FULL_PRODUCT_INFO_FRAGMENT = gql`
	fragment ExportFullProductInfo on Product {
		id
		title
		promote
		highlight
		status
		handle
		Categories {
			name
		}
		Variants {
			barcode
			discountedPrice
			isAvailable
			price
			title
			sku
		}
		unit
		brand
		type
		vat
		createdAt
		updatedAt
	}
`;

const PRODUCTS_QUERY = gql`
	query Products(
		$search: String
		$scope: String
		$order_scope: String
		$promote: Boolean
		$highlight: Boolean
		$CategoryId: ID
		$limit: Int
		$offset: Int
		$locale: String
	) {
		Products(
			search: $search
			scope: $scope
			order_scope: $order_scope
			promote: $promote
			highlight: $highlight
			CategoryId: $CategoryId
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on ProductList {
				count
				hasMore
				List {
					...ProductInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${PRODUCTINFO_FRAGMENT}
`;

const EXPORT_PRODUCTS_QUERY = gql`
	query Products(
		$search: String
		$scope: String
		$order_scope: String
		$promote: Boolean
		$highlight: Boolean
		$CategoryId: ID
		$limit: Int = 0
		$offset: Int = 0
		$locale: String
	) {
		Products(
			search: $search
			scope: $scope
			order_scope: $order_scope
			promote: $promote
			highlight: $highlight
			CategoryId: $CategoryId
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on ProductList {
				List {
					...ExportFullProductInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${EXPORT_FULL_PRODUCT_INFO_FRAGMENT}
`;

const PRODUCTCREATE_MUTATION = gql`
	mutation ProductCreateMutation($input: ProductInput!, $locale: String) {
		ProductCreate(input: $input, locale: $locale) {
			... on Product {
				...ProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${PRODUCTINFO_FRAGMENT}
`;

const PRODUCT_DUPLICATE_MUTATION = gql`
	mutation ProductDuplicateMutation($productId: ID!) {
		ProductDuplicate(productId: $productId) {
			... on Product {
				...ProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${PRODUCTINFO_FRAGMENT}
`;

const PRODUCTUPDATE_MUTATION = gql`
	mutation ProductUpdateMutation($id: ID!, $input: ProductInput!, $locale: String) {
		ProductUpdate(id: $id, input: $input, locale: $locale) {
			... on Product {
				...FullProductInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${FULLPRODUCTINFO_FRAGMENT}
`;

const PRODUCTS_UPDATE_MUTATION = gql`
	mutation ProductsUpdate($productsId: [ID!], $input: ProductInput!) {
		ProductsUpdate(productsId: $productsId, input: $input) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const SINGLEPRODUCT_QUERY = gql`
	query Product($id: ID!, $locale: String!) {
		Product(id: $id, locale: $locale) {
			...FullProductInfo
		}
	}
	${FULLPRODUCTINFO_FRAGMENT}
`;

export const useProduct = (id, locale) =>
	useQuery(SINGLEPRODUCT_QUERY, { variables: { id, locale }, fetchPolicy: 'cache-and-network' });
export const useProducts = (search, scope, limit, offset, locale) =>
	useQuery(PRODUCTS_QUERY, { variables: { search, scope, limit, offset, locale }, fetchPolicy: 'cache-and-network' });
export const useLazyProducts = variables =>
	useLazyQuery(PRODUCTS_QUERY, {
		variables,
		fetchPolicy: 'cache-and-network',
	});
export const useLazyExportProducts = variables =>
	useLazyQuery(EXPORT_PRODUCTS_QUERY, {
		variables,
		fetchPolicy: 'no-cache',
	});
export const useCreateProduct = props => useMutation(PRODUCTCREATE_MUTATION, props);
export const useDuplicateProduct = props => useMutation(PRODUCT_DUPLICATE_MUTATION, props);
export const useUpdateProduct = props => useMutation(PRODUCTUPDATE_MUTATION, props);
export const useUpdateProducts = props => useMutation(PRODUCTS_UPDATE_MUTATION, props);
