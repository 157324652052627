import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import LayoutHeader from 'components/LayoutHeader';
import CustomerAddress from './CustomerAddress';
import CustomerAccountDetails from './CustomerAccountDetails';
import { useCustomer } from 'hooks/use-customers';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from 'components/Toast';

const CustomerDetails = () => {
	const navigate = useNavigate();
	const { customerId } = useParams();
	const { data } = useCustomer({ customerId });
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [customerData, setcustomerDataResponse] = useState(null);

	useEffect(() => {
		if (data) {
			if (data.Customer?.__typename === 'AuthorizationFault') {
				addErrorMessage('Cliente', data.Customer?.message);
			}
			// All Errors implement Fault
			else if (data.Customer?.__typename.endsWith('Fault')) {
				addErrorMessage('Cliente', data.Customer?.message);
			} else if (!data.Customer) {
				addErrorMessage('Cliente', 'Error: No se encontró cliente');
				navigate('/dashboard/clientes');
			}
			if (data.Customer?.__typename === 'User') {
				setcustomerDataResponse(data?.Customer);
			}
		}
	}, [data, addSuccessMessage, addErrorMessage, navigate]);

	return (
		<AuthorizationBoundary for={['admin', 'merchant']}>
			<Card>
				{customerData && (
					<>
						<LayoutHeader>Perfil del cliente</LayoutHeader>
						<CustomerAccountDetails title='Datos de la cuenta' customer={customerData} />
						<CustomerAddress title='Dirección de envío' address={customerData?.ContactAddress} />
						<CustomerAddress title='Dirección de facturación' address={customerData?.BillAddress} />
					</>
				)}
			</Card>
		</AuthorizationBoundary>
	);
};

export default CustomerDetails;
