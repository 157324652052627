import Button from 'components/Button';
import DropImageArea from 'components/DropfileArea';
import Editor from 'components/Editor';
import Input from 'components/Input';
import InputForm from 'components/InputForm';
import LayoutHeader from 'components/LayoutHeader';
import LayoutTitle from 'components/LayoutTitle';
import Schedule from 'components/Schedule';
import Score from 'components/Score';
import { useToast } from 'components/Toast';
import { useUpdateMerchant } from 'hooks/use-merchants';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'hooks/use-auth';

// ComercialContent subfields that are of type editor
const contentOfTypeEditor = new Set(['description', 'content1', 'content2', 'content3']);

const semiParseJSON = content => {
	// First Parse the whole JSON if needed
	let objRes = typeof content === 'string' && content !== '' ? JSON.parse(content) : content;

	// Stringify the specified keys
	for (var [key, value] of Object.entries(objRes)) {
		if (contentOfTypeEditor.has(key) && typeof value !== 'string') objRes[key] = JSON.stringify(value);
	}

	return objRes;
};

const semiStringifyJSON = content => {
	// First Parse the contentOfTypeEditor keys (as they are already stringified)
	for (var [key, value] of Object.entries(content)) {
		if (contentOfTypeEditor.has(key) && typeof value === 'string') content[key] = JSON.parse(value);
	}

	// Now stringify the whole object in one go
	return JSON.stringify(content);
};

const CommercialForm = ({ className, merchantData, setDirty = () => {} }) => {
	const { locale } = useAuth();
	const disableNonTranslatableFields = locale !== process.env.REACT_APP_DEFAULT_LOCALE;
	const [update] = useUpdateMerchant();

	// Images Upload States
	const [commercialLogo, setCommercialLogo] = useState(null);
	const [featuredImage, setFeaturedImage] = useState(null);
	const [Images, setImages] = useState([]);

	// Component States
	const [isFormFilled, setIsFormFilled] = useState(false);
	const [updateMerchantResponse, setUpdateMerchantResponse] = useState(null);
	const [score, setScore] = useState(0);

	// Toast alert
	const { addSuccessMessage, addErrorMessage } = useToast();

	const {
		unregister,
		register,
		handleSubmit,
		getValues,
		control,
		setValue,
		watch,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			commercialForm: {
				commercialAddress1: '',
				commercialAddress2: '',
				commercialCity: '',
				commercialContent: {
					content1: '[{"type": "paragraph", "children": [{ "text": "" }]}]',
					content2: '[{"type": "paragraph", "children": [{ "text": "" }]}]',
					content3: '[{"type": "paragraph", "children": [{ "text": "" }]}]',
					description: '[{"type": "paragraph", "children": [{ "text": "" }]}]',
					title1: '',
					title2: '',
					title3: '',
				},
				commercialCountry: 'ES',
				commercialMaps: '',
				commercialName: '',
				commercialPhone: '',
				commercialProvince: '',
				commercialRequiredDescription: '[{"type": "paragraph", "children": [{ "text": "" }]}]',
				commercialSchedule:
					'[{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": true},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": true}]',
				commercialVideo: '',
				commercialZip: '',
				CommercialLogoId: null,
				Images: [],
				FeaturedImageId: null,
			},
		},
	});

	// Is used to delete a single image id from commercialForm.Images
	useEffect(() => {
		unregister('commercialForm.Images');
		setValue(
			'commercialForm.Images',
			Images.map(image => image.id)
		);
	}, [Images, setValue, unregister]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	// Load Edit data
	useEffect(() => {
		if (merchantData) {
			// Extract JSON and stringify the Editor values (as the Editor expects values stingified)
			const commercialContent = semiParseJSON(merchantData.commercialContent);
			const commercialSchedule =
				merchantData.commercialSchedule ||
				'[{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": false},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": true},{"morningStart": "08:00","morningEnd": "12:00","afternoonStart": "15:00","afternoonEnd": "19:00","closed": true}]';

			setValue('commercialForm', {
				commercialAddress1: merchantData.commercialAddress1 || '',
				commercialAddress2: merchantData.commercialAddress2 || '',
				commercialCity: merchantData.commercialCity || '',
				commercialContent: commercialContent,
				commercialCountry: 'ES',
				commercialMaps: merchantData.commercialMaps || '',
				commercialName: merchantData.commercialName || '',
				commercialPhone: merchantData.commercialPhone || '',
				commercialProvince: merchantData.commercialProvince || '',
				commercialRequiredDescription: merchantData.commercialRequiredDescription,
				commercialSchedule: commercialSchedule,
				commercialVideo: merchantData.commercialVideo || '',
				commercialZip: merchantData.commercialZip || '',
				FeaturedImageId: merchantData.FeaturedImage?.id || null,
				CommercialLogoId: merchantData.CommercialLogo?.id || null,
				Images: merchantData?.Images.map(image => image.id) || [],
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImages(merchantData.Images.map(image => ({ ...image })));
			setCommercialLogo({ ...merchantData.CommercialLogo });
			setFeaturedImage({ ...merchantData.FeaturedImage });
			setDirty(false);
			setIsFormFilled(true);
		}
	}, [merchantData, setImages, setCommercialLogo, setFeaturedImage, setValue, setDirty]);

	// Using calculateFormScore in combination with watch "score" state
	const calculateFormScore = formData => {
		if (formData) {
			let total = Object.entries(formData).length + Object.entries(formData.commercialContent).length;
			let count = 0;

			for (var [key, value] of Object.entries(formData)) {
				if (key === 'commercialContent') {
					for (var [, commercialContentValue] of Object.entries(value)) {
						if (commercialContentValue) {
							count++;
						}
					}
				}
				if (value) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};

	watch(({ commercialForm }) => {
		calculateFormScore(commercialForm);
	});

	// Response update States
	useEffect(() => {
		if (updateMerchantResponse) {
			if (updateMerchantResponse.__typename === 'MerchantUpdate') {
				addSuccessMessage('Ficha Comercial', 'actualizada correctamente');
				setDirty(false);
			}
			// All Errors implement Fault
			else if (updateMerchantResponse.__typename.endsWith('Fault')) {
				addErrorMessage('Ficha Comercial', updateMerchantResponse.message);
			}
		}
	}, [updateMerchantResponse, addSuccessMessage, addErrorMessage, setDirty]);

	// Handlers
	const handleCommercialLogo = useCallback(
		([newImage]) => {
			setCommercialLogo(newImage);
			setValue('commercialForm.CommercialLogoId', newImage.id);
		},
		[setCommercialLogo, setValue]
	);

	const handleFeaturedImage = useCallback(
		([newImage]) => {
			setFeaturedImage(newImage);
			setValue('commercialForm.FeaturedImageId', newImage.id);
		},
		[setFeaturedImage, setValue]
	);

	const handleImages = useCallback(
		_newData => {
			const prevImages = getValues('commercialForm.Images') || [];
			setValue('commercialForm.Images', [
				...prevImages,
				..._newData.map(image => image.id).filter(imageId => !prevImages.includes(imageId)),
			]);
			setImages(prev => [
				...prev,
				// Add images that are not already added
				..._newData.filter(image => !prev.some(prevImage => prevImage.id === image.id)),
			]);
		},
		[setImages, setValue, getValues]
	);

	return (
		isFormFilled && (
			<div className={className}>
				<Score data={score} name='comercial' content='' />
				<LayoutHeader>Ficha comercial</LayoutHeader>
				<p>Ficha comercial que aparecerá en la parte pública de la web.</p>
				<br />
				<LayoutTitle>Descripción</LayoutTitle>
				<form
					onSubmit={handleSubmit(({ commercialForm }) => {
						update({
							variables: {
								id: merchantData?.id,
								input: {
									...commercialForm,
									commercialContent: semiStringifyJSON(commercialForm.commercialContent),
								},
								locale,
							},
						}).then(res => {
							setUpdateMerchantResponse(res.data.MerchantUpdate);
						});
					})}
					encType='multipart/form-data'
				>
					<InputForm
						name='commercialForm.commercialName'
						control={control}
						register={register}
						label='Nombre comercial'
						placeholder='Nombre comercial'
					/>
					<DropImageArea
						label='Logo de empresa'
						whileIdleText='Selecciona logo de la empresa'
						Images={[commercialLogo]}
						onChange={handleCommercialLogo}
						onDelete={() => {
							setValue('commercialForm.CommercialLogoId', null);
							setCommercialLogo(null);
						}}
					/>
					<div className='flex w-full max-w-screen-md '>
						<div className='w-1/3'></div>
						<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 160 caracteres con espacios</p>
					</div>
					<Editor
						name='commercialForm.commercialRequiredDescription'
						control={control}
						label='Descripción (obligatoria)'
						placeholder='Descripción (obligatoria)'
						maxLength='160'
						description='Información para mostrar en buscadores (Google)'
						descriptionClassName='italic font-normal'
					/>
					<div className='flex w-full max-w-screen-md '>
						<div className='w-1/3'></div>
						<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 600 caracteres con espacios</p>
					</div>
					<Editor
						name='commercialForm.commercialContent.description'
						control={control}
						label='Introducción'
						placeholder='Introducción (obligatoria)'
						maxLength='600'
						description='Presentación Empresa (obligatoria)'
						descriptionClassName='italic font-normal'
					/>
					<br />
					<p>
						Completar los distintos bloques con contenido diferenciado. Por ejemplo, cómo se elaboran los
						productos, historia de la empresa, características de la producción, diferencias...
					</p>
					<br />
					<InputForm
						name='commercialForm.commercialContent.title1'
						control={control}
						register={register}
						label='Título 1'
						placeholder='Título 1'
					/>
					<div className='flex w-full max-w-screen-md '>
						<div className='w-1/3'></div>
						<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 3.000 caracteres con espacios</p>
					</div>
					<Editor
						name='commercialForm.commercialContent.content1'
						control={control}
						label='Contenido 1'
						placeholder='Contenido 1'
						maxLength='3000'
					/>
					<InputForm
						name='commercialForm.commercialContent.title2'
						control={control}
						register={register}
						label='Título 2'
						placeholder='Título 2'
					/>
					<div className='flex w-full max-w-screen-md '>
						<div className='w-1/3'></div>
						<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 3.000 caracteres con espacios</p>
					</div>
					<Editor
						name='commercialForm.commercialContent.content2'
						control={control}
						label='Contenido 2'
						placeholder='Contenido 2'
						maxLength='3000'
					/>
					<InputForm
						name='commercialForm.commercialContent.title3'
						control={control}
						register={register}
						label='Título 3'
						placeholder='Título 3'
					/>
					<div className='flex w-full max-w-screen-md '>
						<div className='w-1/3'></div>
						<p className='w-2/3 text-right text-gray-400 italic'>*Máximo 3.000 caracteres con espacios</p>
					</div>
					<Editor
						name='commercialForm.commercialContent.content3'
						control={control}
						label='Contenido 3'
						placeholder='Contenido 3'
						maxLength='3000'
					/>
					<br />
					<LayoutTitle>Datos de contacto</LayoutTitle>
					<div className='flex items-center w-full max-w-screen-md mb-4 '>
						<label className='font-semibold text-gray-700 text-lg w-1/3'>Horario</label>
					</div>
					<Schedule
						name='commercialForm.commercialSchedule'
						control={control}
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='commercialForm.commercialAddress1'
						control={control}
						register={register}
						label='Dirección Línea 1'
						placeholder='Dirección Línea 1'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='commercialForm.commercialAddress2'
						control={control}
						register={register}
						label='Dirección Línea 2'
						placeholder='Dirección Línea 2'
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='commercialForm.commercialZip'
						control={control}
						register={register}
						label='Código Postal'
						placeholder='Código Postal'
						pattern='[0-9]*'
						maxLength='5'
						minLength='5'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='commercialForm.commercialCity'
						control={control}
						register={register}
						label='Localidad'
						placeholder='Localidad'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='commercialForm.commercialProvince'
						control={control}
						register={register}
						label='Provincia'
						placeholder='Provincia'
						required
						disabled={disableNonTranslatableFields}
					/>
					<Input className='border-none' value='ES' label='País' readOnly />
					<InputForm
						name='commercialForm.commercialPhone'
						control={control}
						register={register}
						label='Teléfono'
						placeholder='Teléfono'
						type='tel'
						pattern='^\+?[0-9]*'
						maxLength='13'
						minLength='9'
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='commercialForm.commercialMaps'
						control={control}
						register={register}
						label='Enlace Google Maps'
						placeholder='Enlace Google Maps'
						disabled={disableNonTranslatableFields}
					/>
					<br />
					<LayoutTitle>Imágenes y video</LayoutTitle>
					<DropImageArea
						label='Imagen destacada'
						whileIdleText='Selecciona una imagen destacada para el Producto'
						Images={[featuredImage]}
						onChange={handleFeaturedImage}
						onDelete={() => {
							setValue('commercialForm.FeaturedImageId', null);
							setFeaturedImage(null);
						}}
					/>
					<DropImageArea
						label='Galería de la empresa'
						Images={Images}
						onChange={handleImages}
						onDelete={id => setImages(prev => [...prev.filter(i => i.id !== id)])}
						multiple
					/>
					<InputForm
						name='commercialForm.commercialVideo'
						control={control}
						register={register}
						label='Enlace a Youtube'
						placeholder='Enlace a Youtube'
					/>
					<div className='flex w-full pt-5 max-w-screen-md'>
						<div className='w-1/3'>
							<Button type='submit'>Actualizar ficha comercial</Button>
						</div>
					</div>
				</form>
			</div>
		)
	);
};

export default CommercialForm;
