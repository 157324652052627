import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

export const ORDER_FRAGMENT = /* GraphQL */ `
	fragment OrderInfo on Order {
		id
		name
		email
		authorizeProductSubstitution
		orderDate
		status
		billAddress1
		billAddress2
		billCity
		billCountry
		billName
		billNotes
		billPhone
		billProvince
		billVat
		billZip
		processingMethod
		shippingAddress1
		shippingAddress2
		shippingCity
		shippingCountry
		shippingName
		shippingNotes
		shippingPhone
		shippingPrice
		shippingProvince
		shippingVat
		shippingZip
		totalPrice
		totalDiscounts
		User {
			id
			firstName
			lastName
			userName
			email
		}
		MerchantShippings {
			OrderId
			note
			packages
			status
			number
			weight
			updatedAt
			Merchant {
				id
				commercialAddress1
				commercialAddress2
				commercialCity
				commercialCountry
				commercialName
				commercialPhone
				commercialProvince
				commercialZip
			}
			OrderItems {
				id
				discountedPrice
				Variant {
					id
					discountedPrice
					fulfillmentService
					grams
					price
					sku
					barcode
					isAvailable
					outOfSeasson
					limitedProduction
					title
					weight
					weightUnit
					Product {
						id
						title
					}
				}
				price
				productTitle
				quantity
				sent
				sku
				barcode
				substituted
				sentQuantity
			}
		}
		Events {
			id
			action
			category
			details
			message
			success
			createdAt
		}
	}
`;

const ORDER_QUERY = gql`
	query Order($orderId: ID!) {
		Order(orderId: $orderId) {
			...OrderInfo
		}
	}
	${ORDER_FRAGMENT}
`;

const ORDERS_QUERY = gql`
	query Orders($scope: String, $order_scope: String, $limit: Int, $offset: Int) {
		Orders(scope: $scope, order_scope: $order_scope, limit: $limit, offset: $offset) {
			... on OrdersList {
				count
				hasMore
				List {
					id
					orderDate
					status
					shippingPrice
					totalPrice
					totalDiscounts
					User {
						id
						firstName
						lastName
						userName
						email
					}
					Merchants {
						id
						commercialName
						administrativeBusinessName
					}
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
`;

const UPDATESHIPPINGORDERITEMS_MUTATION = gql`
	mutation UpdateShippingOrderItemsMutation(
		$OrderId: ID!
		$MerchantId: ID!
		$merchantShippingInput: MerchantShippingInput!
		$emptyShipping: Boolean
	) {
		UpdateMerchantShippingAndOrderItems(
			OrderId: $OrderId
			MerchantId: $MerchantId
			merchantShippingInput: $merchantShippingInput
			emptyShipping: $emptyShipping
		) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
			... on StripeFault {
				message
				stripeAccountId
			}
		}
	}
`;

export const useOrder = orderId => useQuery(ORDER_QUERY, { variables: { orderId }, fetchPolicy: 'network-only' });
export const useLazyOrder = variables => useLazyQuery(ORDER_QUERY, { variables, fetchPolicy: 'network-only' });
export const useOrders = variables => useQuery(ORDERS_QUERY, { variables, fetchPolicy: 'network-only' });
export const useLazyOrders = variables =>
	useLazyQuery(ORDERS_QUERY, {
		variables,
		fetchPolicy: 'network-only',
	});
export const useUpdateShippingOrderItems = props => useMutation(UPDATESHIPPINGORDERITEMS_MUTATION, props);

export default useOrders;
