import React from 'react';

const Check = ({ label, checked = false, name, className = '', onChange, disabled }) => (
	<span className={`flex items-center ${className}`}>
		<input
			className='h-5 w-5 cursor-pointer'
			type='checkbox'
			checked={checked}
			name={name}
			onClick={onChange}
			disabled={disabled}
			readOnly
		/>
		<label htmlFor={name} className='ml-2'>
			{label}
		</label>
	</span>
);

export default Check;
