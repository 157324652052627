import Apollo from 'components/Apollo';
import LoginForm from 'components/LoginForm';
import { AuthProvider } from 'hooks/use-auth';
import BaseLayout from 'layouts/BaseLayout';
import EmptyLayout from 'layouts/EmptyLayout';
import MyAccountForm from 'pages/Account/MyAccountForm';
import MyAdministrativeForm from 'pages/Account/MyAdministrativeForm';
import MyCommercialForm from 'pages/Account/MyCommercialForm';
import Categories from 'pages/Categories';
import CategoryEdit from 'pages/Categories/CategoryEdit';
import CategoryNew from 'pages/Categories/CategoryNew';
import Configuration from 'pages/Configuration';
import Customers from 'pages/Customers';
import CustomerDetails from 'pages/Customers/CustomerDetails';
import Dashboard from 'pages/Dashboard';
import FAQCreate from 'pages/FAQ/FAQCreate';
import FAQEdit from 'pages/FAQ/FAQEdit';
import FAQs from 'pages/FAQ/Faqs';
import Help from 'pages/Help/Help';
import HelpDetail from 'pages/Help/HelpDetail';
import Merchants from 'pages/Merchants';
import MerchantEdit from 'pages/Merchants/MerchantEdit';
import MerchantNew from 'pages/Merchants/MerchantNew';
import Orders from 'pages/Orders';
import OrderDetail from 'pages/Orders/OrderDetail';
import Products from 'pages/Products';
import ProductEdit from 'pages/Products/ProductEdit';
import ProductNew from 'pages/Products/ProductNew';
import RevisionEvents from 'pages/Revision/RevisionEvents';
import RevisionMerchants from 'pages/Revision/RevisionMerchants';
import RevisionProducts from 'pages/Revision/RevisionProducts';
import Statistics from 'pages/Statistics';
import Versions from 'pages/Versions/Versions';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';

const App = () => (
	<Apollo>
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route
						path='/login'
						element={
							<EmptyLayout>
								<div className='flex flex-1 min-h-full items-center'>
									<LoginForm />
								</div>
							</EmptyLayout>
						}
					/>
					<Route
						path='dashboard'
						element={
							<BaseLayout>
								<Outlet />
							</BaseLayout>
						}
					>
						<Route path='catalogo/productos/edit/:productId' element={<ProductEdit />} />
						<Route path='catalogo/productos/new' element={<ProductNew />} />
						<Route path='catalogo/productos' element={<Products />} />
						<Route path='catalogo/categorias/edit/:categoryId' element={<CategoryEdit />} />
						<Route path='catalogo/categorias/new' element={<CategoryNew />} />
						<Route path='catalogo/categorias' element={<Categories />} />
						<Route path='pedido/:orderId' element={<OrderDetail />} />
						<Route path='pedidos' element={<Orders />} />
						{/* <Route path='clientes/new'  element={
									<CustomerNew />
									}/> */}
						<Route path='clientes' element={<Customers />} />
						<Route path='clientes/:customerId' element={<CustomerDetails />} />
						<Route path='asociados/new' element={<MerchantNew />} />
						<Route path='asociados/list/:status' element={<Merchants />} />
						<Route path='asociados/edit/:merchantId' element={<MerchantEdit />} />
						<Route path='account' element={<MyAccountForm />} />
						<Route path='edit/administrative' element={<MyAdministrativeForm />} />
						<Route path='edit/commercial' element={<MyCommercialForm />} />
						<Route path='faqs/new' element={<FAQCreate />} />
						<Route path='faqs/edit/:faqId' element={<FAQEdit />} />
						<Route path='faqs' element={<FAQs />} />
						<Route path='help' element={<Help />} />
						<Route path='help/:helpId' element={<HelpDetail />} />
						<Route path='versions' element={<Versions />} />
						<Route path='revision/eventos' element={<RevisionEvents />} />
						<Route path='revision/productos' element={<RevisionProducts />} />
						<Route path='revision/asociados' element={<RevisionMerchants />} />
						<Route path='configuracion' element={<Configuration />} />
						<Route path='estadisticas' element={<Statistics />} />
						<Route index element={<Dashboard />} />
					</Route>
					<Route path='/' element={<Navigate to='/login' replace />} />
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	</Apollo>
);

export default App;
