import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Card from 'components/Card';
import ContentTable from './ContentTable';
import Currency from './Currency';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import Pagination from 'components/Pagination';
import { useToast } from 'components/Toast';

import printUserName from 'lib/printUserName';
import translasteStatus from 'lib/translasteStatus';
import columnTitleElement from 'lib/columnTitleElement';
import formatDate from 'lib/formatDate';

import useAuth from 'hooks/use-auth';
import { useLazyOrders } from 'hooks/use-orders';

const OrderList = ({ pageScope, title, statusList, paginationLimit = 50 }) => {
	const { addErrorMessage } = useToast();
	const { user } = useAuth();
	const [scope, setScope] = useState(pageScope || 'all');
	const [orderScope, setOrderScope] = useState('created_asc');
	const [offset, setOffset] = useState(0);
	const [orders, setOrders] = useState(null);
	const [getLazyOrders, { data, loading }] = useLazyOrders({
		scope,
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyOrders();
	}, [getLazyOrders]);

	useEffect(() => {
		if (data && data.Orders.__typename.endsWith('Fault')) {
			addErrorMessage('Pedidos', data?.Orders.message);
		} else {
			setOrders(data?.Orders);
		}
	}, [data, addErrorMessage]);

	const filterStatusList = () => {
		return statusList?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const orderByCustomer = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'email_asc' },
			{ label: 'Descendente', scope: 'email_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	const orderByTotal = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'totalPrice_asc' },
			{ label: 'Descendente', scope: 'totalPrice_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyOrders();
			},
		}));
	};

	return (
		<Card className='mb-20'>
			<LayoutHeader>{title}</LayoutHeader>
			<Pagination
				offset={offset}
				setOffset={setOffset}
				count={orders?.count}
				hasMore={orders?.hasMore}
				limit={paginationLimit}
			/>
			<LoadingView loading={loading} whileLoading='Buscando órdenes...'>
				<ContentTable
					columns={
						user.type === 'admin'
							? [
									columnTitleElement('Nº Pedido'),
									columnTitleElement('Cliente', orderByCustomer),
									columnTitleElement('Proveedor'),
									columnTitleElement('Fecha', orderByDate),
									columnTitleElement('Estado', filterStatusList),
									columnTitleElement('Total', orderByTotal),
									'',
							  ]
							: [
									columnTitleElement('Nº Pedido'),
									columnTitleElement('Cliente', orderByCustomer),
									columnTitleElement('Fecha', orderByDate),
									columnTitleElement('Estado', filterStatusList),
									columnTitleElement('Total', orderByTotal),
									'',
							  ]
					}
					content={orders?.List}
					render={(order, index) =>
						order && (
							<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
								<td className='px-4 py-2'>
									<Link
										className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
										to={`/dashboard/pedido/${order.id}`}
									>
										{order.id}
									</Link>
								</td>
								<td className='px-4 py-2'>
									<Link
										className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
										to={`/dashboard/clientes/${order.User?.id}`}
									>
										{order.email || printUserName(order.User)}
									</Link>
								</td>
								{user.type === 'admin' && (
									<td className='px-4 py-2'>
										<span className='flex flex-col'>
											{order?.Merchants.map((merchant, index) => (
												<p key={index}>{merchant.commercialName}</p>
											))}
										</span>
									</td>
								)}
								<td className='px-4 py-2'>{formatDate(order.orderDate)}</td>
								<td className='px-4 py-2'>{translasteStatus(order.status)}</td>
								<td className='px-4 py-2'>
									<Currency>{order.totalPrice + order.shippingPrice}</Currency>
								</td>
								<td className='px-4 py-2'></td>
							</tr>
						)
					}
					empty={<p>No hay pedidos</p>}
				/>
			</LoadingView>
		</Card>
	);
};

export default OrderList;
