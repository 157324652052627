import React from 'react';
import Button from 'components/Button';

const Pagination = ({ offset, setOffset, className = 'flex justify-end w-full h-10', count, hasMore, limit = 10 }) => {
	const handleOffset = number => () => {
		if (offset + number > 0) {
			setOffset(prev => prev + number);
		} else {
			setOffset(0);
		}
	};
	return (
		<span className={`${className} ${count > 0 ? 'visible' : 'invisible'}`}>
			<Button className='flex' onClick={offset > 0 ? handleOffset(-limit) : undefined} disabled={offset <= 0}>
				{'<'}
			</Button>
			<span className='self-center text-xl px-2'>
				{Math.ceil((offset + 1) / limit)} / {Math.ceil(count / limit)}
			</span>
			<Button className='flex' onClick={hasMore ? handleOffset(+limit) : undefined} disabled={!hasMore}>
				{'>'}
			</Button>
		</span>
	);
};

export default Pagination;
