import React from 'react';

const Field = ({
	id,
	label,
	disabled = false,
	width = 'w-full',
	align = 'items-center',
	maxW = 'max-w-screen-md',
	labelclass = 'w-1/3',
	mb = 'mb-4',
	containerClassName = '',
	children,
	required,
	disableNonTranslatableFields = false,
	...props
}) => (
	<div
		className={`flex ${align} ${width} ${maxW} ${mb} ${containerClassName} ${
			disableNonTranslatableFields ? 'hidden' : ''
		}`}
	>
		{(label || props.placeholder) && (
			<label
				htmlFor={id}
				className={`${!label && 'sr-only'} font-semibold ${
					disabled ? 'text-gray-500' : 'text-gray-700'
				} text-lg ${labelclass}`}
			>
				{label || props.placeholder}
				{required && <span className='text-coral-500'> *</span>}
				{props.description}
			</label>
		)}
		{children}
	</div>
);

export default Field;
