import Field from 'components/Field';
import { useMultipleFileUpload, useSetImageAttributes, useDeleteFile } from 'hooks/use-media';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';

import Button from './Button';
import InputForm from './InputForm';

export const DropImageArea = ({
	Images = [],
	onChange,
	onDelete,
	multiple = false,
	children,
	disabled = false,
	whileIdleText = 'Pincha o Suelta tus Imágenes aquí',
	className = 'cursor-pointer',
	description = 'Agregar breve descripción para ayudar el SEO',
	descriptionClassName = 'text-sm font-normal',
	setImagesUploading = () => {},
	...props
}) => {
	const { locale } = useAuth();

	const [uploadFiles, { data, loading: isUploading }] = useMultipleFileUpload();

	useEffect(() => {
		// UploadFiles returns an array with images
		if (data && data.UploadFiles.length > 0) {
			onChange(data.UploadFiles);
		}
	}, [data, onChange]);

	const onDrop = useCallback(
		files => {
			setImagesUploading(true);
			uploadFiles({ variables: { files } });
		},
		[uploadFiles, setImagesUploading]
	);

	return (
		<Field disabled={disabled} description={<p className={descriptionClassName}>{description}</p>} {...props}>
			<div className={`w-2/3 ${className || ''}`}>
				<div
					className={`border-4 border-dashed ${
						disabled ? 'border-gray-300' : 'border-coral-300'
					} dashed flex w-full flex-col items-center justify-center rounded-lg p-4 text-sm font-bold text-coral-300`}
				>
					<DropFileArea
						disabled={disabled}
						onDrop={onDrop}
						multiple={multiple}
						isUploading={isUploading}
						noClick={Images?.filter(i => !!i?.id)?.length > 0}
						whileIdleText={whileIdleText}
					>
						{Images?.filter(i => !!i?.id)?.length > 0 && (
							<ImagesGrid images={Images?.filter(i => !!i?.id)} onDelete={onDelete} locale={locale} />
						)}
					</DropFileArea>
				</div>

				{children}
			</div>
		</Field>
	);
};

// limit 500kb file
const maxFileSize = 500_000;
function fileSizeValidator(file) {
	if (file.size > maxFileSize) {
		// Has to return something different than null or the image is uploaded anyway
		return {
			code: 'file-too-large',
			message: `Imagen: ${file.name} supera límite de 500kb`,
		};
	}

	return null;
}
export const DropFileArea = ({ disabled, children, onDrop, multiple, isUploading, noClick = false, whileIdleText }) => {
	const { addErrorMessage } = useToast();
	const { getRootProps, getInputProps, fileRejections, isDragActive, open } = useDropzone({
		onDrop,
		noClick,
		validator: fileSizeValidator,
	});

	// Check for invalid images and display error
	useEffect(() => {
		for (const image of fileRejections) {
			addErrorMessage(`${image.errors[0].message}`);
		}
	}, [fileRejections, addErrorMessage]);

	const handleSelectImageButton = e => {
		e.preventDefault();
		open();
	};

	return (
		<>
			<div
				className=' focus:outline-none w-full text-center text-lg flex flex-col justify-center overflow-auto'
				{...getRootProps()}
			>
				<input disabled={disabled} {...getInputProps({ accept: 'image/*', multiple })} />
				{/* Always display images even when dragging new one */}
				{children}
				{isUploading && <p className='py-16'>Subiendo archivos…</p>}
				{isDragActive && <p className='py-16'>Suelta tus imágenes aquí</p>}
				{!isDragActive && !noClick && !isUploading && <p className='py-16'>{whileIdleText}</p>}
			</div>
			{/* Button for single image*/}
			{/* don't display when there's an image so user has to delete to upload new one*/}
			{!multiple && !noClick && (
				<Button disabled={disabled} onClick={handleSelectImageButton} className='mb-1 mt-4 w-full self-center'>
					Agregar imagen
				</Button>
			)}
			{/* Button for gallery images*/}
			{multiple && (
				<Button disabled={disabled} onClick={handleSelectImageButton} className='mb-1 mt-4 w-full self-center'>
					{noClick ? 'Agregar imágenes' : 'Seleccionar imágenes'}
				</Button>
			)}
		</>
	);
};

export const ImagesGrid = ({ images, onDelete, locale }) => (
	<div className={'flex flex-col gap-5 cursor'}>
		{images.map((image, index) => (
			<ImageElement image={image} key={`ie_${index}`} onDelete={onDelete} locale={locale} />
		))}
	</div>
);

export const ImageElement = ({ image, onDelete, locale }) => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [deleteFile] = useDeleteFile();
	const [setImageAttributes] = useSetImageAttributes();

	const {
		control,
		register,
		getValues,
		setValue,
		formState: { isDirty, isSubmitSuccessful },
		handleSubmit,
		reset,
	} = useForm({
		mode: 'onChange',
		values: { imageForm: { title: '', alt: '' } },
	});

	// Fill imageForm with data
	useEffect(() => {
		if (image) {
			setValue('imageForm', { title: image.title || '', alt: image.alt || '' });
		}
	}, [image, setValue]);

	// useForm's subscription needs to be ready before reset can send a signal to flush form state update.
	useEffect(() => {
		reset({}, { keepDirty: true, keepValues: true });
	}, [isSubmitSuccessful, reset]);

	const onSubmit = ({ imageForm }) => {
		const title = imageForm.title;
		const alt = imageForm.alt;
		if (isDirty) {
			image.title = title;
			image.alt = alt;
			setImageAttributes({ variables: { id: image.id, attributes: { title, alt }, locale } }).then(() => {
				addSuccessMessage('Atributos de imagen', 'guardados correctamente');
			});
		}
	};

	return (
		<>
			<div
				className='border flex flex-1 w-32 h-32 items-center justify-center group relative p-2 rounded-md overflow-hidden'
				key={image.id}
			>
				<button
					className='absolute top-0 right-0 bg-gray-300 text-gray-700 h-6 w-6 text-2xl font-bold rounded-md flex items-center justify-center overflow-hidden focus:outline-none transition-colors duration-200 focus:bg-coral-500 focus:text-white z-30'
					onClick={e => {
						e.preventDefault();
						if (!window.confirm('¿Confirma borrar la imagen? luego no se puede recuperar')) return;
						onDelete &&
							deleteFile({ variables: { id: image.id } }).then(({ data: { DeleteFile } }) => {
								if (DeleteFile.success) {
									addSuccessMessage('Imagen', 'Borrada correctamente');
									onDelete(image.id);
								} else {
									addErrorMessage('Imagen', `Error: ${DeleteFile.message}`);
								}
							});
					}}
				>
					&times;
				</button>
				<img
					src={image.src}
					alt={image.alt}
					title={image.title}
					className='h-auto w-auto max-h-full max-w-full block rounded-md object-contain'
				/>
			</div>
			<div className='text-gray-700 flex flex-col'>
				<InputForm
					name='imageForm.title'
					control={control}
					register={register}
					label='Título'
					id={`${image.id}_title`}
					width='w-full'
					placeholder='Título de la imagen'
					labelclass='flex w-1/3'
					onSubmit={handleSubmit(onSubmit)}
					getValues={getValues}
					debounce
				/>
				<InputForm
					name='imageForm.alt'
					control={control}
					register={register}
					label='Breve descripción'
					id={`${image.id}_alt`}
					width='w-full'
					placeholder='Breve descripción'
					labelw='flex w-1/3'
					required
					onSubmit={handleSubmit(onSubmit)}
					getValues={getValues}
					debounce
				/>
			</div>
		</>
	);
};

export default DropImageArea;
