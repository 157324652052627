import React, { useEffect, useState } from 'react';

import useAuth from 'hooks/use-auth';

import CommercialForm from 'pages/Merchants/CommercialForm';
import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import { useLazyMerchant } from 'hooks/use-merchants';

const MyCommercialForm = () => {
	const { user, locale } = useAuth();
	const [dirty, setDirty] = useState(false);

	const [getLazyMerchant, { data: merchantData }] = useLazyMerchant();

	// Excecute once at page load
	useEffect(() => {
		// query Product fields with specific locale
		getLazyMerchant({ variables: { id: user.Merchant.id, locale } });
	}, [locale, user.MerchantId]);

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				{merchantData ? (
					<CommercialForm
						merchantData={merchantData.Merchant}
						className='shadow-none'
						adminLayout={false}
						setDirty={setDirty}
					/>
				) : null}
			</Card>
		</>
	);
};

export default MyCommercialForm;
