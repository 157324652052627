import React from 'react';
import Loading from './Loading';

const LoadingView = ({ children, loading = true, whileLoading, error = false, haltOnErrors = true, ...rest }) => {
	if (loading) {
		return <Loading text={whileLoading} {...rest} />;
	}

	if (error && haltOnErrors) {
		return <div className='alert alert-danger text-center'>{error.message}</div>;
	}

	return children;
};

export default LoadingView;
