import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Label } from 'recharts';

const OrderAmountChart = ({ graphData }) => (
	<LineChart width={600} height={300} data={graphData} margin={{ top: 15, right: 30, left: 20, bottom: 20 }}>
		<CartesianGrid stroke='#ff515b' strokeDasharray='3' />
		<XAxis dataKey='month' stroke='#ff515b'>
			<Label value='Mes' offset={0} position='bottom' />
		</XAxis>
		<Tooltip />
		<YAxis stroke='#ff515b' label={{ value: 'Total Ventas', angle: -90, position: 'insideLeft' }} />
		<Line type='monotone' dataKey='total' stroke='#ff515b' strokeWidth={3} />
	</LineChart>
);

export default OrderAmountChart;
