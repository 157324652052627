import React from 'react';
import Input from 'components/Input';
import LayoutTitle from 'components/LayoutTitle';
import TextArea from 'components/Textarea';

const CustomerAddress = ({ address, title }) => {
	return address ? (
		<div className='mt-16'>
			<LayoutTitle>{title}</LayoutTitle>
			<Input value={address.name} label='Nombre' readOnly />
			<Input value={address.vat} label='DNI/NIF' readOnly />
			<Input value={address.phone} label='Teléfono' readOnly />
			<Input value={address.address1 + '' + address.address2} label='Dirección' readOnly />
			<Input value={address.zip} label='Código Postal' readOnly />
			<Input value={address.city} label='Localidad' readOnly />
			<Input value={address.province} label='Provincia' readOnly />
			<Input value={address.country} label='País' readOnly />
			<TextArea value={address.notes} label='Más información' readOnly />
		</div>
	) : null;
};

export default CustomerAddress;
