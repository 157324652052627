import React from 'react';
import MerchantsList from 'components/MerchantsList';

const Merchants = () => {
	const statusList = [
		{ label: 'Todos', scope: 'all' },
		{ label: 'Activo', scope: 'active' },
		{ label: 'Inactivo', scope: 'inactive' },
		{ label: 'Pendiente', scope: 'pending' },
		{ label: 'Revisión', scope: 'revision' },
	];

	return <MerchantsList statusList={statusList} />;
};

export default Merchants;
