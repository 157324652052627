import OrderList from 'components/OrderList';
import React from 'react';

const Orders = () => {
	const statusList = [
		{ label: 'Todas', scope: 'all' },
		{ label: 'Creado', scope: 'new' },
		{ label: 'Procesando', scope: 'processing' },
		{ label: 'Preparada', scope: 'prepared' },
		{ label: 'Enviada', scope: 'shipped' },
		{ label: 'Incidencia', scope: 'incident' },
		{ label: 'Anulada', scope: 'canceled' },
		{ label: 'Devuelto', scope: 'returned' },
		{ label: 'Completada', scope: 'completed' },
	];

	return <OrderList pageScope='all' title='Pedidos' statusList={statusList} />;
};

export default Orders;
