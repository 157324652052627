import React from 'react';
import Card from 'components/Card';
import Currency from 'components/Currency';

const SalesStatisticsSummary = ({ statistics }) => (
	<div className='w-full lg:w-1/3'>
		<Card className='mb-4'>
			<p className='text-center text-coral-400 text-2xl font-semibold'>Ventas</p>
			<p className='text-center text-sm'>Base</p>
			<p className='text-center text-teal-400 text-4xl'>
				<Currency>{statistics.totalSales}</Currency>
			</p>
			<div className='grid grid-cols-2 gap-2 mt-4'>
				<div>
					<p className='text-gray-600'>IVA</p>
					<p className='text-gray-800'>
						<Currency>{statistics.totalTax}</Currency>
					</p>
				</div>
				<div>
					<p className='text-gray-600'>Total Descuntos</p>
					<p className='text-gray-800'>
						<Currency>{statistics.totalDiscounts}</Currency>
					</p>
				</div>
			</div>
			<div className='grid grid-cols-2 gap-2 mt-4'>
				<div>
					<p className='text-gray-600'>Nº Pedidos</p>
					<p className='text-gray-800'>{statistics.numberOfOrders}</p>
				</div>
			</div>
		</Card>
	</div>
);

export default SalesStatisticsSummary;
