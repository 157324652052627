import Button from 'components/Button';
import Input from 'components/Input';
import TextArea from 'components/Textarea';
import React, { useEffect, useState } from 'react';

const FaqForm = ({ faq, onSubmit }) => {
	const [disabled, setDisabled] = useState(false);

	const [input, setInput] = useState({
		question: '',
		answer: '',
	});

	useEffect(() => {
		if (faq) {
			setInput({
				question: faq.question,
				answer: faq.answer,
			});
		}
	}, [faq]);

	return (
		<form
			onSubmit={e => {
				e.preventDefault();
				if (!disabled) {
					setDisabled(true);
					onSubmit(input);
					setDisabled(false);
				}
			}}
		>
			<Input
				label='Pregunta'
				value={input.question}
				onChange={e => setInput({ ...input, question: e.target.value })}
			/>
			<TextArea
				label='Respuesta'
				value={input.answer}
				onChange={e => setInput({ ...input, answer: e.target.value })}
			/>
			<Button className='mt-4' disabled={disabled} type='submit'>
				{disabled ? 'Actualizando Datos' : 'Actualizar Datos'}
			</Button>
		</form>
	);
};

export default FaqForm;
