import Field from 'components/Field';
import React, { useState } from 'react';

const Input = ({
	id,
	suffix,
	width = 'w-1/3',
	type = 'text',
	fieldWidth = 'w-full',
	fieldFloat = '',
	value,
	children,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	if (type === 'password') {
		return (
			<Field width={fieldWidth} float={fieldFloat} {...props}>
				<div
					className={`flex items-center border border-coral-300  ${
						props?.disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
					} rounded-md py-2 px-4 ${width} ${props.className || ''}`}
				>
					<input
						value={value || ''}
						type={`${showPassword ? 'text' : 'password'}`}
						id={id}
						{...props}
						className={'flex-1 outline-none focus:outline-none'}
						autoComplete='false'
					/>
					<span onClick={handleShowPassword}>
						<img
							className='w-5'
							alt='toggle password visibility'
							src={`${
								showPassword
									? '/images/form-icons/eye-slash-solid.svg'
									: '/images/form-icons/eye-solid.svg'
							}`}
						/>
					</span>
				</div>
				{children}
			</Field>
		);
	}

	return (
		<Field width={fieldWidth} float={fieldFloat} {...props}>
			<div
				className={`flex items-center border border-coral-300  ${
					props?.disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
				} rounded-md py-2 px-4 ${width} ${props.className || ''}`}
			>
				<input
					value={value ?? ''}
					type={type}
					id={id}
					{...props}
					className={'flex-1 outline-none focus:outline-none'}
				/>
				{suffix}
			</div>
			{children}
		</Field>
	);
};

export default Input;
