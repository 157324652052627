import React, { useEffect, useState } from 'react';
import { SectionHeader } from 'components/LayoutHeader';
import ContentTable from './ContentTable';
import formatDate from 'lib/formatDate';
import formatTime from 'lib/formatTime';
import SearchBox from './SearchBox';
import Pagination from './Pagination';
import { useLazyEvents } from 'hooks/use-events';
import AuthorizationBoundary from './AuthorizationBoundary';
import SelectMerchantInput from './SelectMerchantInput';
import { useToast } from 'components/Toast';
import { Link } from 'react-router-dom';
import useAuth from 'hooks/use-auth';

const EventList = ({ events, paginationLimit = 50 }) => {
	const { user } = useAuth();
	const { addErrorMessage } = useToast();
	const [offset, setOffset] = useState(0);
	const [eventSearch, setEventSearch] = useState('');
	const [merchantId, setMerchantId] = useState('');
	const [eventData, setEventData] = useState(events || []);

	const [getLazyEvents, { loading }] = useLazyEvents({
		variables: {
			search: eventSearch,
			MerchantId: merchantId,
			limit: paginationLimit,
			offset,
		},
		onCompleted: ({ Events }) => {
			if (Events.__typename.endsWith('Fault')) {
				addErrorMessage('Actualizar categoría', Events.message);
			} else if (Events.__typename === 'EventList') {
				setEventData(Events);
			}
		},
	});

	useEffect(() => {
		if (!events) {
			getLazyEvents();
		}
	}, [events]);

	const handleSearch = input => {
		setEventSearch(input);
		setOffset(0);
		getLazyEvents();
	};

	const handleClearSearch = () => {
		setEventSearch('');
		setOffset(0);
		getLazyEvents();
	};
	const handleSelectMerchant = e => setMerchantId(e.target.value);

	const displayDetails = event => {
		if (user.type === 'admin' && event) {
			if (event.User?.Merchant) {
				return (
					<td className='px-4 text-left cursor-pointer'>
						<Link to={`/dashboard/asociados/edit/${event.User?.Merchant.id}`}>{event.details}</Link>
					</td>
				);
			}
			if (event.Order && event.Order.status !== 'draft') {
				return (
					<td className='px-4 text-left cursor-pointer'>
						<Link to={`/dashboard/pedido/${event?.Order?.id}`}>{event.details}</Link>
					</td>
				);
			}
			return <td className='px-4 py-2 w-1/2'>{event.details}</td>;
		}
	};

	return (
		<>
			<SectionHeader>Eventos</SectionHeader>
			<span className='flex w-full justify-between'>
				<SearchBox
					placeholder='Busca un Asociado'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				<Pagination
					offset={offset}
					setOffset={setOffset}
					count={eventData?.count}
					hasMore={eventData?.hasMore}
					limit={paginationLimit}
				/>
			</span>
			<AuthorizationBoundary for={['admin']}>
				{/* En SelectMerchantInput se hace un use-merchant que muestra los merchants y permite cambiar el MerchantId */}
				<SelectMerchantInput
					emptyValue={{ id: '', commercialName: 'Todos los asociados' }}
					onChange={handleSelectMerchant}
					width='w-1/3'
					label='Selecciona un asociado'
				/>
			</AuthorizationBoundary>
			<ContentTable
				columns={user.type === 'admin' ? ['Fecha', 'Detalle', 'Mensaje'] : ['Fecha', 'Mensaje']}
				content={eventData.List || events}
				render={(event, index) =>
					event && (
						<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300'>
							<td className='px-4 py-2 w-32'>{`${formatDate(event.createdAt)}, ${formatTime(
								event.createdAt
							)}`}</td>
							{displayDetails(event)}
							<td className='px-4 py-2 flex-1'>{event.message}</td>
						</tr>
					)
				}
				empty={<p>No hay eventos</p>}
			/>
		</>
	);
};

export default EventList;
