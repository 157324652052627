import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import DropImageArea from 'components/DropfileArea';
import InputForm from 'components/InputForm';
import LayoutHeader from 'components/LayoutHeader';
import PasswordManagement from 'components/PasswordManagement';
import Score from 'components/Score';
import SelectForm from 'components/SelectForm';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const AccountForm = ({ adminLayout, className, userData, ownUser, setDirty = () => {} }) => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { setUserData, useUpdateUser, getLazyNotificationCount, locale } = useAuth();
	const disableNonTranslatableFields = locale !== process.env.REACT_APP_DEFAULT_LOCALE;
	const [updateUser, { loading }] = useUpdateUser();
	const [updateUserResponse, setUpdateUserResponse] = useState(null);
	const [userImage, setUserImage] = useState([]);
	const [score, setScore] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);
	const [imagesUploading, setImagesUploading] = useState(false);

	const {
		handleSubmit,
		control,
		register,
		setValue,
		watch,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			accountForm: {
				firstName: '',
				lastName: '',
				userName: '',
				email: '',
				phone: '',
				acceptsMarketing: false,
				UserImageId: null,
				status: 'inactive',
			},
		},
	});

	useEffect(() => {
		if (userData) {
			setValue('accountForm', {
				firstName: userData.firstName || '',
				lastName: userData.lastName || '',
				userName: userData.userName || '',
				email: userData.email || '',
				phone: userData.phone || '',
				acceptsMarketing: userData.acceptsMarketing || false,
				UserImageId: userData.UserImageId || null,
				status: userData.status || 'inactive',
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setUserImage({ ...userData?.Image });
			setDirty(false);
			setIsFormFilled(true);
		}
	}, [userData, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	useEffect(() => {
		if (updateUserResponse && updateUserResponse.__typename === 'AuthorizationFault') {
			addErrorMessage('Asociado', updateUserResponse.message);
		}
		// All Errors implement Fault
		else if (updateUserResponse && updateUserResponse.__typename.endsWith('Fault')) {
			addErrorMessage('Asociado', updateUserResponse.message);
		}
	}, [updateUserResponse, addErrorMessage]);

	const conditionsLink = (
		<p>
			{`Acepto recibir información comercial de ${process.env.REACT_APP_MARKETPLACE_NAME}. Consulta la `}
			<a className='text-red-500' href='/'>
				Política de Privacidad.
			</a>
		</p>
	);

	// Using calculateFormScore in combination with watch "score" state
	function calculateFormScore(userFormData) {
		if (userFormData) {
			let total = Object.entries(userFormData).length;
			let count = 0;

			for (var [, value] of Object.entries(userFormData)) {
				if (value) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	}

	watch(({ accountForm }) => {
		calculateFormScore(accountForm);
	});

	const handleOnChangeUserImage = useCallback(
		([media]) => {
			setValue('accountForm.UserImageId', media.id);
			setUserImage({ ...media });
			setImagesUploading(false);
			handleSubmit(onSubmit)();
		},
		[setUserImage, setValue]
	);

	const onSubmit = ({ accountForm }) => {
		updateUser({
			variables: {
				id: userData?.id,
				input: accountForm,
			},
		}).then(response => {
			setUpdateUserResponse(response.data?.ProfileUpdate);
			addSuccessMessage('Asociado', 'actualizado correctamente');
			// when admin change merchant status from 'revision'
			if (
				!ownUser &&
				((userData.status === 'revision' && accountForm.status !== 'revision') ||
					(userData.status !== 'revision' && accountForm.status === 'revision'))
			) {
				getLazyNotificationCount();
			}
			// when user is modifying own User data, store user result in useAuth to be used in the app
			if (ownUser) {
				setUserData(response.data?.ProfileUpdate);
			}
		});
	};

	return (
		isFormFilled && (
			<div className={className}>
				<Score data={score} name={ownUser ? 'mis datos' : 'de datos'} content='' />
				<form onSubmit={handleSubmit(onSubmit)}>
					<LayoutHeader>{adminLayout ? 'Datos' : 'Mis datos'}</LayoutHeader>
					<br />
					{adminLayout && (
						<SelectForm
							name='accountForm.status'
							control={control}
							register={register}
							options={[
								{ value: 'active', name: 'Activo' },
								{ value: 'inactive', name: 'Inactivo' },
								{ value: 'pending', name: 'Pendiente' },
								{ value: 'revision', name: 'Revisión' },
							]}
							label='Estado'
							disabled={disableNonTranslatableFields || loading}
						/>
					)}
					<InputForm
						name='accountForm.firstName'
						control={control}
						register={register}
						disabled={disableNonTranslatableFields}
						label='Nombre'
						placeholder='Nombre'
					/>
					<InputForm
						name='accountForm.lastName'
						control={control}
						register={register}
						disabled={disableNonTranslatableFields}
						label='Apellidos'
						placeholder='Appelidos'
					/>
					<InputForm
						name='accountForm.userName'
						control={control}
						register={register}
						disabled={disableNonTranslatableFields}
						label='Nombre de usuario'
						placeholder='Nombre de usuario'
					/>
					<InputForm
						name='accountForm.email'
						control={control}
						register={register}
						disabled={disableNonTranslatableFields}
						label='Email'
						placeholder='Email'
					/>
					<InputForm
						name='accountForm.phone'
						control={control}
						register={register}
						disabled={disableNonTranslatableFields}
						label='Teléfono'
						placeholder='+34555555555'
						type='tel'
						pattern='^\+?[0-9]*'
						maxLength='13'
						minLength='9'
					/>
					<DropImageArea
						label='Imagen de Perfil'
						whileIdleText='Selecciona imagen de perfil del Panel de Administración'
						Images={userImage ? [userImage] : []}
						onChange={handleOnChangeUserImage}
						onDelete={() => {
							setValue('accountForm.UserImageId', null);
							setUserImage(null);
						}}
						setImagesUploading={setImagesUploading}
					/>
					<CheckForm
						name='accountForm.acceptsMarketing'
						control={control}
						label={conditionsLink}
						disabled={disableNonTranslatableFields}
					/>
					<Button className='mt-5' type='submit' disabled={loading || imagesUploading}>
						{`Actualizar ${adminLayout ? 'datos' : 'mis datos'}`}
					</Button>
				</form>
				<PasswordManagement
					userData={userData}
					className='border-t border-dashed border-coral-300 mt-10 pt-10'
				/>
			</div>
		)
	);
};

export default AccountForm;
