import DayInput from 'components/DayInput';
import React from 'react';
import { useController } from 'react-hook-form';

const Schedule = ({ disabled, name, control, ...props }) => {
	const {
		field: { value, onChange },
	} = useController({ name, control });

	let weekSchedule = Array.isArray(value) ? value : value && JSON.parse(value);
	if (!weekSchedule) {
		// When no schedule use a default one
		const length = 7;
		weekSchedule = Array.from({ length }).fill({
			morningStart: '00:00',
			morningEnd: '00:00',
			afternoonStart: '00:00',
			afternoonEnd: '00:00',
			closed: false,
		});
	}

	const label = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];
	const elements = weekSchedule.map((daySchedule, dayScheduleIdx) => (
		<DayInput
			onChange={e => {
				// Modify the day data in the JSON Array (we know the day using dayScheduleIdx)
				weekSchedule[dayScheduleIdx] = e;
				// Convert the whole weekSchedule back to a JSON string and send it back to the parent
				onChange(JSON.stringify(weekSchedule));
			}}
			key={dayScheduleIdx}
			label={label[dayScheduleIdx]}
			dayData={daySchedule}
			disabled={disabled}
			props={props}
		/>
	));

	return <>{elements}</>;
};

export default Schedule;
