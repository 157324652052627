import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import { useToast } from 'components/Toast';
import { useProduct, useUpdateProduct } from 'hooks/use-products';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductForm from './ProductForm';
import useAuth from 'hooks/use-auth';

const ProductEdit = () => {
	const { user, locale, getLazyNotificationCount } = useAuth();
	const { productId } = useParams();
	const [dirty, setDirty] = useState(false);
	// Toast alert
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { data, ...rest } = useProduct(productId, locale);
	const [productEditResponse, setProductEditResponse] = useState(null);

	// Status list
	const statusList = [
		{ value: 'active', name: 'Activo' },
		{ value: 'inactive', name: 'Inactivo' },
		{ value: 'revision', name: 'Revisión' },
		{ value: 'modified', name: 'Modificado' },
		{ value: 'deleted', name: 'Descatalogado' },
	];

	// productEditResponse update States
	useEffect(() => {
		if (productEditResponse && productEditResponse.__typename === 'DatabaseFault') {
			addErrorMessage('Editar producto', productEditResponse.message);
		} else if (productEditResponse && productEditResponse.__typename === 'AuthorizationFault') {
			addErrorMessage('Editar producto', productEditResponse.message);
		} // All Errors implement Fault
		else if (productEditResponse && productEditResponse.__typename.endsWith('Fault')) {
			addErrorMessage('Editar producto', productEditResponse.message);
		} else if (productEditResponse && productEditResponse.__typename === 'Product') {
			if (user?.type === 'admin') {
				getLazyNotificationCount();
			}
			addSuccessMessage('Producto', `${productEditResponse.title} actualizado correctamente`);
		}
	}, [productEditResponse, addSuccessMessage, addErrorMessage, getLazyNotificationCount, user.type]);

	const [updateProduct] = useUpdateProduct({
		onCompleted: ({ ProductUpdate }) => {
			setProductEditResponse(ProductUpdate);
		},
	});

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				<LayoutHeader>Editar Producto</LayoutHeader>
				<LoadingView whileLoading='Cargando el producto' {...rest}>
					<ProductForm
						product={data?.Product}
						onSubmit={input => updateProduct({ variables: { id: productId, input, locale } })}
						setDirty={setDirty}
						statusList={statusList}
					/>
				</LoadingView>
			</Card>
		</>
	);
};

export default ProductEdit;
