import React from 'react';

import { useNavigate } from 'react-router-dom';

import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import FaqsForm from './FaqsForm';

import { useCreateFaq } from 'hooks/use-faqs';

const FAQEdit = () => {
	const navigate = useNavigate();

	const [createFaq, { loading }] = useCreateFaq({
		onCompleted: () => {
			navigate('/dashboard/faqs');
		},
	});

	return (
		<LoadingView loading={loading}>
			<LayoutHeader>Crear FAQ</LayoutHeader>
			<FaqsForm onSubmit={input => createFaq({ variables: { input } })} />
		</LoadingView>
	);
};

export default FAQEdit;
