import { useState, useEffect } from 'react';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

import Currency from './Currency';
import LayoutHeader from './LayoutHeader';
import Input from './Input';
import Message from './Message';
import Button from './Button';

import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import { usePaymentFeeCreate, usePaymentFeeDone } from 'hooks/use-checkout';

const CARD_OPTIONS = {
	iconStyle: 'solid',
	style: {
		base: {
			iconColor: '#646464',
			color: '#646464',
			fontWeight: '500',
			fontFamily: 'sans-serif',
			fontSize: '16px',
			fontSmoothing: 'antialiased',
			':-webkit-autofill': {
				color: '#fce883',
			},
			'::placeholder': {
				color: '#646464',
			},
		},
		invalid: {
			iconColor: '#ef2961',
			color: '#ef2961',
		},
	},
};

const StripeCheckout = ({ onSuccess = () => {} }) => {
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { isLoggedIn, loggedInStatus, user } = useAuth();
	const navigate = useNavigate();

	// Deny access to checkout if not merchant and not loged in
	useEffect(() => {
		if (user?.type !== 'merchant') {
			navigate('/dashboard');
			addErrorMessage('Autenticación', 'Opción de pago solo para Asociados');
		}
		if (isLoggedIn === loggedInStatus.no) {
			navigate('/login');
			addErrorMessage('Autenticación', 'Debe iniciar sesión');
		}
	}, [navigate, user]);

	return (
		<Elements options={{ locale: 'es' }} stripe={stripePromise}>
			<Checkout onSuccess={onSuccess} addErrorMessage={addErrorMessage} addSuccessMessage={addSuccessMessage} />
		</Elements>
	);
};

const Checkout = ({ onSuccess, addErrorMessage, addSuccessMessage }) => {
	const stripe = useStripe();
	const elements = useElements();

	const [cardholderName, setCardholderName] = useState('');
	const [paymentStatus, setPaymentStatus] = useState(null);
	const [errorMessage, setErrorMessage] = useState('');
	const [paymentCreateResponse, setPaymentCreateResponse] = useState('');
	const [paymentButtonLabel, setPaymentButtonLabel] = useState('Realizar pago');
	const [paymentFeeCreate] = usePaymentFeeCreate();
	const [paymentFeeDone] = usePaymentFeeDone();

	useEffect(() => {
		paymentFeeCreate().then(({ data }) => {
			// All Errors implement Fault
			if (data && data.PaymentFeeCreate?.__typename.endsWith('Fault')) {
				addErrorMessage('Pago', data.PaymentFeeCreate.message);
			} else if (data && data.PaymentFeeCreate?.__typename === 'PaymentIntent') {
				setPaymentCreateResponse(data.PaymentFeeCreate);
			}
		});
	}, []);

	const handleSubmit = async event => {
		// Block native form submission.
		event.preventDefault();
		setPaymentStatus(null);
		setErrorMessage(null);
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}

		// Get a reference to a mounted CardElement. Elements knows how
		// to find your CardElement because there can only ever be one of
		// each type of element.
		const cardElement = elements.getElement(CardElement);
		setPaymentButtonLabel('Procesando pago');
		// Use your card Element with other Stripe.js APIs
		const { error, paymentIntent } = await stripe.confirmCardPayment(paymentCreateResponse.client_secret, {
			payment_method: {
				card: cardElement,
				billing_details: { name: cardholderName },
			},
		});

		if (error) {
			setPaymentButtonLabel('Reintentar pago');
			setPaymentStatus(error);
			return;
		}
		setPaymentStatus(paymentIntent);

		paymentFeeDone().then(({ data }) => {
			// All Errors implement Fault
			if (data && data.PaymentFeeDone?.__typename.endsWith('Fault')) {
				addErrorMessage('Pago', data.PaymentFeeDone.message);
			} else if (data && data.PaymentFeeDone?.__typename === 'Success') {
				addSuccessMessage('Pago', 'Procesado correctamente');
				onSuccess();
			}
		});
	};

	const handleCardholderName = e => setCardholderName(e.target.value);

	return (
		<form onSubmit={handleSubmit}>
			{process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY.includes('pk_test') && (
				<p className='my-8 bg shadow-md p-4 text-white text-center font-bold'>
					Esto es una web de demostración. No se cargará nada en tu cuenta
				</p>
			)}
			<div className='mt-4 mb-4 shadow-md overflow-hidden p-8 border border-black rounded-tl-xl rounded-br-xl '>
				<LayoutHeader className='uppercase'>Datos de la tarjeta</LayoutHeader>
				<div className='w-full mt-4'>
					<Input
						required
						width='w-full'
						placeholder='Titular de la tarjeta'
						className='w-full bg-white p-2'
						placeholderstyle='placeholder-gray-700'
						onChange={handleCardholderName}
						value={cardholderName}
					/>
					<div className='w-full border rounded-md border-coral-300 h-10 bg-white p-2'>
						<CardElement options={CARD_OPTIONS} />
					</div>

					{paymentCreateResponse?.amount && (
						<div className='my-8 border-b border-t border-dashed border-brightRed px-4 flex justify-between py-2 items-center'>
							<p className='text-xl'>Total a pagar</p>
							<Currency className='text-xl font-bold'>{paymentCreateResponse?.amount / 100}</Currency>
						</div>
					)}

					<Button disabled={!stripe || !elements} type='submit' className='mt-4 w-full' id='pay'>
						{paymentButtonLabel}
					</Button>
					<Message error>{showPaymentStatus(paymentStatus, errorMessage)}</Message>
				</div>
			</div>
		</form>
	);
};

const showPaymentStatus = (paymentStatus, otherError) => {
	if (otherError) {
		return otherError;
	}

	if (paymentStatus?.type === 'validation_error') {
		switch (paymentStatus?.code) {
			case 'incomplete_number':
				return 'Número de tarjeta está incompleto';

			case 'invalid_expiry_year_past':
				return 'El año de vencimiento de su tarjeta está en el pasado';

			case 'incomplete_expiry':
				return 'La fecha de vencimiento de su tarjeta está incompleta';

			case 'invalid_number':
				return 'Número de tarjeta no es válido';

			case 'incomplete_cvc':
				return 'El código de seguridad de su tarjeta está incompleto';

			case 'incomplete_zip':
				return 'Código postal está incompleto';
		}
	}

	if (
		paymentStatus?.type === 'invalid_request_error' &&
		paymentStatus?.code === 'payment_intent_authentication_failure'
	) {
		return 'No podemos autenticar su método de pago. Elija un método de pago diferente y vuelva a intentarlo';
	}

	return null;
};

export default StripeCheckout;
