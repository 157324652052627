import React from 'react';

import Check from './Check';
import Field from './Field';

const DayInput = ({
	id,
	suffix,
	width = 'w-20',
	label,
	maxW = 'max-w-screen-md',
	mb = 'mb-4',
	dayData = {},
	containerclassname = '',
	onChange,
	disabled,
	props,
}) => {
	return (
		<>
			<div className={`flex justify-end float-right w-full items-center ${maxW} ${containerclassname}`}>
				<Field
					id={id}
					className={`${!label && 'sr-only'} font-semibold text-gray-700 text-lg text-left`}
					labelclass='w-20'
					containerclassname={'w-full justify-end mr-32 flex'}
					label={label || props.placeholder}
				>
					<div
						className={`items-right align-middle border border-coral-400 bg-white mx-4 rounded-md py-2 px-4 ${width} ${
							disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
						} ${props.className || ''}`}
					>
						<input
							type='time'
							id={id}
							{...props}
							value={dayData.morningStart}
							className={`${disabled && 'bg-gray-300'} flex-1 outline-none focus:outline-none`}
							disabled={disabled}
							onChange={e => onChange({ ...dayData, morningStart: e.target.value })}
						/>
						{suffix}
					</div>
					<div className='mt-2'>-</div>
					<div
						className={`items-center border border-coral-400 bg-white rounded-md py-2 mx-4 px-4 ${width} ${
							disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
						} ${props.className || ''}`}
					>
						<input
							type='time'
							id={id}
							{...props}
							value={dayData.morningEnd}
							className={`${disabled && 'bg-gray-300'} flex-1 outline-none focus:outline-none`}
							disabled={disabled}
							onChange={e => onChange({ ...dayData, morningEnd: e.target.value })}
						/>
						{suffix}
					</div>
					<div className='mt-2'>y de</div>
					<div
						className={`flex items-center border border-coral-400 bg-white rounded-md py-2 mx-4 px-4 ${width} ${
							disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
						} ${props.className || ''}`}
					>
						<input
							type='time'
							id={id}
							{...props}
							value={dayData.afternoonStart}
							className={`${disabled && 'bg-gray-300'} flex-1 outline-none focus:outline-none`}
							disabled={disabled}
							onChange={e => onChange({ ...dayData, afternoonStart: e.target.value })}
						/>
						{suffix}
					</div>
					<div className='mt-2'>-</div>
					<div
						className={`flex items-center border border-coral-400 bg-white rounded-md py-2 mx-4 px-4 ${width} ${
							disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
						} ${props.className || ''}`}
					>
						<input
							type='time'
							id={id}
							{...props}
							value={dayData.afternoonEnd}
							className={`${disabled && 'bg-gray-300'} flex-1 outline-none focus:outline-none`}
							disabled={disabled}
							onChange={e => onChange({ ...dayData, afternoonEnd: e.target.value })}
						/>
						{suffix}
					</div>
					<Check
						checked={dayData.closed}
						label='Cerrado'
						onChange={() => onChange({ ...dayData, closed: !dayData.closed })}
						disabled={disabled}
					/>
				</Field>
			</div>
			<div className={`flex float-right w-full justify-end ${mb}`}>
				<p className='text-gray-400 italic mr-40'>*Rellenar solo en caso de jornada partida.</p>
			</div>
		</>
	);
};

export default DayInput;
