import React from 'react';

import Button from 'components/Button';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

const changelogSection = (projectName, releaseInfo) => (
	<>
		<p className='font-medium text-xl'>{projectName}</p>
		<div className='border'></div>
		{releaseInfo.description && (
			<>
				<ReactMarkdown>{releaseInfo.description}</ReactMarkdown>
				<br />
			</>
		)}
		{releaseInfo.new && (
			<>
				<span className='font-medium'>Actualizado</span>
				<ul className='list-inside list-disc'>
					{releaseInfo.new.map((item, index) => (
						<li key={`N1-${index}`}>
							<ReactMarkdown className='inline-flex'>{item}</ReactMarkdown>
						</li>
					))}
				</ul>
				<br />
			</>
		)}
		{releaseInfo.improve && (
			<>
				<span className='font-medium'>Mejorado</span>
				<ul className='list-inside list-disc'>
					{releaseInfo.improve.map((item, index) => (
						<li key={`I1-${index}`}>
							<ReactMarkdown className='inline-flex'>{item}</ReactMarkdown>
						</li>
					))}
				</ul>
				<br />
			</>
		)}
		{releaseInfo.fix && (
			<>
				<span className='font-medium'>Corregido</span>
				<ul className='list-inside list-disc'>
					{releaseInfo.fix.map((item, index) => (
						<li key={`F1-${index}`}>
							<ReactMarkdown className='inline-flex'>{item}</ReactMarkdown>
						</li>
					))}
				</ul>
				<br />
			</>
		)}
		{releaseInfo.remove && (
			<>
				<span className='font-medium'>Eliminado</span>
				<ul className='list-inside list-disc'>
					{releaseInfo.remove.map((item, index) => (
						<li key={`R1-${index}`}>
							<ReactMarkdown className='inline-flex'>{item}</ReactMarkdown>
						</li>
					))}
				</ul>
				<br />
			</>
		)}
		{releaseInfo.add && (
			<>
				<span className='font-medium'>Añadido</span>
				<ul className='list-inside list-disc'>
					{releaseInfo.add.map((item, index) => (
						<li key={`A1-${index}`}>
							<ReactMarkdown className='inline-flex'>{item}</ReactMarkdown>
						</li>
					))}
				</ul>
				<br />
			</>
		)}
	</>
);

const ChangelogElement = ({ releaseStr, release = {}, button = false, more = false, link, bottom = '', ...props }) => (
	<div
		{...props}
		className={`pb-4 pt-2 px-2 border-t-2 ${bottom} border-dotted border-red-400 grid grid-flow-col gap-4`}
	>
		<div>
			<p className='mb-2 font-medium'>{releaseStr}</p>
			{Object.entries(release)
				.sort()
				.map(([projectName, releaseInfo]) => changelogSection(projectName, releaseInfo))}
		</div>
		<div className='flex h-full'>
			<p className='w-full text-right'>{Object.entries(release)[0][1]?.date}</p>
			{button && (
				<Button to={link} className='m-auto'>
					Ver más
				</Button>
			)}
			{more && (
				<Link className='mb-0 align-bottom mt-auto'>
					<span className='text-red-400 align-bottom leading-6 font-medium text-3xl mr-1'>+</span>
					más
				</Link>
			)}
		</div>
	</div>
);

export default ChangelogElement;
