import React from 'react';

const Score = ({ data, name, content }) => {
	var percent = 0;

	if (data) {
		percent = data.toFixed(0);
	}

	return (
		<div className='my-3 xl:sticky xl:float-right xl:top-0 xl:right-0 bg-gray-200 w-full xl:w-1/6 text-center border border-gray-300 p-3'>
			<p className='text-xl leading-5'>Esta ficha {name} está al</p>
			<p className='text-3xl text-red-500'>{percent}%</p>
			<div className='flex pb-3 justify-center'>
				<img
					className='w-8'
					alt='25 por ciento'
					src={`/images/progress-bar/progress-25${percent < 25 ? '-white' : ''}.svg`}
				/>
				<img
					className='w-8'
					alt='50 por ciento'
					src={`/images/progress-bar/progress-50-75${percent < 50 ? '-white' : ''}.svg`}
				/>
				<img
					className='w-8'
					alt='75 por ciento'
					src={`/images/progress-bar/progress-50-75${percent < 75 ? '-white' : ''}.svg`}
				/>
				<img
					className='w-8'
					alt='100 por cien'
					src={`/images/progress-bar/progress-100${percent < 100 ? '-white' : ''}.svg`}
				/>
			</div>
			<p className='text-base leading-4 text-gray-400'>{content}</p>
		</div>
	);
};

export default Score;
