import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import { useToast } from 'components/Toast';
import { useSetImageAttributes } from 'hooks/use-media';
import { useProductCategory, useProductCategoryUpdateMutation } from 'hooks/use-product-categories';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import CategoryForm from './CategoryForm';
import useAuth from 'hooks/use-auth';

const CategoryEdit = () => {
	const { locale } = useAuth();
	const { categoryId } = useParams();
	const [dirty, setDirty] = useState(false);

	const { data, ...rest } = useProductCategory(categoryId, locale);

	const { addSuccessMessage, addErrorMessage } = useToast();

	const [editCategory, { loading: isSubmitting }] = useProductCategoryUpdateMutation({
		onCompleted: ({ ProductCategoryUpdate }) => {
			if (ProductCategoryUpdate.__typename.endsWith('Fault')) {
				addErrorMessage('Actualizar categoría', ProductCategoryUpdate.message);
			} else if (ProductCategoryUpdate.__typename === 'ProductCategory') {
				addSuccessMessage('Categoría', 'actualizada correctamente');
			}
		},
	});

	const [setImageAttributes] = useSetImageAttributes();

	const handleSubmit = values => {
		let { Image, ...params } = values;
		editCategory({ variables: { id: categoryId, locale, input: { ...params } } });

		if (Image) {
			setImageAttributes({ variables: { id: Image.id, attributes: { title: Image.title, alt: Image.alt } } });
		}
	};

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				<LayoutHeader>Editar Categoría - {data?.ProductCategory?.name}</LayoutHeader>
				<LoadingView whileLoading='Buscando categoría' {...rest}>
					<CategoryForm
						productCategory={data?.ProductCategory}
						isSubmitting={isSubmitting}
						onSubmit={handleSubmit}
						setDirty={setDirty}
					/>
				</LoadingView>
			</Card>
		</>
	);
};

export default CategoryEdit;
