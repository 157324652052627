import useToggle from 'hooks/use-toggle';
import React from 'react';
import { Link } from 'react-router-dom';

export const TooltipMenu = ({
	menuList = [],
	chevron = false,
	mt = 'mt-5',
	ml = 'ml-0',
	width = 'w-40',
	left = '-left-40',
	chevronBlack = null,
	className,
}) => {
	const [isMenuOpen, setIsMenuOpen] = useToggle();

	let content = '';

	// closeOnly when mouse is out of the TooltipMenu area
	const handleOpenAndCloseMenu = (closeOnly = false) => {
		setIsMenuOpen(prev => (closeOnly ? false : !prev));
	};

	if (menuList && menuList.length > 0) {
		content = (
			<>
				{menuList.map((link, i, arr) => {
					let element = '';

					if (Object.prototype.hasOwnProperty.call(link, 'onClick')) {
						element = (
							<div
								className='block w-full border-l-4 pl-2 hover:border-red-500 border-gray-200 transition duration-300 ease-in-out'
								onClick={() => {
									link.onClick();
									setIsMenuOpen(false);
								}}
							>
								{link.title}
							</div>
						);
					}

					if (Object.prototype.hasOwnProperty.call(link, 'href')) {
						element = (
							<Link
								className='block w-full border-l-4 pl-2 hover:border-red-500 border-gray-200 transition duration-300 ease-in-out'
								to={link.href}
								onClick={() => setIsMenuOpen(false)}
							>
								{link.title}
							</Link>
						);
					}

					if (Object.prototype.hasOwnProperty.call(link, 'email')) {
						element = (
							<a
								href={link.email}
								className='block w-full border-l-4 pl-2 hover:border-red-500 border-gray-200 transition duration-300 ease-in-out'
							>
								{link.title}
							</a>
						);
					}
					return (
						<React.Fragment key={i}>
							{element}
							{arr.length - 1 !== i && <div className='mx-3 border-b border-gray-300'></div>}
						</React.Fragment>
					);
				})}
			</>
		);
	}

	return (
		<div onMouseLeave={() => handleOpenAndCloseMenu(true)} className={className || `absolute ${mt} ${ml}`}>
			<img
				className={chevron ? 'cursor-pointer fill-current text-white' : 'cursor-pointer h-6'}
				onClick={() => handleOpenAndCloseMenu()}
				alt='dots-horizontal'
				src={
					chevronBlack
						? chevronBlack
						: chevron
						? '/images/list-icons/chevron-down-white.svg'
						: '/images/list-icons/dots-horizontal.svg'
				}
			/>
			<div
				className={
					isMenuOpen
						? `absolute ${width} ${left} shadow-lg z-50 rounded-md py-2 text-gray-700 font-normal bg-gray-200 text-left cursor-pointer`
						: 'hidden'
				}
			>
				<div className='absolute border-l-8 border-l-transparent border-r-transparent border-r-8 border-b-8 border-gray-200 border-transparent -top-6 left-42'></div>
				<div>{content}</div>
			</div>
		</div>
	);
};

export default TooltipMenu;
