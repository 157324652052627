import isHotkey from 'is-hotkey';
import React, { useCallback, useMemo } from 'react';
import { createEditor, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useEditor, useSlate, withReact } from 'slate-react';

import { EditorButton, Icon, Toolbar } from './EditorComponents';

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
	'mod+`': 'code',
};

// const LIST_TYPES = new Set(['numbered-list', 'bulleted-list']);

const SlateEditor = ({
	value = [{ type: 'paragraph', children: [{ text: '' }] }],
	placeholder,
	inputRef,
	...props
}) => {
	const renderElement = useCallback(props => <Element {...props} />, []);
	const renderLeaf = useCallback(props => <Leaf {...props} />, []);
	const editor = useMemo(() => withHistory(withReact(createEditor())), []);

	return (
		<div className='border border-coral-300 bg-white w-2/3 rounded-md'>
			<Slate editor={editor} value={value} {...props}>
				<Toolbar>
					<MarkButton format='bold' icon='bold' />
					<MarkButton format='italic' icon='italic' />
					<MarkButton format='underline' icon='underline' />
					{/* <MarkButton format='code' icon='code' /> */}
					{/* <BlockButton format='heading-one' icon='heading' /> */}
					{/* <BlockButton format='heading-two' icon='heading' /> */}
					{/* <BlockButton format='block-quote' icon='quote-right' /> */}
					{/* <BlockButton format='numbered-list' icon='list-ol' /> */}
					{/* <BlockButton format='bulleted-list' icon='list-ul' /> */}
					{/* <BlockButton format='image' icon='image' /> */}
				</Toolbar>
				<div ref={inputRef} className='h-full w-full max-w-64 overflow-auto'>
					<Editable
						className='contentEditor h-56 px-4 pb-4'
						renderElement={renderElement}
						renderLeaf={renderLeaf}
						placeholder={placeholder}
						spellCheck={false}
						onKeyDown={event => {
							for (const hotkey in HOTKEYS) {
								if (isHotkey(hotkey, event)) {
									event.preventDefault();
									const mark = HOTKEYS[hotkey];
									toggleMark(editor, mark);
								}
							}
						}}
					/>
				</div>
			</Slate>
		</div>
	);
};

// const toggleBlock = (editor, format) => {
// 	const isActive = isBlockActive(editor, format);
// 	const isList = LIST_TYPES.has(format);

// 	Transforms.unwrapNodes(editor, {
// 		match: n => LIST_TYPES.has(n.type),
// 		split: true,
// 	});

// 	Transforms.setNodes(editor, {
// 		type: isActive ? 'paragraph' : isList ? 'list-item' : format,
// 	});

// 	if (!isActive && isList) {
// 		const block = { type: format, children: [] };
// 		Transforms.wrapNodes(editor, block);
// 	}
// };

const toggleMark = (editor, format) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

// const isBlockActive = (editor, format) => {
// 	const [match] = Editor.nodes(editor, {
// 		match: n => n.type === format,
// 	});

// 	return !!match;
// };

const isMarkActive = (editor, format) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
	switch (element.type) {
		case 'block-quote':
			return <blockquote {...attributes}>{children}</blockquote>;
		case 'bulleted-list':
			return <ul {...attributes}>{children}</ul>;
		case 'heading-one':
			return <h1 {...attributes}>{children}</h1>;
		case 'heading-two':
			return <h2 {...attributes}>{children}</h2>;
		case 'list-item':
			return <li {...attributes}>{children}</li>;
		case 'numbered-list':
			return <ol {...attributes}>{children}</ol>;
		case 'link':
			return (
				<a href={element.url} {...attributes}>
					{children}
				</a>
			);
		case 'image':
			return <ImageElement {...{ attributes, children, element }} />;
		case 'video':
			return <VideoElement {...{ attributes, children, element }} />;
		default:
			return <p {...attributes}>{children}</p>;
	}
};

const Leaf = ({ attributes, children, leaf }) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.code) {
		children = <code>{children}</code>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

// const BlockButton = ({ format, icon, ...props }) => {
// 	const editor = useSlate();
// 	return (
// 		<Button
// 			active={isBlockActive(editor, format)}
// 			onMouseDown={event => {
// 				event.preventDefault();
// 				toggleBlock(editor, format);
// 			}}
// 		>
// 			<Icon icon={icon} {...props} />
// 		</Button>
// 	);
// };

const MarkButton = ({ format, icon }) => {
	const editor = useSlate();

	return (
		<EditorButton
			active={isMarkActive(editor, format)}
			onMouseDown={event => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			<Icon icon={icon} />
		</EditorButton>
	);
};

const VideoElement = ({ attributes, children, element }) => {
	const editor = useEditor();
	const { url } = element;

	return (
		<div {...attributes}>
			<div contentEditable={false}>
				<div
					style={{
						padding: '75% 0 0 0',
						position: 'relative',
					}}
				>
					<iframe
						title='Video'
						src={`${url}?title=0&byline=0&portrait=0`}
						frameBorder='0'
						style={{
							position: 'absolute',
							top: '0',
							left: '0',
							width: '100%',
							height: '100%',
						}}
					/>
				</div>
				<input
					value={url}
					onClick={e => e.stopPropagation()}
					style={{
						marginTop: '5px',
						boxSizing: 'border-box',
					}}
					onChange={e => {
						const path = ReactEditor.findPath(editor, element);
						Transforms.setNodes(editor, { url: e.target.value }, { at: path });
					}}
				/>
			</div>
			{children}
		</div>
	);
};

const ImageElement = ({ attributes, children, element }) => (
	<div {...attributes}>
		{children}
		<img src={element.url} alt={''} />
	</div>
);

export default SlateEditor;
