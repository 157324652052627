import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import SalesStatisticsSummary from 'components/SalesStatisticsSummary';
import Input from 'components/Input';
import LayoutHeader from 'components/LayoutHeader';
import OrderList from 'components/OrderList';
import { useToast } from 'components/Toast';
import Pill from 'components/Pill';
import useAuth from 'hooks/use-auth';
import { useStatistics } from 'hooks/use-statistics';
import OrderCountChart from './Statistics/OrderCountChart';

const Dashboard = () => {
	const { addErrorMessage } = useToast();
	const { notificationCounter } = useAuth();
	const [graphYear, setGraphYear] = useState(new Date().getFullYear());
	const [graphData, setGraphData] = useState(null);
	const [statisticsTotals, setStatisticsTotals] = useState(null);
	const { data } = useStatistics({ variables: { graphYear } });
	const statusList = [
		{ label: 'Todas', scope: 'notification' },
		{ label: 'Creado', scope: 'new' },
		{ label: 'Procesando', scope: 'processing' },
		{ label: 'Preparada', scope: 'prepared' },
		{ label: 'Enviada', scope: 'shipped' },
		{ label: 'Incidencia', scope: 'incident' },
		{ label: 'Anulada', scope: 'canceled' },
		{ label: 'Devuelto', scope: 'returned' },
	];

	const handleSelectGraphYear = e => setGraphYear(Number.parseInt(e.target.value));
	// graphData update States
	useEffect(() => {
		if (data && data.Statistics.__typename.endsWith('Fault')) {
			addErrorMessage('Estadísticas', data.Statistics.message);
		} else if (data && data.Statistics.__typename === 'StatisticsValues') {
			const filledStatisticsList = [...data.Statistics.salesList];
			filledStatisticsList.sort((a, b) => a.month - b.month);
			// Fill List with empty data
			if (filledStatisticsList.length < 12) {
				for (let month = 0; month < 12; month++) {
					if (filledStatisticsList[month]?.month !== month + 1) {
						filledStatisticsList.splice(month, 0, {
							month: month + 1 < 10 ? '0' + (month + 1) : month + 1,
							orderCountPerMonth: 0,
						});
					}
				}
			}
			setGraphData(filledStatisticsList);
			setStatisticsTotals(data.Statistics.totals);
		}
	}, [data, addErrorMessage]);

	return (
		<div className='flex'>
			<div className='md:w-2/3 mr-4'>
				{notificationCounter.ordersCount > 0 && (
					<Card className='mb-4'>
						<LayoutHeader>Resumen</LayoutHeader>
						<div className='grid grid-cols-3 grid-gap-4 mt-4'>
							{notificationCounter?.ordersCount > 0 ? (
								<div>
									<Pill className='mr-2'>{notificationCounter.ordersCount}</Pill>
									<span className='ml-2'>Pedidos pendientes</span>
								</div>
							) : (
								<div>
									<span className='ml-2'>No hay pedidos pendientes</span>
								</div>
							)}
						</div>
					</Card>
				)}
				{graphData && (
					<Card className='mb-4'>
						<LayoutHeader>Nº de ventas mensuales</LayoutHeader>
						<Input
							label='Año'
							className='my-2'
							onChange={handleSelectGraphYear}
							type='number'
							min='2021'
							max='2099'
							step='1'
							value={graphYear}
						/>
						<OrderCountChart
							graphData={graphData.map(monthData => ({
								month: monthData.month,
								total: monthData.orderCountPerMonth,
							}))}
						/>
					</Card>
				)}
				<OrderList
					pageScope='notification'
					title='Últimos Pedidos'
					statusList={statusList}
					paginationLimit={20}
				/>
			</div>
			{statisticsTotals && <SalesStatisticsSummary statistics={statisticsTotals} />}
		</div>
	);
};

export default Dashboard;
