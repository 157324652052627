import React from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import FaqsForm from './FaqsForm';

import { useFaq, useUpdateFaq } from 'hooks/use-faqs';

const FAQEdit = () => {
	const navigate = useNavigate();
	const { faqId: id } = useParams();
	const { faq, loading } = useFaq(id);
	const [updateFaq] = useUpdateFaq({
		onCompleted: () => {
			navigate('/dashboard/faqs');
		},
	});

	return (
		<LoadingView loading={loading}>
			<LayoutHeader>Editar FAQ</LayoutHeader>
			<FaqsForm faq={faq} onSubmit={input => updateFaq({ variables: { id, input } })} />
		</LoadingView>
	);
};

export default FAQEdit;
