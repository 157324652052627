import React, { useContext, createContext, useEffect, useCallback, useState } from 'react';

/**
 * @param {String} position top-right, top-left, bottom-right, bottom-left
 */
const ToastMessages = ({ toastList, setToastList, position, autoDelete = false, dismissTime = 8000 }) => {
	const deleteToast = useCallback(id => setToastList(prev => prev.filter(e => e.id !== id)), [setToastList]);

	switch (position) {
		case 'top-right':
			position = 'inset-y-0 right-0 animate-right';
			break;
		case 'top-left':
			position = 'inset-y-0 left-0 animate-left';
			break;
		case 'bottom-right':
			position = 'bottom-0 right-0 animate-right';
			break;
		case 'bottom-left':
			position = 'bottom-0 left-0 animate-left';
			break;
		default:
			position = 'inset-y-0 right-0 animate-right';
			break;
	}

	useEffect(() => {
		if (autoDelete) {
			const interval = setInterval(() => {
				if (toastList.length > 0) {
					deleteToast(toastList[0].id);
				}
			}, dismissTime);

			// The useEffect return value is a function called to do the cleanup:
			// It will be executed when we unmount the component or before a new
			// render happens.
			return () => clearInterval(interval);
		}
	}, [toastList, autoDelete, dismissTime, deleteToast]);

	return (
		<>
			<div className={`box-border text-sm fixed m-2 ${position} h-0 z-40`}>
				{toastList.map((toast, i) => (
					<div
						key={i}
						className={`bg-white bg-no-repeat mx-0 mt-0 mb-4 overflow-hidden p-8 pointer-events-auto relative text-black hover:opacity-100 h-auto py-5 px-5 text-white ${position}`}
						style={{ backgroundColor: toast.backgroundColor }}
					>
						<button
							className='border-0 cursor-pointer float-right font-bold text-base leading-none p-0 relative text-white'
							onClick={() => deleteToast(toast.id)}
						>
							X
						</button>
						<div className='float-left mr-4'>
							{toast.type === 'success' && (
								<img className='h-8 w-8' src='/images/toast-icons/check.svg' alt='' />
							)}
							{toast.type === 'error' && (
								<img className='h-8 w-8' src='/images/toast-icons/error.svg' alt='' />
							)}
						</div>
						<div>
							<p className='w-72 font-bold h-4 text-base mt-0 mb-1 text-left'>{toast.title}</p>
							<p className='my-0 mr-0 -ml-px text-left truncate'>{toast.description}</p>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

const ToastContext = createContext();

// Provider component that wraps the Toast object ...
// ... available to any child component that wants to display a success or error message
export default function Toast({ children }) {
	const [toastList, setToastList] = useState([]);

	const addSuccessMessage = useCallback(
		(title, description) => {
			// Add success message
			setToastList(prev => [
				...prev,
				{
					id: prev.length > 0 ? prev?.at(-1).id + 1 : 1,
					title,
					description,
					type: 'success',
					backgroundColor: '#61bfad',
				},
			]);
		},
		[setToastList]
	);

	const addErrorMessage = useCallback(
		(title, description) => {
			// Add success message
			setToastList(prev => [
				...prev,
				{
					id: prev.length > 0 ? prev?.at(-1).id + 1 : 1,
					title,
					description,
					type: 'error',
					backgroundColor: '#ff8b8b',
				},
			]);
		},
		[setToastList]
	);

	return (
		<ToastContext.Provider value={{ addSuccessMessage, addErrorMessage }}>
			<ToastMessages toastList={toastList} setToastList={setToastList} position='top-right' autoDelete='true' />
			{children}
		</ToastContext.Provider>
	);
}

export const useToast = () => useContext(ToastContext);
