import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from 'hooks/use-auth';

import Button from 'components/Button';
import Input from 'components/Input';

const LoginForm = () => {
	const { isLoggedIn, loggedInStatus, setUserData, user, useLogin } = useAuth();
	const [login, { loading }] = useLogin();

	const [error, setError] = useState(null);
	const [loginFormData, setLoginFormData] = useState({
		email: '',
		password: '',
	});

	// Delete form email and password values if login successful
	useEffect(() => {
		if (isLoggedIn === loggedInStatus.yes) {
			setLoginFormData({ email: '', password: '' });
		}
	}, [isLoggedIn, loggedInStatus.yes]);

	useEffect(() => {
		if (user && user?.__typename === 'AuthorizationFault') {
			setError(user.message);
		}
		// All Errors implement Fault
		else if (user && user?.__typename.endsWith('Fault')) {
			setError(user.message);
		}
	}, [user]);

	const handleEmail = e => {
		setLoginFormData({ ...loginFormData, email: e.target.value });
	};

	const handleLogin = e => {
		e.preventDefault();
		if (loginFormData.email === '' || loginFormData.password === '') {
			setError('Se requiere email y contraseña');
			return null;
		}
		login({
			variables: {
				input: loginFormData,
			},
		}).then(response => {
			setUserData(response.data.SignIn);
		});
	};

	const handlePassword = e => {
		setLoginFormData({ ...loginFormData, password: e.target.value });
	};

	if (isLoggedIn === loggedInStatus.yes) {
		return user && user.type === 'merchant' && (user.status === 'revision' || user.status === 'pending') ? (
			<Navigate to={`/dashboard/asociados/edit/${user.Merchant.id}`} replace />
		) : (
			<Navigate to='/dashboard' replace />
		);
	}

	if (loading) {
		return null;
	}

	return (
		<form
			onSubmit={handleLogin}
			className='mx-auto flex w-full max-w-md flex-col items-center justify-center rounded bg-white px-8 py-8 pt-8 shadow-md'
		>
			<img
				src={`${process.env.REACT_APP_STATICS}/mp/logob.svg`}
				className='mx-auto block h-24 w-full overflow-hidden text-transparent'
				alt={`${process.env.REACT_APP_MARKETPLACE_NAME}`}
			/>
			<div className='w-full pb-4'>
				<label htmlFor='email' className='block pb-2 text-sm font-bold uppercase'>
					Email
				</label>
				<Input
					type='email'
					name='email'
					id='email'
					className={'flex-1'}
					placeholder={`demo@${process.env.REACT_APP_URL_DOMAIN}`}
					value={loginFormData.email}
					onChange={handleEmail}
					required
				/>
			</div>
			<div className='w-full pb-4'>
				<label htmlFor='password' className='block pb-2 text-sm font-bold uppercase'>
					Contraseña
				</label>
				<Input
					type='password'
					name='password'
					id='password'
					className={'flex-1'}
					placeholder='Tu Contraseña'
					value={loginFormData.password}
					onChange={handlePassword}
					required
				/>
				<p className='text-gray-600'>Si olvidó la contraseña, contacte con el Administrador</p>
			</div>
			<Button onClick={handleLogin} type='submit' disabled={loading}>
				{loading ? 'Enviando…' : 'Entrar al sistema'}
			</Button>
			{error && <p className='mt-4 border border-red-500 bg-gray-200 p-2 text-red-500'>{error}</p>}
		</form>
	);
};

export default LoginForm;
