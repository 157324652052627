import AuthorizationBoundary from 'components/AuthorizationBoundary';
import EventList from 'components/EventList';
import Card from 'components/Card';
import Currency from 'components/Currency';
import LayoutHeader, { SectionHeader } from 'components/LayoutHeader';
import { useLazyOrder } from 'hooks/use-orders';
import formatDate from 'lib/formatDate';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import translasteStatus from 'lib/translasteStatus';
import printUserName from 'lib/printUserName';

import MerchantShippingDetails from './MerchantShippingDetails';
import Message from 'components/Message';

const OrderDetail = () => {
	const { orderId } = useParams();
	const [getLazyOrder, { data }] = useLazyOrder({
		orderId,
	});
	const [totalAmount, setTotalAmount] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);

	// Excecute once at page load
	useEffect(() => {
		getLazyOrder();
	}, [getLazyOrder]);

	useEffect(() => {
		if (data) {
			setTotalAmount(
				data.Order.MerchantShippings?.map(ms =>
					ms?.OrderItems?.reduce(
						(sum, { discountedPrice, price, sentQuantity, quantity }) =>
							sum + (discountedPrice || price) * (sentQuantity === null ? quantity : sentQuantity),
						0
					)
				)?.reduce((sum, item) => item + sum, 0)
			);
			setIsFormFilled(true);
		}
	}, [data]);

	let merchantShippingsSortByStatus = [];

	if (data?.Order.MerchantShippings) {
		merchantShippingsSortByStatus = data?.Order.MerchantShippings?.slice()?.sort((a, b) => {
			if (a.status === 'new' && ['prepared', 'canceled', 'shipped'].includes(b.status)) {
				return -1;
			}

			if (['prepared', 'canceled', 'shipped'].includes(a.status) && b.status === 'new') {
				return 1;
			}

			return 0;
		});
	}

	return (
		isFormFilled && (
			<>
				<Card className='mb-4'>
					<LayoutHeader>Detalles del Pedido {orderId}</LayoutHeader>
					{(data?.Order.status === 'new' || data?.Order.status === 'processing') && (
						<Message error className='w-full sm:w-72'>
							Recuerda que tienes 24h para preparar el pedido
						</Message>
					)}
					<div className='mt-5'>
						<SectionHeader className='mt-5'>General</SectionHeader>
						<div className='flex flex-col w-1/5 mt-5'>
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Fecha de creación</p>
								<p>{formatDate(data?.Order.orderDate)}</p>
							</div>

							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Estado</p>
								<span className='border border-coral-500 text-coral-500 font-medium text-center py-1 px-2'>
									{translasteStatus(data?.Order.status)}
								</span>
							</div>

							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Accepta Sustitución</p>
								<p>{data?.Order.authorizeProductSubstitution ? 'Si' : 'No'}</p>
							</div>
							<div className='flex content-between gap-4'>
								<p className='text-coral-500 flex-1'>Usuario</p>
								<p>{printUserName(data?.Order?.User)}</p>
							</div>
						</div>

						<div className='grid grid-cols-2 grid-gap-4 w-full mt-5'>
							<div>
								<SectionHeader>Facturación</SectionHeader>
								<p className='mt-5'>{data?.Order.billName}</p>
								<div>
									<p>{data?.Order.billAddress1}</p>
									<p>{data?.Order.billAddress2}</p>
									<p>
										{data?.Order.billZip} {data?.Order.billProvince}
									</p>
									<p>{data?.Order.billCountry}</p>
									{data?.Order.email && <p>Mail: {data?.Order.email}</p>}
									<p>
										{data?.Order.billPhone && (
											<span>Tel: {data?.Order.billPhone || data?.Order.shippingPhone}</span>
										)}
									</p>
								</div>
							</div>
							<div>
								<SectionHeader>Envío</SectionHeader>
								{data?.Order.processingMethod === 'shipping' ? (
									<div>
										<p className='mt-5'>{data?.Order.shippingName}</p>
										<p>{data?.Order.shippingAddress1}</p>
										<p>{data?.Order.shippingAddress2}</p>
										<p>
											{data?.Order.shippingZip} {data?.Order.shippingProvince}
										</p>
										<p>{data?.Order.shippingCountry}</p>
										<p>{data?.Order.billNotes}</p>
									</div>
								) : (
									'Recoger en tienda'
								)}
							</div>
						</div>
						<div className='grid grid-gap-4 grid-cols-2 mt-5'></div>
					</div>

					{data?.Order.processingMethod === 'shipping' && (
						<div className='flex'>
							<span className='flex-1 uppercase font-semibold'>Gastos de envío</span>
							{data?.Order?.shippingPrice ? (
								<Currency className='flex-1 font-bold'>{data?.Order?.shippingPrice}</Currency>
							) : (
								<p className='flex-1 font-bold'>Envio gratuito</p>
							)}
						</div>
					)}

					<AuthorizationBoundary for={['admin']}>
						<div className='flex'>
							<span className='flex-1 uppercase font-semibold'>Importe Total</span>
							<Currency className='flex-1 font-bold'>{totalAmount + data?.Order?.shippingPrice}</Currency>
						</div>
					</AuthorizationBoundary>
				</Card>
				<Card className='mb-4'>
					{merchantShippingsSortByStatus?.map(merchantShipping => (
						<MerchantShippingDetails
							key={data?.Order.MerchantShippings.indexOf(merchantShipping)}
							merchantShippingIndex={data?.Order.MerchantShippings.indexOf(merchantShipping)}
							orderId={orderId}
							orderData={data?.Order}
							merchantShipping={merchantShipping}
							setTotalAmount={setTotalAmount}
							getLazyOrder={getLazyOrder}
						/>
					))}
				</Card>

				<Card className='mb-4'>
					{data && <EventList events={data?.Order?.Events?.filter(event => event.message !== null)} />}
				</Card>
			</>
		)
	);
};
export default OrderDetail;
