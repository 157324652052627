import React from 'react';

import AccountForm from './AccountForm';
import AdministrativeForm from './AdministrativeForm';
import CommercialForm from './CommercialForm';

const MerchantForm = ({ adminLayout, data, setDirty }) => {
	return (
		<>
			<AccountForm
				adminLayout={adminLayout}
				className='shadow-none'
				userData={data?.Merchant.User}
				setDirty={setDirty}
			/>
			<AdministrativeForm
				adminLayout={adminLayout}
				className='border-t border-dashed border-coral-300 mt-10 pt-10'
				merchantData={data?.Merchant}
				userData={data?.Merchant.User}
				setDirty={setDirty}
			/>
			<CommercialForm
				adminLayout={adminLayout}
				className='border-t border-dashed border-coral-300 mt-10 pt-10'
				merchantData={data?.Merchant}
				setDirty={setDirty}
			/>
		</>
	);
};

export default MerchantForm;
