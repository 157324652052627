import React from 'react';
import { Navigate } from 'react-router-dom';

import EmptyLayout from './EmptyLayout';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Toast from 'components/Toast';
import { Link } from 'react-router-dom';

import useAuth from 'hooks/use-auth';
import ScrollTopArrow from 'components/ScrollTopArrow';

const BaseLayout = ({ children }) => {
	const { isLoggedIn, loggedInStatus } = useAuth();
	if (isLoggedIn === loggedInStatus.no) return <Navigate to='/login' replace />;

	return (
		<EmptyLayout>
			<Toast>
				<Header />
				<div className='flex flex-1 flex-col md:flex-row-reverse'>
					<div className='flex-1 p-5'>
						<div className='h-full max-w-6xl mx-auto'>{children}</div>
					</div>
					<Sidebar />
					<Link className='fixed text-white bottom-0 left-0 px-10 py-2' to={'/dashboard/versions'}>
						v.{`${process.env.REACT_APP_VERSION}`}
					</Link>
				</div>
				<ScrollTopArrow />
			</Toast>
		</EmptyLayout>
	);
};

export default BaseLayout;
