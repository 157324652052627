import React from 'react';

import Button from 'components/Button';
import { Link } from 'react-router-dom';

const DottedElement = ({
	title = '',
	description = '',
	date = '',
	button = false,
	more = false,
	link,
	bottom = '',
	...props
}) => (
	<div
		{...props}
		className={`pb-4 pt-2 px-2 border-t-2 ${bottom} border-dotted border-red-400 grid grid-flow-col gap-4`}
	>
		<div>
			<p className='mb-2 font-medium'>{title}</p>
			{description.length > 0 && (
				<>
					<p dangerouslySetInnerHTML={{ __html: description }}></p>
					<br />
				</>
			)}
		</div>
		<div className='flex h-full'>
			<p className='w-full text-right'>{date}</p>
			{button && (
				<Button to={link} className='m-auto'>
					Ver más
				</Button>
			)}
			{more && (
				<Link className='mb-0 align-bottom mt-auto'>
					<span className='text-red-400 align-bottom leading-6 font-medium text-3xl mr-1'>+</span>
					más
				</Link>
			)}
		</div>
	</div>
);

export default DottedElement;
