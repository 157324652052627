import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import Checkout from 'components/Checkout';
import Input from 'components/Input';
import InputForm from 'components/InputForm';
import LayoutHeader from 'components/LayoutHeader';
import LayoutTitle from 'components/LayoutTitle';
import Modal from 'components/Modal';
import Score from 'components/Score';
import SelectForm from 'components/SelectForm';
import TextareaForm from 'components/TextareaForm';
import { useToast } from 'components/Toast';
import { useStripeStatus, useUpdateMerchant } from 'hooks/use-merchants';
import { useMainProductCategories } from 'hooks/use-product-categories';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'hooks/use-auth';

const AdministrativeForm = ({ adminLayout, className, merchantData, userData, setDirty = () => {} }) => {
	const { locale } = useAuth();
	const disableNonTranslatableFields = locale !== process.env.REACT_APP_DEFAULT_LOCALE;
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [openModalConditions, setOpenModalConditions] = useState(false);
	const [openModalStripe, setOpenModalStripe] = useState(false);
	const [stripeButtonLabel, setStripeButtonLabel] = useState('');
	const [stripeMessage, setStripeMessage] = useState('');
	const [stripeStatusResponse, setStripeStatusResponse] = useState(null);
	const [score, setScore] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);

	const [update] = useUpdateMerchant();
	const [getStripeStatus] = useStripeStatus({
		variables: {
			MerchantId: merchantData.id,
		},
		onCompleted: ({ StripeStatus }) => {
			if (StripeStatus && StripeStatus.__typename === 'StripeStatus') {
				setStripeStatusResponse(StripeStatus);
				setStripeMessage(StripeStatus?.message);
			}
			// All Errors implement Fault
			else if (StripeStatus && StripeStatus.__typename.endsWith('Fault')) {
				setStripeMessage('Error');
				addErrorMessage('Ficha Administrativa', StripeStatus?.message);
			}
		},
	});
	const { data: categories } = useMainProductCategories();

	const {
		handleSubmit,
		control,
		register,
		setValue,
		getValues,
		watch,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			administrativeForm: {
				administrativeAddress1: '',
				administrativeAddress2: '',
				administrativeBusinessName: '',
				administrativeCity: '',
				administrativeCountry: 'ES',
				administrativeDescription: '',
				administrativeEmail: '',
				administrativeName: '',
				administrativePhone: '',
				administrativeProvince: '',
				administrativeUrl: '',
				administrativeVat: '',
				administrativeZip: '',
				acceptConditions: false,
				meetRequirements: false,
				ProductCategoryId: '',
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (merchantData) {
			setValue('administrativeForm', {
				administrativeAddress1: merchantData.administrativeAddress1 || '',
				administrativeAddress2: merchantData.administrativeAddress2 || '',
				administrativeBusinessName: merchantData.administrativeBusinessName || '',
				administrativeCity: merchantData.administrativeCity || '',
				administrativeCountry: 'ES',
				administrativeDescription: merchantData.administrativeDescription || '',
				administrativeEmail: merchantData.administrativeEmail || '',
				administrativeName: merchantData.administrativeName || '',
				administrativePhone: merchantData.administrativePhone || '',
				administrativeProvince: merchantData.administrativeProvince || '',
				administrativeUrl: merchantData.administrativeUrl || '',
				administrativeVat: merchantData.administrativeVat || '',
				administrativeZip: merchantData.administrativeZip || '',
				acceptConditions: merchantData.acceptConditions || false,
				meetRequirements: merchantData.meetRequirements || false,
				ProductCategoryId: merchantData.ProductCategoryId || categories?.ProductCategories[0].id,
			});
			setDirty(false);
			setIsFormFilled(true);
		}
	}, [merchantData, categories, setValue, setDirty]);

	watch(({ administrativeForm }) => {
		calculateFormScore(administrativeForm);
	});

	// Change Stripe button label
	useEffect(() => {
		if (!merchantData?.stripeAccountId) setStripeButtonLabel('Darse de alta en Stripe');
		if (merchantData?.stripeAccountId && userData?.status === 'pending')
			setStripeButtonLabel('Continuar alta en Stripe');
	}, [merchantData, userData]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	// Check Stripe Status
	useEffect(async () => {
		if (merchantData) {
			getStripeStatus();
		}
	}, []);

	// Using calculateFormScore in combination with watch "score" state
	const calculateFormScore = formData => {
		if (formData) {
			let total = Object.entries(formData).length;
			let count = 0;

			for (var [, value] of Object.entries(formData)) {
				if (value) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};
	const thridConditionsLink = (
		<p>
			Acepto las{' '}
			<a
				className='text-red-500 cursor-pointer'
				href={`${process.env.REACT_APP_STATICS}/legal/condiciones_de_adhesion.pdf`}
				target='_blank'
				rel='noopener noreferrer'
			>
				Condiciones de adhesión y servicio.
			</a>
		</p>
	);

	const conditionsModalObject = {
		title: 'Condiciones de adhesión',
		btnText: getValues('administrativeForm.acceptConditions') ? '' : 'Aceptar',
		cancelBtnText: getValues('administrativeForm.acceptConditions') ? 'Cerrar' : 'Rechazar',
	};

	const stripeModalObject = {
		title: 'Pago de cuota',
		btnText: '',
		cancelBtnText: 'Cerrar',
	};

	const onSubmit = ({ administrativeForm }) => {
		if (administrativeForm) {
			update({
				variables: {
					id: merchantData?.id,
					input: administrativeForm,
					locale,
				},
			}).then(({ data }) => {
				if (data?.MerchantUpdate && data.MerchantUpdate.__typename === 'MerchantUpdate') {
					setDirty(false);
					if (data.MerchantUpdate.Url) {
						addSuccessMessage(
							'Ficha Administrativa',
							'abriendo Stripe para acabar el proceso de OnBoarding'
						);
						window.open(data.MerchantUpdate.Url, '_self');
					} else {
						addSuccessMessage('Ficha Administrativa', 'actualizada correctamente');
					}
				}
				// All Errors implement Fault
				else if (data.MerchantUpdate && data.MerchantUpdate.__typename.endsWith('Fault')) {
					addErrorMessage('Ficha Administrativa', data.MerchantUpdate.message);
				}
			});
		}
	};

	return (
		isFormFilled && (
			<div className={className}>
				<Score data={score} name='administrativa' content='' />

				<LayoutHeader>Ficha administrativa</LayoutHeader>

				<p>
					Ficha administrativa donde insertar los datos de la empresa a modo administrativo y de facturación.
				</p>

				<br />
				<LayoutTitle>Datos de Empresa</LayoutTitle>
				<form onSubmit={handleSubmit(onSubmit)}>
					<InputForm
						name='administrativeForm.administrativeName'
						control={control}
						register={register}
						label='Nombre Administrativo'
						placeholder='Nombre Administrativo'
						required
					/>
					<InputForm
						name='administrativeForm.administrativeEmail'
						control={control}
						register={register}
						label='Email Administrativo'
						placeholder='Email Administrativo'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativePhone'
						control={control}
						register={register}
						label='Teléfono Administrativo'
						placeholder='Teléfono Administrativo'
						type='tel'
						pattern='^\+?[0-9]*'
						maxLength='13'
						minLength='9'
						required
						disabled={disableNonTranslatableFields}
					/>
					<SelectForm
						name='administrativeForm.ProductCategoryId'
						control={control}
						register={register}
						label='Categoría'
						placeholder='Categoría'
						required
						width='w-1/3'
						options={categories?.ProductCategories || []}
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeBusinessName'
						control={control}
						register={register}
						label='Razón social'
						placeholder='Razón social'
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeVat'
						control={control}
						register={register}
						label='CIF'
						placeholder='CIF'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeAddress1'
						control={control}
						register={register}
						label='Dirección Linea 1'
						placeholder='Dirección Linea 1'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeAddress2'
						control={control}
						register={register}
						label='Dirección Linea 2'
						placeholder='Dirección Linea 2'
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeZip'
						control={control}
						register={register}
						label='Código Postal'
						placeholder='Código Postal'
						pattern='[0-9]*'
						maxLength='5'
						minLength='5'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeCity'
						control={control}
						register={register}
						label='Localidad'
						placeholder='Localidad'
						required
						disabled={disableNonTranslatableFields}
					/>
					<InputForm
						name='administrativeForm.administrativeProvince'
						control={control}
						register={register}
						label='Provincia'
						placeholder='Provincia'
						disabled={disableNonTranslatableFields}
					/>
					<Input className='border-none' value='ES' label='País' readOnly />
					<InputForm
						name='administrativeForm.administrativeUrl'
						control={control}
						register={register}
						label='Web'
						placeholder='Web'
						disabled={disableNonTranslatableFields}
					/>
					<div className='flex w-full max-w-screen-md text-gray-400 italic'>
						<p className='w-full text-right'>*Máximo 200 caracteres con espacios</p>
					</div>
					<TextareaForm
						name='administrativeForm.administrativeDescription'
						control={control}
						maxLength='200'
						label='Actividad comercial desarrollada'
						placeholder='Actividad comercial desarrollada'
						required
					/>
					<br />
					<Input
						label='Estado de Stripe'
						value={stripeMessage}
						className={`font-bold text-lg border-none ${
							stripeStatusResponse?.isActive ? 'text-green-400' : 'text-red-500'
						}`}
						readOnly
					/>
					<div className='w-full p-4 px-10 py-12 pr-20 shadow-md'>
						<p className='w-full uppercase font-medium text-xl'>Aceptación condiciones de adhesión</p>
						<div className='flex'>
							{process.env.REACT_APP_MARKETPLACE_TYPE === 'own' && !adminLayout ? (
								<>
									<div className='w-1/2'>
										<CheckForm
											onClick={() => setOpenModalConditions(true)}
											name='administrativeForm.acceptConditions'
											control={control}
											noToogle
											label={
												<p>
													Acepto las{' '}
													<span className='text-red-500 cursor-pointer'>
														Condiciones de adhesión y servicio.
													</span>
												</p>
											}
											disabled={
												adminLayout ? false : getValues('administrativeForm.acceptConditions')
											}
										/>
									</div>
									{getValues('administrativeForm.meetRequirements') ? (
										<CheckForm
											name='administrativeForm.meetRequirements'
											control={control}
											label='Cuota pagada'
											disabled={
												adminLayout ? false : getValues('administrativeForm.meetRequirements')
											}
										/>
									) : (
										<Button
											onClick={e => {
												e.preventDefault();
												setOpenModalStripe(true);
											}}
											disabled={adminLayout ? false : merchantData?.meetRequirements}
										>
											Pagar cuota de adhesión
										</Button>
									)}
								</>
							) : (
								<>
									<div className='w-1/2'>
										<CheckForm
											name='administrativeForm.acceptConditions'
											control={control}
											label={thridConditionsLink}
											disabled={adminLayout ? false : merchantData?.acceptConditions}
										/>
									</div>
									<div className='w-1/2'>
										<CheckForm
											name='administrativeForm.meetRequirements'
											control={control}
											label='Acepto sistema de pagos'
											disabled={adminLayout ? false : merchantData?.meetRequirements}
										/>
									</div>
								</>
							)}
						</div>
					</div>
					<div className='flex w-full pt-10'>
						<Button type='submit'>Actualizar ficha administrativa</Button>
						<AuthorizationBoundary for={['merchant']}>
							{userData?.status === 'pending' && (
								<Button
									onClick={handleSubmit(({ administrativeForm }) => {
										if (administrativeForm) {
											if (
												!adminLayout &&
												administrativeForm.meetRequirements &&
												administrativeForm.acceptConditions
											) {
												setStripeButtonLabel('Abriendo Stripe...');
												update({
													variables: {
														id: merchantData?.id,
														input: administrativeForm,
														locale,
														stripe: true,
													},
												}).then(({ data }) => {
													if (
														data?.MerchantUpdate &&
														data.MerchantUpdate.__typename === 'MerchantUpdate'
													) {
														setDirty(false);
														if (data.MerchantUpdate.Url) {
															addSuccessMessage(
																'Ficha Administrativa',
																'abriendo Stripe para acabar el proceso de OnBoarding'
															);
															window.open(data.MerchantUpdate.Url, '_self');
														}
													}
													// All Errors implement Fault
													else if (
														data.MerchantUpdate &&
														data.MerchantUpdate.__typename.endsWith('Fault')
													) {
														addErrorMessage(
															'Ficha Administrativa',
															data.MerchantUpdate.message
														);
													}
												});
											} else if (
												!adminLayout &&
												(administrativeForm.meetRequirements === false ||
													administrativeForm.acceptConditions === false)
											) {
												addErrorMessage(
													'Ficha Administrativa',
													`Debe aceptar condiciones de adhesión ${
														process.env.REACT_APP_MARKETPLACE_TYPE === 'own' &&
														'y pago de cuota'
													} para continuar'`
												);
											}
										}
									})}
									className='ml-10'
									type='submit'
								>
									{stripeButtonLabel}
								</Button>
							)}
						</AuthorizationBoundary>
					</div>
				</form>
				<Modal
					modalObject={conditionsModalObject}
					openModal={openModalConditions}
					setOpenModal={setOpenModalConditions}
					handleSubmit={() => {
						if (!getValues('administrativeForm.acceptConditions')) {
							setValue('administrativeForm.acceptConditions', true);
						}
						setOpenModalConditions(false);
					}}
					onCancel={() => {
						if (!getValues('administrativeForm.acceptConditions')) {
							setValue('administrativeForm.acceptConditions', false);
						}
					}}
					fullScreen
				>
					<iframe
						src={`${process.env.REACT_APP_STATICS}/legal/condiciones_de_adhesion.pdf#view=fitH`}
						title='pdf'
						height='100%'
						width='100%'
					/>
				</Modal>
				<Modal
					modalObject={stripeModalObject}
					openModal={openModalStripe}
					setOpenModal={setOpenModalStripe}
					onCancel={() => setOpenModalStripe(false)}
				>
					<>
						<span className='text-start ml-10 mt-4 mr-auto'>
							<p className='mb-4'>{`Puede realizar una transferencia con los siguientes datos y enviar comprobante al administrador <${process.env.REACT_APP_NOTIFICATIONS_EMAIL}>:`}</p>
							<div className='p-2 border rounded-lg bg-gray-200 font-medium'>
								<p>IBAN ES0821003340152200130064</p>
								<p>CAIXESBBXXX</p>
								<p>{`Concepto: Cuota adhesion de ${getValues(
									'administrativeForm.administrativeName'
								)}`}</p>
							</div>
							<p className='mt-4'>O si prefiere, puede pagar con tarjeta</p>
						</span>
						<Checkout
							onSuccess={() => {
								setOpenModalStripe(false);
								setValue('administrativeForm.meetRequirements', true);
							}}
						/>
					</>
				</Modal>
			</div>
		)
	);
};

export default AdministrativeForm;
