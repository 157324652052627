import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const client = new ApolloClient({
	link: createUploadLink({
		uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
		credentials: 'include',
	}),
	cache: new InMemoryCache(),
});

const Apollo = ({ children }) => <ApolloProvider client={client}>{children}</ApolloProvider>;

export const clearCache = () => client.clearStore();

export default Apollo;
