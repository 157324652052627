import Field from 'components/Field';
import React from 'react';
import { useController } from 'react-hook-form';

const TextareaForm = ({ id, children, maxLength = '500', width = 'w-2/3', name, control, ...props }) => {
	const { field } = useController({ name, control });

	// Prevent React Warning about value being null
	if (field.value === null || field.value === undefined) {
		field.value = '';
	}

	return (
		<Field align='items-start' {...props}>
			<textarea
				id={id}
				{...field}
				maxLength={maxLength}
				{...props}
				className={`border mt-1 p-4 text-lg rounded-md border-coral-300 outline-none focus:outline-none h-32 ${width} ${
					props?.disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
				} ${props.className || ''}`}
			>
				{children}
			</textarea>
		</Field>
	);
};

export default TextareaForm;
