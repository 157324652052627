import React, { useEffect, useState } from 'react';
import ContentTable from 'components/ContentTable';
import LoadingView from 'components/LoadingView';
import translasteStatus from 'lib/translasteStatus';

import { Link, useParams } from 'react-router-dom';

import useAuth from 'hooks/use-auth';
import { useLazyMerchants } from 'hooks/use-merchants';

import Button from 'components/Button';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import Pagination from 'components/Pagination';
import SearchBox from 'components/SearchBox';
import { useToast } from 'components/Toast';
import TooltipMenu from 'components/TooltipMenu';
import columnTitleElement from 'lib/columnTitleElement';

const paginationLimit = 50;

const MerchantsList = ({ statusList, pageScope }) => {
	const { addErrorMessage, addSuccessMessage } = useToast();
	const { status: paramScope } = useParams();
	const { useUpdateUser } = useAuth();
	const [scope, setScope] = useState(pageScope || paramScope);
	const [orderScope, setOrderScope] = useState('commercialName_asc');
	const [merchants, setMerchants] = useState(null);
	const [merchantSearch, setMerchantSearch] = useState('');
	const [offset, setOffset] = useState(0);

	const [getLazyMerchants, { data, loading }] = useLazyMerchants({
		search: merchantSearch,
		scope,
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
	});
	const [updateUser] = useUpdateUser({
		onCompleted: ({ ProfileUpdate }) => {
			if (ProfileUpdate.id) {
				setOffset(0);
				getLazyMerchants();
			}
		},
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyMerchants();
	}, [getLazyMerchants]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && data.Merchants.__typename.endsWith('Fault')) {
			addErrorMessage('Productos', data?.Merchants.message);
		} else {
			setMerchants(data?.Merchants);
		}
	}, [data, addErrorMessage]);

	const handleSearch = input => {
		setMerchantSearch(input);
		setOffset(0);
		getLazyMerchants();
	};

	const handleClearSearch = () => {
		setMerchantSearch('');
		setOffset(0);
		getLazyMerchants();
	};

	const merchantMenuList = ({ merchantId, userId, userStatus }) => {
		const list = [{ title: 'Editar', href: `/dashboard/asociados/edit/${merchantId}` }];

		if (userStatus !== 'active') {
			list.push({
				title: 'Activar',
				onClick: () => {
					updateUser({
						variables: {
							id: userId,
							input: { status: 'active' },
						},
					}).then(({ data }) => {
						if (data?.ProfileUpdate?.id) {
							addSuccessMessage('Asociado', 'Activado correctamente');
						}
					});
				},
			});
		}

		if (userStatus !== 'inactive') {
			list.push({
				title: 'Desactivar',
				onClick: () => {
					updateUser({
						variables: {
							id: userId,
							input: { status: 'inactive' },
						},
					}).then(({ data }) => {
						if (data?.ProfileUpdate?.id) {
							addSuccessMessage('Asociado', 'Desactivado correctamente');
						}
					});
				},
			});
		}
		return list;
	};

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyMerchants();
			},
		}));
	};

	const orderByCommercialName = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'commercialName_asc' },
			{ label: 'Descendente', scope: 'commercialName_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyMerchants();
			},
		}));
	};

	const filterStatusList = () => {
		return statusList?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
				getLazyMerchants();
			},
		}));
	};

	return (
		<Card className='mb-20'>
			<LayoutHeader actions={[CreateMerchantButton]}>Listado de Asociados</LayoutHeader>
			<span className='flex w-full justify-between'>
				<SearchBox
					placeholder='Busca un Asociado'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				<Pagination
					offset={offset}
					setOffset={setOffset}
					count={merchants?.count}
					hasMore={merchants?.hasMore}
					limit={paginationLimit}
				/>
			</span>
			<LoadingView loading={loading} whileLoading='Buscando asociados…' className='text-gray-600'>
				<ContentTable
					key={21}
					columns={[
						columnTitleElement('Fecha alta', orderByDate),
						columnTitleElement('Asociado', orderByCommercialName),
						columnTitleElement('Estado', statusList && filterStatusList),
					]}
					content={merchants?.List}
					render={(i, index) =>
						i && (
							<tr
								className='border-t border-dashed border-coral-300 text-gray-700'
								key={index}
								data-id={`merchant-${i.id}`}
							>
								<td className='px-4 py-2'>{new Date(i.createdAt).toLocaleDateString()}</td>
								<td className='px-4 py-2'>
									<Link to={`/dashboard/asociados/edit/${i.id}`}>{i.commercialName}</Link>
								</td>
								<td className='px-4 py-2'>{translasteStatus(i.User?.status)}</td>
								<td className='px-6'>
									<div className='h-16'>
										<TooltipMenu
											menuList={merchantMenuList({
												merchantId: i.id,
												userId: i.User?.id,
												userStatus: i.User?.status,
											})}
										/>
									</div>
								</td>
							</tr>
						)
					}
					empty={<p className='text-center text-gray-600'>No hay Asociados que mostrar</p>}
				/>
			</LoadingView>
		</Card>
	);
};

const CreateMerchantButton = () => (
	<Button as={Link} to='/dashboard/asociados/new'>
		Nuevo Asociado
	</Button>
);

export default MerchantsList;
