import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from 'components/Button';
import Card from 'components/Card';
import Check from 'components/Check';
import ContentTable from 'components/ContentTable';
import DownloadCSVButton from 'components/DownloadCSVButton';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import Pagination from 'components/Pagination';
import SearchBox from 'components/SearchBox';
import TooltipMenu from 'components/TooltipMenu';
import columnTitleElement from 'lib/columnTitleElement';
import formatDate from 'lib/formatDate';
import translasteStatus from 'lib/translasteStatus';
import useAuth from 'hooks/use-auth';
import { useUpdateProducts, useLazyProducts, useUpdateProduct, useDuplicateProduct } from 'hooks/use-products';
import { useToast } from 'components/Toast';
import { useNavigate } from 'react-router-dom';
import DropdownTree from 'components/DropdownTree';
import { categoriesSimpleTree, useProductCategories } from 'hooks/use-product-categories';

const CreateProductButton = () => (
	<Button as={Link} to='/dashboard/catalogo/productos/new'>
		Nuevo Producto
	</Button>
);
const paginationLimit = 50;

const ProductsList = ({ statusList, pageScope }) => {
	const navigate = useNavigate();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { getLazyNotificationCount, user } = useAuth();
	const [products, setProducts] = useState(null);
	const [scope, setScope] = useState(pageScope || 'all');
	const [orderScope, setOrderScope] = useState('updated_desc');
	const [promoted, setPromoted] = useState(false);
	const [highlighted, setHighlighted] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [productSearch, setProductSearch] = useState('');
	const [offset, setOffset] = useState(0);
	const [getLazyProducts, { data, loading }] = useLazyProducts({
		search: productSearch,
		scope,
		order_scope: orderScope,
		promote: promoted,
		highlight: highlighted,
		CategoryId: selectedCategory,
		limit: paginationLimit,
		offset,
	});
	const [updateProducts] = useUpdateProducts({
		onCompleted: ({ ProductsUpdate }) => {
			if (ProductsUpdate.__typename.endsWith('Fault')) {
				addErrorMessage('Producto', ProductsUpdate?.message);
			} else if (ProductsUpdate.__typename === 'Success') {
				setOffset(0);
				getLazyProducts();
				addSuccessMessage('Productos', 'Descatalogados correctamente');
				if (user.type === 'admin') {
					getLazyNotificationCount();
				}
				setInput(prev => ({ ...prev, items: [] }));
			}
		},
	});
	const [updateProduct] = useUpdateProduct({
		onCompleted: ({ ProductUpdate }) => {
			if (ProductUpdate.__typename.endsWith('Fault')) {
				addErrorMessage('Producto', ProductUpdate?.message);
			} else if (ProductUpdate.id) {
				setOffset(0);
				getLazyProducts();
				if (user.type === 'admin') {
					getLazyNotificationCount();
				}
			}
		},
	});
	const [duplicateProduct] = useDuplicateProduct({
		onCompleted: ({ ProductDuplicate }) => {
			if (ProductDuplicate.__typename.endsWith('Fault')) {
				addErrorMessage('Producto', ProductDuplicate?.message);
			} else if (ProductDuplicate.id) {
				addSuccessMessage('Productos', 'Duplicado correctamente');
				navigate(`/dashboard/catalogo/productos/edit/${ProductDuplicate.id}`);
			}
		},
	});

	// Main Categories
	const { data: categoryData } = useProductCategories();

	const [input, setInput] = useState({
		globalCheck: false,
		items: [],
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyProducts();
	}, [getLazyProducts]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && data.Products.__typename.endsWith('Fault')) {
			addErrorMessage('Productos', data?.Products.message);
		} else {
			setProducts(data?.Products);
			setInput({ globalCheck: false, items: data?.Products.List.map(() => false) || [] });
		}
	}, [data, addErrorMessage]);

	const handleSearch = input => {
		setProductSearch(input);
		setOffset(0);
		getLazyProducts();
	};

	const handleClearSearch = () => {
		setProductSearch('');
		setOffset(0);
		getLazyProducts();
	};

	const handlePromotedProduct = e => {
		e.preventDefault();
		setPromoted(prev => !prev);
	};

	const handleHighlightedProduct = e => {
		e.preventDefault();
		setHighlighted(prev => !prev);
	};

	const handleSelectCategoryId = categoryId => setSelectedCategory(categoryId);

	const handleClearCategory = () => {
		setSelectedCategory(null);
	};

	const filterStatusList = () => {
		return statusList?.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setScope(scope);
				setOffset(0);
				getLazyProducts();
			},
		}));
	};

	const orderByCreatedDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyProducts();
			},
		}));
	};

	const orderByUpdatedDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'updated_asc' },
			{ label: 'Descendente', scope: 'updated_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyProducts();
			},
		}));
	};

	const orderByTitle = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'title_asc' },
			{ label: 'Descendente', scope: 'title_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyProducts();
			},
		}));
	};

	const globalMenuList = [
		{
			title: 'Descatalogar productos',
			onClick: () => {
				if (input.items.some(isCheckedItem => isCheckedItem)) {
					updateProducts({
						variables: {
							productsId: products?.List.filter((_, index) => input.items[index]).map(
								product => product.id
							),
							input: { status: 'deleted', promote: false, highlight: false },
						},
					});
				} else {
					addErrorMessage('Productos', 'No hay productos seleccionados');
				}
			},
		},
	];

	const getProductMenuList = ({ id, status, promote, highlight }) => {
		const options = [
			{ title: 'Editar', href: `/dashboard/catalogo/productos/edit/${id}` },
			{
				title: 'Duplicar',
				onClick: () => {
					duplicateProduct({
						variables: {
							productId: id,
						},
					});
				},
			},
			{
				title: 'Descatalogar',
				onClick: () => {
					updateProduct({
						variables: {
							id,
							input: { status: 'deleted', promote: false, highlight: false },
						},
					}).then(({ data }) => {
						if (data.ProductUpdate.id) {
							addSuccessMessage('Producto', 'Descatalogado correctamente');
						}
					});
				},
			},
		];

		// Depends on product status, activeOption or inactiveOption are added as second option (in index 1 of options array)
		const activeOption = () => {
			if (status === 'inactive' || status === 'deleted') {
				return {
					title: 'Activar',
					onClick: () => {
						updateProduct({
							variables: {
								id,
								// When user type 'admin' activates a product, update to 'active'
								// When user type 'merchant' activates a product, update to 'modified'
								input: {
									status: user.type === 'admin' ? 'active' : 'revision',
									promote: false,
									highlight: false,
								},
							},
						}).then(({ data }) => {
							if (data.ProductUpdate.id) {
								addSuccessMessage('Producto', 'Activado correctamente');
							}
						});
					},
				};
			}
			return null;
		};
		if (activeOption()) options.splice(1, 0, activeOption());

		const inactiveOption = () => {
			if (status === 'active' || status === 'revision' || status === 'modified') {
				return {
					title: 'Desactivar',
					onClick: () => {
						updateProduct({
							variables: {
								id,
								input: { status: 'inactive', promote: false, highlight: false },
							},
						}).then(({ data }) => {
							if (data.ProductUpdate.id) {
								addSuccessMessage('Producto', 'Desactivado correctamente');
							}
						});
					},
				};
			}
			return null;
		};
		if (inactiveOption()) options.splice(1, 0, inactiveOption());

		// Only 'admin' can promote or de-promote products with status 'active' or 'modified'
		if (user.type === 'admin' && (status === 'active' || status === 'modified')) {
			options.push({
				title: promote ? 'Despromocionar producto' : 'Promocionar producto',
				onClick: () => {
					// updateProduct({
					// 	variables: {
					// 		id,
					// 		input: { promote: !promote },
					// 	},
					// }).then(({ data }) => {
					// 	if (data.ProductUpdate.id) {
					// 		addSuccessMessage(
					// 			'Producto',
					// 			`${
					// 				data.ProductUpdate.promote
					// 					? 'Promovido correctamente'
					// 					: 'Despromovido correctamente'
					// 			}`
					// 		);
					// 	}
					// });
					addErrorMessage('Producto', 'Promocionar producto no implementado actualmente');
				},
			});
		}

		// Only 'admin' can highlight or de-highlight products with status 'active' or 'modified'
		if (user.type === 'admin' && (status === 'active' || status === 'modified')) {
			options.push({
				title: highlight ? 'No destacar producto' : 'Destacar producto',
				onClick: () => {
					updateProduct({
						variables: {
							id,
							input: { highlight: !highlight },
						},
					}).then(({ data }) => {
						if (data.ProductUpdate.id) {
							addSuccessMessage(
								'Producto',
								`${
									data.ProductUpdate.highlight
										? 'Destacado correctamente'
										: 'Producto no destacado correctamente'
								}`
							);
						}
					});
				},
			});
		}

		// 'merchant' can send email to request promote or de-promote products with status 'active' or 'modified'
		if (user.type === 'merchant' && (status === 'active' || status === 'modified')) {
			options.push({
				title: promote ? 'Despromocionar producto' : 'Promocionar producto',
				// email: `mailto:${process.env.REACT_APP_NOTIFICATIONS_EMAIL}?subject=Solicitud ${
				// 	promote ? 'Despromocionar producto' : 'Promocionar producto'
				// }`,
				onClick: () => {
					addErrorMessage('Producto', 'Promocionar producto no implementado actualmente');
				},
			});
		}
		return options;
	};

	return (
		<Card className='mb-20'>
			<DownloadCSVButton
				queryProps={{
					search: productSearch,
					scope,
					order_scope: orderScope,
					promote: promoted,
					highlight: highlighted,
					CategoryId: selectedCategory,
				}}
			/>
			{!pageScope && <LayoutHeader actions={[CreateProductButton]}>Productos</LayoutHeader>}
			<span className='flex w-full'>
				<SearchBox
					placeholder='Busca un Producto'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				<Pagination
					offset={offset}
					setOffset={setOffset}
					count={products?.count}
					hasMore={products?.hasMore}
					limit={paginationLimit}
				/>
			</span>
			<span className='flex'>
				<DropdownTree
					noneSelectedLabel='Filtrar categoría'
					treeData={categoriesSimpleTree(null, categoryData?.ProductCategories)}
					initialValue=''
					onChange={handleSelectCategoryId}
					width='w-1/3'
					className='h-10 py-0 font-light'
					borderStyle='border-black'
					onClear={handleClearCategory}
				/>
				<div className='flex w-full flex-col self-end lg:flex-row'>
					<Check
						checked={promoted}
						onChange={handlePromotedProduct}
						className='ml-2 w-48 checked:bg-blue-600'
						label='Producto en promoción'
					/>
					<Check
						checked={highlighted}
						onChange={handleHighlightedProduct}
						className='ml-2 w-48 checked:bg-blue-600'
						label='Producto destacado'
					/>
				</div>
			</span>
			<LoadingView loading={loading} whileLoading='Buscando productos...'>
				<ContentTable
					key={22}
					columns={[
						<Check
							checked={input.globalCheck}
							onChange={() => {
								// Toogle all the products
								setInput({
									globalCheck: !input.globalCheck,
									items: products.List.map(() => !input.globalCheck),
								});
							}}
							className='mt-4 checked:bg-blue-600'
						/>,
						columnTitleElement('Creación', orderByCreatedDate),
						columnTitleElement('Modificación', orderByUpdatedDate),
						columnTitleElement('Producto', orderByTitle),
						columnTitleElement('Categoria'),
						columnTitleElement('Precio'),
						columnTitleElement('Estado', statusList && filterStatusList),
						<div className='right pr-6 font-bold'>
							<TooltipMenu menuList={globalMenuList} mt='mt-0' ml='ml-5' />
						</div>,
					]}
					content={products?.List}
					render={(item, index) => (
						<tr key={index} className='border-t border-dashed border-coral-300 text-gray-700'>
							<td>
								<Check
									checked={input.items[index] || input.globalCheck}
									onChange={() => {
										// Toogle item on list
										const newItems = [...input.items];
										newItems[index] = !newItems[index];
										setInput({ ...input, items: newItems });
									}}
									className='checked:bg-blue-600'
								/>
							</td>
							<td className='px-4 py-2'>{formatDate(item.createdAt)}</td>
							<td className='px-4 py-2'>{formatDate(item.updatedAt)}</td>
							<td className='px-4 text-left'>
								<Link to={`/dashboard/catalogo/productos/edit/${item.id}`}>{item.title}</Link>
							</td>
							<td className='px-4 text-left'>{item?.Categories?.map(c => c.name).join(' > ')}</td>
							<td className='px-2 pr-8 text-right'>
								{item?.Variants?.map(c => c.price + '€').join(', ')}
							</td>
							<td className='px-4 py-2'>{translasteStatus(item.status)}</td>
							<td className='px-6'>
								<div className='h-16'>
									<TooltipMenu
										menuList={getProductMenuList({
											id: item.id,
											status: item.status,
											promote: item.promote,
											highlight: item.highlight,
										})}
									/>
								</div>
							</td>
						</tr>
					)}
					empty={
						<p className='my-5 flex h-24 w-full items-center justify-center rounded-md border border-gray-400 text-gray-700 shadow-md'>
							No hay Productos
						</p>
					}
				/>
			</LoadingView>
		</Card>
	);
};

export default ProductsList;
