import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';

const CUSTOMER_LIST_INFO_FRAGMENT = gql`
	fragment CustomerListInfo on User {
		id
		email
		firstName
		lastName
		userName
		type
		acceptsMarketing
		createdAt
		lastLoginAt
	}
`;

const ADDRESS_FRAGMENT = /* GraphQL */ `
	fragment CustomerAddressInfo on UserAddress {
		id
		name
		vat
		phone
		address1
		address2
		zip
		province
		city
		country
		notes
		default
	}
`;

const USER_DETAILS_FRAGMENT = gql`
	fragment CustomerDetailsInfo on User {
		id
		email
		firstName
		lastName
		userName
		type
		phone
		acceptsMarketing
		authorizeProductSubstitution
		createdAt
		lastLoginAt
		BillAddress {
			...CustomerAddressInfo
		}
		ContactAddress {
			...CustomerAddressInfo
		}
	}
	${ADDRESS_FRAGMENT}
`;

const CUSTOMERS_QUERY = gql`
	query Customers($search: String, $scope: String, $order_scope: String, $limit: Int, $offset: Int) {
		Customers(search: $search, scope: $scope, order_scope: $order_scope, limit: $limit, offset: $offset) {
			... on UserList {
				count
				hasMore
				List {
					...CustomerListInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${CUSTOMER_LIST_INFO_FRAGMENT}
`;

const CUSTOMER_QUERY = gql`
	query Customer($customerId: ID) {
		Customer(customerId: $customerId) {
			... on User {
				...CustomerDetailsInfo
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
	${USER_DETAILS_FRAGMENT}
`;

const CUSTOMER_DELETE_MUTATION = gql`
	mutation CustomerDelete($customerId: ID!) {
		CustomerDelete(customerId: $customerId) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

export const useLazyCustomers = variables =>
	useLazyQuery(CUSTOMERS_QUERY, {
		variables,
		fetchPolicy: 'cache-and-network',
	});

export const useCustomer = variables =>
	useQuery(CUSTOMER_QUERY, {
		variables,
		fetchPolicy: 'cache-and-network',
	});

export const useCustomerDelete = props => useMutation(CUSTOMER_DELETE_MUTATION, props);
