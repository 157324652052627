import React, { useEffect, useState } from 'react';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import CustomersList from 'components/CustomersList';
import SearchBox from 'components/SearchBox';
import { useToast } from 'components/Toast';
import { useLazyCustomers } from 'hooks/use-customers';
import useToggle from 'hooks/use-toggle';
const paginationLimit = 50;

const Customers = () => {
	const { addErrorMessage } = useToast();
	const [openModal, setOpenModal] = useToggle();
	const [search, setSearch] = useState('');
	const [customers, setCustomers] = useState(null);
	const [offset, setOffset] = useState(0);
	const [orderScope, setOrderScope] = useState('created_asc');
	const [getLazyUsers, { data, loading }] = useLazyCustomers({
		search,
		scope: 'all_customers',
		order_scope: orderScope,
		limit: paginationLimit,
		offset,
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyUsers();
	}, [getLazyUsers]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data && data.Customers.__typename.endsWith('Fault')) {
			addErrorMessage('Usuarios', data?.Customers.message);
		} else {
			setCustomers(data?.Customers);
		}
	}, [data, addErrorMessage]);

	const orderByDate = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'created_asc' },
			{ label: 'Descendente', scope: 'created_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};
	const orderByFirstName = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'firstName_asc' },
			{ label: 'Descendente', scope: 'firstName_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};

	const orderByEmail = () => {
		const labelsAndScopes = [
			{ label: 'Ascendente', scope: 'email_asc' },
			{ label: 'Descendente', scope: 'email_desc' },
		];

		return labelsAndScopes.map(({ label, scope }) => ({
			title: label,
			onClick: () => {
				setOrderScope(scope);
				setOffset(0);
				getLazyUsers();
			},
		}));
	};
	const handleSearch = input => {
		setSearch(input);
		getLazyUsers();
	};

	const handleClearSearch = () => {
		setSearch('');
		getLazyUsers();
	};

	const getCustomerMenuList = customerId => {
		const options = [
			{
				title: 'Detalles',
				href: `/dashboard/clientes/${customerId}`,
			},
			{
				title: 'Eliminar',
				onClick: () => {
					setOpenModal(true);
				},
			},
		];

		return options;
	};

	return (
		<AuthorizationBoundary for={['admin']}>
			<Card>
				<LayoutHeader>Listado de clientes</LayoutHeader>
				<SearchBox
					placeholder='Busca usuario'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				<CustomersList
					data={customers?.List}
					orderByDate={orderByDate}
					orderByEmail={orderByEmail}
					orderByFirstName={orderByFirstName}
					loading={loading}
					getCustomerMenuList={getCustomerMenuList}
					openModal={openModal}
					setOpenModal={setOpenModal}
					customers={customers}
					setCustomers={setCustomers}
				/>
			</Card>
		</AuthorizationBoundary>
	);
};

export default Customers;
