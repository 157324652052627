import React from 'react';

const Message = ({ error, success, className, children }) =>
	children && (
		<p
			className={`${error && 'text-red-500 border border-red-500'} ${
				success && 'text-green-700 border border-green-700'
			} bg-gray-200 mt-4 mb-4 p-2 ${className}`}
		>
			{children}
		</p>
	);

export default Message;
