import Button from 'components/Button';
import DropdownTreeForm from 'components/DropdownTreeForm';
import DropImageArea from 'components/DropfileArea';
import InputForm from 'components/InputForm';
import LayoutTitle from 'components/LayoutTitle';
import Score from 'components/Score';
import TextareaForm from 'components/TextareaForm';
import { categoriesSimpleTree, useProductCategories } from 'hooks/use-product-categories';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from 'hooks/use-auth';
import slugify from 'lib/slugify';

const CategoryForm = ({ productCategory, isSubmitting = false, onSubmit, setDirty = () => {} }) => {
	const { locale } = useAuth();
	const disableNonTranslatableFields = locale !== process.env.REACT_APP_DEFAULT_LOCALE;

	const { data } = useProductCategories({ fetchPolicy: 'network-only' }, locale);
	const [score, setScore] = useState(0);

	const [Image, setImage] = useState();
	const [imagesUploading, setImagesUploading] = useState(false);

	const {
		handleSubmit,
		getValues,
		watch,
		control,
		register,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			categoryForm: {
				name: '',
				slug: '',
				content: {
					description: '',
					subtitle1: '',
					description1: '',
					subtitle2: '',
					description2: '',
					subtitle3: '',
					description3: '',
				},
				ImageId: null,
				ParentId: null,
			},
		},
	});
	// Using calculateFormScore in combination with watch "score" state
	const calculateFormScore = formData => {
		if (formData) {
			let total = Object.entries(formData).length;
			let count = 0;

			for (var [, value] of Object.entries(formData)) {
				if (value) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};

	watch(({ categoryForm }, { name }) => {
		calculateFormScore(categoryForm);
		if (name === 'categoryForm.name') {
			setValue('categoryForm.slug', slugify(categoryForm.name));
		}
	});

	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	useEffect(() => {
		if (productCategory) {
			setValue('categoryForm', {
				name: productCategory.name || '',
				slug: productCategory.slug || '',
				content: JSON.parse(productCategory.content) || {
					description: '',
					subtitle1: '',
					description1: '',
					subtitle2: '',
					description2: '',
					subtitle3: '',
					description3: '',
				},
				ImageId: productCategory.Image?.id || null,
				ParentId: productCategory.ParentId || null,
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImage({ ...productCategory.Image });
			setDirty(false);
		}
	}, [productCategory, setValue, setDirty]);

	const handleOnchangeParentId = pId => {
		setValue('categoryForm.ParentId', pId);
	};

	const handleOnChangeImage = useCallback(
		newImage => {
			setImage(newImage[0]);
			setValue('categoryForm.ImageId', newImage[0].id);
			setImagesUploading(false);
		},
		[setImagesUploading, setValue]
	);

	return (
		<form
			onSubmit={handleSubmit(data => {
				onSubmit({
					...data.categoryForm,
					content: JSON.stringify(data.categoryForm.content),
					slug: slugify(data.categoryForm.slug),
				});
			})}
			disabled={isSubmitting}
		>
			<Score data={score} name='de categoría' content='' />
			<div className='flex items-center w-full max-w-screen-md mb-4'>
				<span
					className={`${
						disableNonTranslatableFields ? 'text-gray-500' : 'text-gray-700'
					} font-semibold text-lg w-1/3`}
				>
					Categoría Superior
				</span>
				<DropdownTreeForm
					name='categoryForm.ParentId'
					control={control}
					initialValue={getValues('categoryForm.ParentId')}
					disabled={disableNonTranslatableFields}
					noneSelectedLabel='Sin categoría padre'
					treeData={categoriesSimpleTree(null, data?.ProductCategories)}
					onChange={handleOnchangeParentId}
					selectCategoryParent={true}
					productCategory={productCategory}
				/>
			</div>

			<LayoutTitle>Descripción categoría. Texto visible en la web</LayoutTitle>
			<InputForm
				name='categoryForm.name'
				control={control}
				register={register}
				label='Nombre de la Categoría'
				containerclassname='my-4'
				className='border border-coral-300 py-2 px-4 text-lg'
				placeholder='Añadir nombre'
				required
			/>
			<InputForm
				name='categoryForm.slug'
				control={control}
				register={register}
				containerclassname='my-4'
				className='border border-coral-300 py-2 px-4 text-lg'
				label='Slug'
				placeholder='Añadir slug'
				required
			/>
			<TextareaForm
				name='categoryForm.content.description'
				control={control}
				containerclassname='my-4 w-128'
				className='border mt-1 p-4 w-128'
				label='Descripción breve'
			/>
			<DropImageArea
				name='categoryForm.Image'
				label='Imagen'
				whileIdleText='Pincha o Suelta tus Imágenes aquí'
				Images={[Image]}
				onChange={handleOnChangeImage}
				onDelete={() => setImage(null)}
				setImagesUploading={setImagesUploading}
			/>
			<InputForm
				name='categoryForm.content.subtitle1'
				control={control}
				register={register}
				label='Subtítulo 1'
				containerclassname='my-4'
				className='border border-coral-300 py-2 px-4 text-lg'
				placeholder='Añadir subtitulo'
			/>
			<TextareaForm
				name='categoryForm.content.description1'
				control={control}
				containerclassname='my-4 w-128'
				className='border mt-1 p-4 w-128'
				label='Descripción 1'
			/>
			<InputForm
				name='categoryForm.content.subtitle2'
				control={control}
				register={register}
				label='Subtítulo 2'
				containerclassname='my-4'
				className='border border-coral-300 py-2 px-4 text-lg'
				placeholder='Añadir subtitulo'
			/>
			<TextareaForm
				name='categoryForm.content.description2'
				control={control}
				containerclassname='my-4 w-128'
				className='border mt-1 p-4 w-128'
				label='Descripción 2'
			/>
			<InputForm
				name='categoryForm.content.subtitle3'
				control={control}
				register={register}
				label='Subtítulo 3'
				containerclassname='my-4'
				className='border border-coral-300 py-2 px-4 text-lg'
				placeholder='Añadir subtitulo'
			/>
			<TextareaForm
				name='categoryForm.content.description3'
				control={control}
				containerclassname='my-4 w-128'
				className='border mt-1 p-4 w-128'
				label='Descripción 3'
			/>
			<Button className='mr-auto mt-4 w-32' disabled={isSubmitting || imagesUploading} type='submit'>
				{isSubmitting ? 'Guardando' : 'Guardar'}
			</Button>
		</form>
	);
};

export default CategoryForm;
