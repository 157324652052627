/* eslint-disable react/display-name */
import React from 'react';

export const Row = ({ children }) => (
	<div className='row flex justify-end border-b border-gray-300 mr-4'>{children}</div>
);
export const Col = ({ children }) => <div className='col flex flex-col'>{children}</div>;

export const EditorButton = ({ className, active, ...props }) => (
	<span
		{...props}
		className={`w-8 h-8  rounded-md cursor-pointer flex items-center justify-center overflow-hidden m-2 mr-0 ${className} ${
			active ? 'border-2 border-gray-600' : 'text-gray-400'
		}`}
	/>
);

export const EditorValue = ({ className = '', value, ...props }) => {
	const textLines = value.document.nodes
		.map(node => node.text)
		.toArray()
		.join('\n');

	return (
		<div {...props} className={className} style={{ margin: '30px -20px 0' }}>
			{textLines}
		</div>
	);
};

export const Icon = ({ className = 'h-4 w-4', ...props }) => (
	<img {...props} alt={props.icon} src={`/images/editor-icons/editor-${props.icon}.svg`} className={className} />
);

export const Toolbar = ({ ...props }) => <Row className='editorToolbar mx-0' {...props} />;
