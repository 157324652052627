import React from 'react';

const Table = props => <table {...props} />;

const ContentTable = ({ columns = [], content = [], render = () => {}, empty = null }) =>
	content?.length > 0 ? (
		<Table className='w-full table-auto'>
			<thead className='text-coral-400'>
				<tr className='px-4 py-2'>
					{columns.map((c, i) =>
						typeof c === 'string' ? (
							<th scope='col' key={i} className='px-4 py-2 text-left'>
								{c}
							</th>
						) : (
							<th key={i}>{c}</th>
						)
					)}
				</tr>
			</thead>
			<tbody>{content.map((c, index) => render(c, index))}</tbody>
		</Table>
	) : (
		<>
			<Table className='w-full table-auto'>
				<thead className='text-coral-400'>
					<tr className='px-4 py-2'>
						{columns.map((c, i) =>
							typeof c === 'string' ? (
								<th scope='col' key={c} className='px-4 py-2 text-left'>
									{c}
								</th>
							) : (
								<th key={i}>{c}</th>
							)
						)}
					</tr>
				</thead>
			</Table>
			{empty}
		</>
	);

export default ContentTable;
