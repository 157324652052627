/**
 * Display name of user.
 *
 * @param {Object} user User object.
 * @returns {String} display name available in the account (first name, last name, userName or email).
 */
const printUserName = user => {
	if (user?.firstName) return `${user?.firstName} ${user?.lastName ?? ''}`;
	if (user?.userName) return user?.userName;
	return user?.email;
};

export default printUserName;
