import React from 'react';
import Input from 'components/Input';
import LayoutTitle from 'components/LayoutTitle';
import formatDate from 'lib/formatDate';

const CustomerAccountDetails = ({ customer, title }) => {
	return (
		<div className='mt-16'>
			<LayoutTitle>{title}</LayoutTitle>
			<Input value={customer?.firstName} label='Nombre' readOnly />
			<Input value={customer?.lastName} label='Apellidos' readOnly />
			<Input value={customer?.userName} label='Nombre de usuario' readOnly />
			<Input value={customer?.email} label='Email' readOnly />
			<Input value={customer?.phone} label='Teléfono' readOnly />
			<div className='flex py-5 w-full max-w-screen-md mb-4'>
				<p className='w-1/3 text-lg text-gray-700 font-semibold'>Preferencias</p>
				<span className='flex flex-col'>
					<p>
						El cliente <strong>{customer?.acceptsMarketing ? '' : 'no '}acepta</strong> la sustitución de
						productos por otro de igual o superior calidad.
					</p>
					<p>
						El cliente <strong>{customer?.authorizeProductSubstitution ? '' : 'no '}autoriza</strong> que se
						le envie información comercial
					</p>
				</span>
			</div>
			<Input value={formatDate(customer?.createdAt)} label='Fecha de alta como cliente' readOnly />
		</div>
	);
};

export default CustomerAccountDetails;
