import React from 'react';

import Field from 'components/Field';

const Textarea = ({ id, children, maxLength = '500', width = 'w-2/3', ...props }) => (
	<Field align='items-start' {...props}>
		<textarea
			id={id}
			maxLength={maxLength}
			{...props}
			className={`border mt-1 p-4 text-lg rounded-md border-coral-300 outline-none focus:outline-none h-32 ${width} ${
				props.className || ''
			}`}
		>
			{children}
		</textarea>
	</Field>
);

export default Textarea;
