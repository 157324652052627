import { useMerchants } from 'hooks/use-merchants';
import React from 'react';

import SelectForm from './SelectForm';
import Select from './Select';

const SelectMerchantInput = ({ emptyValue = { id: '', commercialName: 'Selecciona asociado' }, ...props }) => {
	const { data } = useMerchants({ limit: 0 });

	return props.control ? (
		<SelectForm {...props} options={data ? [emptyValue, ...data.Merchants.List] : [emptyValue]} />
	) : (
		<Select {...props} options={data ? [emptyValue, ...data.Merchants.List] : [emptyValue]} />
	);
};

export default SelectMerchantInput;
