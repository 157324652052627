import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

export const MERCHANT_FRAGMENT = /* GraphQL */ `
	fragment MerchantInfo on Merchant {
		id
		acceptConditions
		administrativeAddress1
		administrativeAddress2
		administrativeBusinessName
		administrativeCity
		administrativeCountry
		administrativeDescription
		administrativeEmail
		administrativeName
		administrativePhone
		administrativeProvince
		administrativeUrl
		administrativeVat
		administrativeZip
		commercialAddress1
		commercialAddress2
		commercialCity
		commercialContent
		commercialCountry
		CommercialLogo {
			id
			src
			title
			alt
		}
		commercialMaps
		commercialName
		commercialPhone
		commercialProvince
		commercialRequiredDescription
		commercialSchedule
		commercialVideo
		commercialZip
		createdAt
		Images {
			id
			src
			title
			alt
		}
		FeaturedImage {
			id
			src
			title
			alt
		}
		meetRequirements
		ProductCategoryId
		ProductCount
		slug
		stripeAccountId
		User {
			id
			userName
			firstName
			lastName
			email
			phone
			note
			currency
			verifiedEmail
			acceptsMarketing
			taxExempt
			UserImageId
			type
			Image {
				id
				src
				title
				alt
			}
			status
		}
		UserId
	}
`;

export const MERCHANTS_FRAGMENT = /* GraphQL */ `
	fragment MerchantsInfo on Merchant {
		id
		commercialName
		createdAt
		User {
			id
			status
		}
	}
`;

const MERCHANT_QUERY = gql`
	query Merchant($id: ID!, $locale: String!) {
		Merchant(id: $id, locale: $locale) {
			... on Merchant {
				...MerchantInfo
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const MERCHANTS_QUERY = gql`
	query Merchants($search: String, $scope: String, $order_scope: String, $limit: Int, $offset: Int, $locale: String) {
		Merchants(
			search: $search
			scope: $scope
			order_scope: $order_scope
			limit: $limit
			offset: $offset
			locale: $locale
		) {
			... on MerchantList {
				count
				hasMore
				List {
					...MerchantsInfo
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
	${MERCHANTS_FRAGMENT}
`;

const MERCHANTCREATE_MUTATION = gql`
	mutation MerchantCreate($input: MerchantCreateInput!) {
		MerchantCreate(input: $input) {
			... on Merchant {
				...MerchantInfo
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on MailFault {
				message
				emailRecipient
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const MERCHANTUPDATE_MUTATION = gql`
	mutation MerchantUpdate($id: ID!, $input: MerchantUpdateInput!, $locale: String!, $stripe: Boolean) {
		MerchantUpdate(id: $id, input: $input, locale: $locale, stripe: $stripe) {
			... on MerchantUpdate {
				Merchant {
					...MerchantInfo
				}
				Url
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on MailFault {
				message
				emailRecipient
			}
			... on AuthorizationFault {
				message
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const MERCHANTDELETE_MUTATION = gql`
	mutation MerchantDelete($id: ID!) {
		MerchantDelete(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
	${MERCHANT_FRAGMENT}
`;

const STRIPESTATUS_MUTATION = gql`
	mutation StripeStatus($MerchantId: ID) {
		StripeStatus(MerchantId: $MerchantId) {
			... on StripeStatus {
				message
				isActive
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
		}
	}
`;

export const useCreateMerchant = props =>
	useMutation(MERCHANTCREATE_MUTATION, {
		...props,
		// refetchQueries: [
		// 	{ query: MERCHANTS_QUERY, variables: { status: 'all' } },
		// 	{ query: MERCHANTS_QUERY, variables: { status: 'active' } },
		// 	{ query: MERCHANTS_QUERY, variables: { status: 'inactive' } },
		// 	{ query: MERCHANTS_QUERY, variables: { status: 'pending' } },
		// ],
	});
export const useUpdateMerchant = props => useMutation(MERCHANTUPDATE_MUTATION, { ...props, fetchPolicy: 'no-cache' });
export const useDeleteMerchant = props => useMutation(MERCHANTDELETE_MUTATION, props);
export const useMerchant = (id, locale) =>
	useQuery(MERCHANT_QUERY, { variables: { id, locale }, fetchPolicy: 'network-only' });
export const useLazyMerchant = variables => useLazyQuery(MERCHANT_QUERY, { variables, fetchPolicy: 'network-only' });
export const useMerchants = variables => useQuery(MERCHANTS_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useLazyMerchants = variables =>
	useLazyQuery(MERCHANTS_QUERY, { variables, fetchPolicy: 'cache-and-network' });
export const useStripeStatus = props => useMutation(STRIPESTATUS_MUTATION, { ...props, fetchPolicy: 'no-cache' });
