import React, { useEffect, useState } from 'react';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Card from 'components/Card';
import ConfigurationFolder from './ConfigurationFolder';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import SearchBox from 'components/SearchBox';
import { useToast } from 'components/Toast';
import { useLazyConfigurations } from 'hooks/use-configurations';
import useAuth from 'hooks/use-auth';

const Configuration = () => {
	// Start with a default language
	const [dirty, setDirty] = useState(false);
	const { addErrorMessage } = useToast();
	const { locale } = useAuth();
	// Create an state for every configuration folder
	const [folders, setFolders] = useState([]);
	// Create an state for every configuration group
	// const [background, setBackground] = useState(null);
	// const [heroConfigurations, setHeroConfigurations] = useState([]);
	const [configurationSearch, setConfigurationSearch] = useState('');
	const [getLazyConfigurations, { data, loading }] = useLazyConfigurations({
		variables: {
			searchByDescription: configurationSearch,
			locale,
		},
	});

	// Excecute once at page load
	useEffect(() => {
		getLazyConfigurations();
	}, [getLazyConfigurations]);

	// Excecute after first load and on search
	useEffect(() => {
		if (data?.Configurations) {
			if (data.Configurations.__typename?.endsWith('Fault')) {
				addErrorMessage('Configuración', data?.Configurations.message);
			} else {
				const confs = data.Configurations;
				// Filter only valueType === 'folder'
				let folders = confs.filter(conf => conf.valueType === 'folder');
				// Create a copy of folders that can be modified
				folders = folders.map(conf => JSON.parse(JSON.stringify(conf)));
				// Add a children for each object in folders containing related configs objects
				for (const folder of folders) {
					folder.children = confs.filter(conf => conf.key.startsWith(folder.key));
				}
				setFolders(folders);
			}
		}
	}, [data, addErrorMessage]);

	const handleSearch = input => {
		setConfigurationSearch(input);
		getLazyConfigurations();
	};

	const handleClearSearch = () => {
		setConfigurationSearch('');
		getLazyConfigurations();
	};

	return (
		<AuthorizationBoundary for={['admin']}>
			<LanguageTabs dirty={dirty} />
			<Card>
				<LayoutHeader>Configuración</LayoutHeader>
				<SearchBox
					placeholder='Busca palabra clave'
					search={handleSearch}
					loading={loading}
					clearSearch={handleClearSearch}
				/>
				{folders.map(
					folder =>
						folder.children?.length > 0 && (
							<ConfigurationFolder key={folder.key} folder={folder} setDirty={setDirty} locale={locale} />
						)
				)}
			</Card>
		</AuthorizationBoundary>
	);
};

export default Configuration;
