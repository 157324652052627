import React, { useEffect } from 'react';
import Button from 'components/Button';
import InputForm from 'components/InputForm';
import { useToast } from 'components/Toast';
import { useForm } from 'react-hook-form';
import { useUpdateConfiguration } from 'hooks/use-configurations';

const ConfigurationTextInput = ({ setDirty = () => {}, textData, locale }) => {
	const [updateConfiguration, { loading }] = useUpdateConfiguration();
	const { addSuccessMessage, addErrorMessage } = useToast();

	const {
		handleSubmit,
		control,
		register,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			configurationForm: {
				description: '',
				key: '',
				valueType: '',
				valuePlainText: null,
				valueTranslatableText: null,
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (textData) {
			switch (textData.valueType) {
				case 'plainText':
					setValue('configurationForm', {
						description: textData.description || '',
						key: textData.key || '',
						valueType: textData.valueType || '',
						valuePlainText: textData.valuePlainText || null,
					});
					break;
				case 'translatableText':
					setValue('configurationForm', {
						description: textData.description || '',
						key: textData.key || '',
						valueType: textData.valueType || '',
						valueTranslatableText: textData.valueTranslatableText || null,
					});
					break;
				default:
					break;
			}
			setDirty(false);
		}
	}, [textData, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	return (
		<form
			onSubmit={handleSubmit(({ configurationForm }) => {
				updateConfiguration({
					variables: {
						configurationId: textData.id,
						input: configurationForm,
						locale,
					},
				}).then(({ data: { ConfigurationUpdate } }) => {
					if (ConfigurationUpdate && ConfigurationUpdate.__typename === 'Configuration') {
						addSuccessMessage('Configuración', 'actualizado correctamente');
					}
					// All Errors implement Fault
					else if (ConfigurationUpdate && ConfigurationUpdate.__typename.endsWith('Fault')) {
						addErrorMessage('Configuración', ConfigurationUpdate.message);
					}
				});
			})}
		>
			{textData.valueType === 'translatableText' && (
				<InputForm
					name='configurationForm.valueTranslatableText'
					control={control}
					register={register}
					label={textData.description}
					placeholder={textData.description}
				/>
			)}
			{textData.valueType === 'plainText' && (
				<InputForm
					name='configurationForm.valuePlainText'
					control={control}
					register={register}
					disabled={locale !== process.env.REACT_APP_DEFAULT_LOCALE}
					label={textData.description}
					placeholder={textData.description}
				/>
			)}
			<Button className='mt-5' type='submit' disabled={loading}>
				{`Guardar texto${textData.valueType === 'translatableText' ? ' traducible' : ''}`}
			</Button>
		</form>
	);
};

export default ConfigurationTextInput;
