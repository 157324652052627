import React from 'react';

const Loading = ({ text = 'Cargando Módulo', isLoading = true, className }) => (
	<div className='flex justify-center items-center p-5'>
		<p className={`self-center p-5 m-auto text-center flex-1 ${className}`}>
			<i className={`fa fa-2x fa-fw mb-2 mr-2 ${isLoading ? 'fa-cog fa-spin' : 'fa-refresh'}`} />
			{isLoading ? text : 'Puede que tengas que recargar la página'}
		</p>
	</div>
);

export default Loading;
