import { useQuery, useMutation, gql } from '@apollo/client';

export const FAQINFO_FRAGMENT = /* GraphQL */ `
	fragment faqInfo on FAQ {
		id
		question
		answer
	}
`;

export const FAQS_QUERY = gql`
	query FAQS {
		FAQS {
			...faqInfo
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQ_QUERY = gql`
	query FAQ($id: ID!) {
		FAQ(id: $id) {
			...faqInfo
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQCREATE_MUTATION = gql`
	mutation CreateFAQ($input: FAQInput!) {
		CreateFAQ(input: $input) {
			...faqInfo
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQUPDATE_MUTATION = gql`
	mutation UpdateFAQ($id: ID!, $input: FAQInput!) {
		UpdateFAQ(id: $id, input: $input) {
			...faqInfo
		}
	}
	${FAQINFO_FRAGMENT}
`;

export const FAQDELETE_MUTATION = gql`
	mutation DeleteFAQ($id: ID!) {
		DeleteFAQ(id: $id)
	}
`;

export const useFaqs = () => {
	const { data, loading, ...rest } = useQuery(FAQS_QUERY);
	return { faqs: data?.FAQS, loading, ...rest };
};

export const useFaq = id => {
	const { data, loading } = useQuery(FAQ_QUERY, { variables: { id } });
	return { faq: data?.FAQ, loading };
};

export const useCreateFaq = input => useMutation(FAQCREATE_MUTATION, { variables: { input } });
export const useUpdateFaq = props => useMutation(FAQUPDATE_MUTATION, props);
export const useDeleteFaq = id =>
	useMutation(FAQDELETE_MUTATION, { variables: { id }, refetchQueries: [{ query: FAQS_QUERY }] });

export default useFaqs;
