import React, { useState, useEffect } from 'react';

const ScrollTopArrow = () => {
	const [showScroll, setShowScroll] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', checkScrollTop);
		return function cleanup() {
			window.removeEventListener('scroll', checkScrollTop);
		};
	});

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 200) {
			setShowScroll(true);
		} else if (showScroll && window.pageYOffset <= 200) {
			setShowScroll(false);
		}
	};

	return (
		<div className='fixed flex bottom-0 right-0 mr-10 mb-10 z-40'>
			<img
				onClick={scrollTop}
				className='cursor-pointer h-24'
				alt='dots-horizontal'
				src='/images/arrow-circle-up-solid.svg'
				style={{ height: 40, display: showScroll ? 'flex' : 'none' }}
			/>
		</div>
	);
};

const scrollTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
};

export default ScrollTopArrow;
