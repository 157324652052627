import React, { useState } from 'react';

import useAuth from 'hooks/use-auth';

import AccountForm from 'pages/Merchants/AccountForm';
import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';

const MyAccountForm = () => {
	const { user } = useAuth();
	const [dirty, setDirty] = useState(false);

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				<AccountForm
					adminLayout={false}
					className='shadow-none'
					ownUser={true}
					userData={user}
					setDirty={setDirty}
				/>
			</Card>
		</>
	);
};

export default MyAccountForm;
