import React, { useState } from 'react';
import { DeleteButton } from 'components/Button';

const SearchBox = ({ className, search, loading, clearSearch, placeholder }) => {
	const [searchInput, setSearchInput] = useState('');
	const handleSearch = e => setSearchInput(e.target.value);
	const handleSubmit = e => {
		e.preventDefault();
		search(searchInput);
	};

	const handleClearSearch = () => {
		setSearchInput('');
		clearSearch();
	};

	return (
		<div className='flex w-full'>
			<form
				onSubmit={handleSubmit}
				className={`flex flex-1 items-center bg-white border-black border w-full md:w-1/2 text-sm rounded-md h-10 overflow-hidden ${className}`}
			>
				<input
					className='p-2 font-light text-black outline-none focus:border-2 focus:border-coral-500 w-full placeholder-gray-400'
					placeholder={placeholder}
					type='text'
					value={searchInput}
					onChange={handleSearch}
				/>
				<button type='submit' className='flex items-center justify-center h-full w-10 bg-coral-500'>
					<img src='/images/search-white.svg' alt='Ícono de búsqueda' width='20' height='20' />
				</button>
			</form>
			<DeleteButton disabled={loading} onClick={handleClearSearch} className='ml-2 h-10' />
		</div>
	);
};

export default SearchBox;
