import Button from 'components/Button';
import Card from 'components/Card';
import InputForm from 'components/InputForm';
import LayoutHeader from 'components/LayoutHeader';
import { useCreateMerchant } from 'hooks/use-merchants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useToast } from 'components/Toast';

const MerchantNew = () => {
	let location = useLocation();
	const [create, { loading }] = useCreateMerchant();

	const [createMerchantResponse, setCreateMerchantResponse] = useState(null);
	const { addSuccessMessage, addErrorMessage } = useToast();

	const { handleSubmit, reset, control, register } = useForm({
		defaultValues: {
			merchantForm: {
				commercialName: '',
				firstName: '',
				email: '',
				lastName: '',
				password: '',
				entranceFee: 0,
			},
		},
	});

	useEffect(() => {
		reset();
	}, [location, reset]);

	useEffect(() => {
		if (createMerchantResponse && createMerchantResponse.__typename === 'DatabaseFault') {
			addErrorMessage('Crear asociado', createMerchantResponse.message);
		} else if (createMerchantResponse && createMerchantResponse.__typename === 'MailFault') {
			addErrorMessage('Crear asociado', createMerchantResponse.message);
		} // All Errors implement Fault
		else if (createMerchantResponse && createMerchantResponse.__typename.endsWith('Fault')) {
			addErrorMessage('Crear asociado', createMerchantResponse.message);
		} else if (createMerchantResponse && createMerchantResponse.__typename === 'Merchant') {
			reset();
			addSuccessMessage('Crear asociado', 'creado correctamente');
		}
	}, [createMerchantResponse, addSuccessMessage, addErrorMessage, reset]);

	return (
		<>
			<Card>
				<LayoutHeader>Crear Asociado</LayoutHeader>
				<p className='mb-4'>
					Con los siguientes datos se creará un Asociado y se enviará un Email con su usuario y contraseña
					notificando su Alta en el sistema.
				</p>
				<p className='mb-4'>Todos los campos son requeridos. (El email estará asociado al usuario)</p>
				<form
					onSubmit={handleSubmit(data => {
						create({
							variables: {
								input: data.merchantForm,
							},
						}).then(res => {
							setCreateMerchantResponse(res.data.MerchantCreate);
						});
					})}
				>
					{process.env.REACT_APP_MARKETPLACE_TYPE === 'own' && (
						<InputForm
							name='merchantForm.entranceFee'
							control={control}
							register={register}
							id='entranceFee'
							label='Cuota a pagar'
							placeholder='Cuota en €'
							htmlFor='contactName'
							required
						/>
					)}
					<InputForm
						name='merchantForm.firstName'
						control={control}
						register={register}
						id='firstName'
						label='Nombre Contacto'
						placeholder='Nombre de Persona Contacto'
						htmlFor='contactName'
						required
					/>
					<InputForm
						name='merchantForm.lastName'
						control={control}
						register={register}
						id='lastName'
						label='Apellido Contacto'
						placeholder='Nombre de Persona Contacto'
						htmlFor='contactName'
						required
					/>
					<InputForm
						name='merchantForm.commercialName'
						control={control}
						register={register}
						id='commercialName'
						label='Nombre Comercial'
						placeholder='Nombre Comercial'
						htmlFor='commercialName'
						required
					/>
					<InputForm
						name='merchantForm.email'
						control={control}
						register={register}
						id='email'
						type='email'
						label='Email'
						placeholder='Email'
						htmlFor='email'
						required
					/>
					<InputForm
						name='merchantForm.password'
						control={control}
						register={register}
						id='password'
						label='Contraseña Temporal'
						placeholder='Contraseña Temporal'
						type='password'
						htmlFor='password'
						required
					/>
					<Button disabled={loading}>Crear</Button>
				</form>
			</Card>
		</>
	);
};

export default MerchantNew;
