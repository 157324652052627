import Card from 'components/Card';
import LayoutTitle from 'components/LayoutTitle';
import ConfigurationImageInput from './ConfigurationImageInput';
import ConfigurationTextInput from './ConfigurationTextInput';
import React from 'react';

const ConfigurationFolder = ({ folder, setDirty = () => {}, locale }) => {
	const selectInputType = configuration => {
		switch (configuration.valueType) {
			case 'image':
				return <ConfigurationImageInput setDirty={setDirty} imageData={configuration} locale={locale} />;
			case 'plainText':
			case 'translatableText':
				return <ConfigurationTextInput setDirty={setDirty} textData={configuration} locale={locale} />;
			default:
				return null;
		}
	};
	return (
		<Card className='mt-10'>
			<LayoutTitle>{folder.description}</LayoutTitle>
			<p>{folder.valuePlainText}</p>
			{folder.children.map(configuration => (
				<div key={configuration.id} className='mt-5'>
					{selectInputType(configuration)}
				</div>
			))}
		</Card>
	);
};

export default ConfigurationFolder;
