import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AuthorizationBoundary from 'components/AuthorizationBoundary';
import Button from 'components/Button';
import DropdownTreeForm from 'components/DropdownTreeForm';
import DropImageArea from 'components/DropfileArea';
import Editor from 'components/Editor';
import Field from 'components/Field';
import Input from 'components/Input';
import InputForm from 'components/InputForm';
import Score from 'components/Score';
import SelectForm from 'components/SelectForm';
import SelectMerchantInput from 'components/SelectMerchantInput';
import TextareaForm from 'components/TextareaForm';
// import Toggle from 'components/Toggle';
import { useToast } from 'components/Toast';
import useAuth from 'hooks/use-auth';
import { categoriesSimpleTree, useProductCategories } from 'hooks/use-product-categories';
import isEmailInText from 'lib/isEmailInText';
import translasteStatus from 'lib/translasteStatus';
import formatDate from 'lib/formatDate';
import slugify from 'lib/slugify';
import LayoutHeader from 'components/LayoutHeader';

const ProductForm = ({ newProduct = false, product, onSubmit, setDirty = () => {}, statusList }) => {
	const { user, locale } = useAuth();
	const disableNonTranslatableFields = locale !== process.env.REACT_APP_DEFAULT_LOCALE;
	const [variantsFields] = useState([
		{
			title: '',
			price: null,
			discountedPrice: null,
			sku: '',
			barcode: '',
		},
	]);

	const [otherFeatureFields] = useState([
		{
			title: '',
			value: '',
		},
	]);

	const { addErrorMessage } = useToast();
	const {
		unregister,
		register,
		handleSubmit,
		getValues,
		control,
		watch,
		setValue,
		formState: { isDirty },
		setFocus,
	} = useForm({
		defaultValues: {
			productForm: {
				MerchantId: '',
				CategoryId: '',
				title: '',
				handle: '',
				sendByMerchant: false,
				subtitle: '',
				description: '[{ "type": "paragraph", "children": [{ "text": "" }] }]',
				instructions: '[{ "type": "paragraph", "children": [{ "text": "" }] }]',
				vat: 21,
				video: '',
				unit: 'unidad',
				seoTitle: '',
				seoDescription: '',
				type: 'shippable',
				brand: null,
				contactInstructions: '',
				status: 'revision',
				DefaultImage: null,
				Images: [],
				otherFeatures: otherFeatureFields,
				Variants: variantsFields,
			},
		},
	});

	// Form States
	const [isFormFilled, setIsFormFilled] = useState(newProduct);
	const [disabled, setDisabled] = useState(false);
	const [score, setScore] = useState(0);
	const [productType, setProductType] = useState(product?.type || 'shippable');
	// Images Upload States
	const [defaultImage, setDefaultImage] = useState();
	const [Images, setImages] = useState([]);
	const [imagesUploading, setImagesUploading] = useState(false);

	// Main Categories
	const { data, loading: catsLoading } = useProductCategories();

	const handleDropdownTreeForm = pId => {
		setValue('productForm.CategoryId', pId);
	};

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	// Is used to delete a single image id from productForm.Images
	useEffect(() => {
		unregister('productForm.Images');
		setValue(
			'productForm.Images',
			Images.map(image => image.id)
		);
	}, [Images, setValue, unregister]);

	// Load Edit data
	useEffect(() => {
		if (product) {
			const otherProductFeatures = product.otherFeatures
				? toArrayOfObjects(product.otherFeatures)
				: otherFeatureFields;

			const productVariants =
				product.Variants.length > 0
					? product.Variants.map(item => {
							return {
								id: item.id,
								title: item.title || '',
								price: item.price ?? '',
								discountedPrice: item.discountedPrice ?? '',
								sku: item.sku,
								barcode: item.barcode,
								isAvailable: item.isAvailable,
								outOfSeasson: item.outOfSeasson ?? 0,
								limitedProduction: item.limitedProduction ?? 0,
							};
					  })
					: variantsFields;
			setValue('productForm', {
				// requried fields
				MerchantId: product.Merchant?.id,
				Variants: productVariants,
				title: product.title,
				handle: product.handle,
				CategoryId: product.CategoryId,
				subtitle: product.subtitle,
				vat: product.vat,

				sendByMerchant: product.sendByMerchant,
				description: product.description || '[{ "type": "paragraph", "children": [{ "text": "" }] }]',
				instructions: product.instructions || '[{ "type": "paragraph", "children": [{ "text": "" }] }]',
				seoTitle: product.seoTitle || '',
				seoDescription: product.seoDescription || '',
				type: product.type || 'shippable',
				brand: product.brand || null,
				contactInstructions: product.contactInstructions || '',
				status: product.status || 'revision',
				video: product.video || '',
				unit: product.unit || 'unidad',
				DefaultImage: product.DefaultImage?.id || null,
				Images: product.Images?.map(i => i?.id) || [],
				otherFeatures: otherProductFeatures,
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setDefaultImage({ ...product.DefaultImage });
			setImages(product.Images.map(image => ({ ...image })));
			setDirty(false);
			setIsFormFilled(true);
		}
	}, [product, setValue, setDirty]);

	const handleDefaultImage = useCallback(
		([newImage]) => {
			setImagesUploading(true);
			setDefaultImage(newImage);
			setValue('productForm.DefaultImage', newImage.id);
			setImagesUploading(false);
		},
		[setDefaultImage, setImagesUploading, setValue]
	);

	const handleGalleryImages = useCallback(
		_newData => {
			setImagesUploading(true);
			const prevImages = getValues('productForm.Images') || [];
			setValue('productForm.Images', [
				...prevImages,
				..._newData.map(image => image.id).filter(imageId => !prevImages.includes(imageId)),
			]);
			setImages(prev => [
				...prev,
				// Add images that are not already added
				..._newData.filter(image => !prev.some(prevImage => prevImage.id === image.id)),
			]);
			setImagesUploading(false);
		},
		[setImages, setImagesUploading, setValue, getValues]
	);

	// Using calculateFormScore in combination with watch "score" state
	const calculateProductScore = input => {
		if (input) {
			let total = Object.entries(input).length;
			let count = 0;

			for (var [key, value] of Object.entries(input)) {
				if (key === 'description' || key === 'instructions') {
					if (!/"text":""/.test(value)) count++;
				} else if (key === 'unit') {
					if (value !== '' || value === null) count++;
				} else if (
					(value !== null && value !== '' && value !== undefined && value.length > 0) ||
					key === 'vat'
				) {
					count++;
				}
			}
			setScore((100 * count) / total);
		} else {
			// When no data 0%
			setScore(0);
		}
	};

	watch(({ productForm }, { name }) => {
		calculateProductScore(productForm);
		// Set template for contactInstructions
		if (name === 'productForm.type') {
			if (getValues('productForm.type') === 'customizable') {
				setProductType('customizable');
				setValue(
					'productForm.contactInstructions',
					`Producto personalizable. Envía tu diseño y número de pedido a ${
						user.type === 'merchant' ? user?.Merchant?.administrativeEmail : 'agregar-email@de-asociado.com'
					}`
				);
			} else if (getValues('productForm.type') === 'budgetable') {
				setProductType('budgetable');
				setValue(
					'productForm.contactInstructions',
					`${
						user.type === 'merchant' ? user?.Merchant?.administrativeEmail : 'agregar-email@de-asociado.com'
					}`
				);
			} else setProductType('shippable');
		}
		if (name === 'productForm.title') {
			setValue('productForm.handle', slugify(productForm.title));
		}
	});
	const displayInstructionsInput = type => {
		if (type === 'customizable')
			return (
				<TextareaForm
					name='productForm.contactInstructions'
					control={control}
					label='Instrucciones de personalización'
					className='border border-coral-300 py-2 px-4 font-normal'
					placeholder='Instrucciones para envío de archivos'
					maxLength='160'
					description={
						<span className='flex flex-col'>
							<p className='ml-4 text-sm italic text-gray-600'>* Máximo 160 caracteres.</p>
							<p className='ml-4 text-sm italic text-gray-600'>Puedes cambiar el correo</p>
						</span>
					}
					required={productType !== 'shippable'}
				/>
			);
		if (type === 'budgetable')
			return (
				<InputForm
					register={register}
					name='productForm.contactInstructions'
					control={control}
					label='Correo para enviar los documentos'
					className='border border-coral-300 py-2 px-4 font-semibold'
					placeholder='Email'
					type='email'
					description={<p className='ml-4 text-sm italic text-gray-600'>Puedes cambiar el correo</p>}
					required={productType !== 'shippable'}
				/>
			);
		return null;
	};

	const isRequired = useCallback(() => {
		if (newProduct) {
			return true;
		} else if (user.type === 'admin') {
			return false;
		}
		return true;
	}, [newProduct, user]);

	return (
		isFormFilled && (
			<div>
				<Score
					data={score}
					name='de producto'
					content='Si tu ficha supera el 75% de cumplimentación, tiene muchas más probabilidades de generar ventas'
				/>
				<form
					onSubmit={handleSubmit(({ productForm }) => {
						if (!disabled) {
							let status = getValues('productForm.status');

							// Check that product contains defaultImage to be created
							let defaultImage = getValues('productForm.DefaultImage');
							if (!defaultImage) {
								addErrorMessage('Producto', 'El producto debe contener "Imagen destacada"');
								return;
							}

							// Set product status when user type 'merchant' update the product
							if (user.type === 'merchant') {
								if (status === 'inactive') status = 'revision';
								if (status === 'active') status = 'modified';
							}
							// Check that contactInstructions contains email if product type is 'customizable' or 'budgetable'
							if (
								productType !== 'shippable' &&
								!isEmailInText(getValues('productForm.contactInstructions'))
							) {
								setFocus('productForm.contactInstructions');
								if (productType === 'customizable') {
									addErrorMessage(
										'Producto',
										'Instrucciones de personalización debe contener un correo electrónico'
									);
									return;
								}
								if (productType === 'budgetable') {
									addErrorMessage(
										'Producto',
										'Correo para enviar los documentos debe contener un correo electrónico'
									);

									return;
								}
							}

							let { Variants, otherFeatures, ...restProductForm } = productForm;

							Variants = Variants.map(variant => ({
								...variant,
								price: variant.price === '' ? null : variant.price,
								discountedPrice: variant.discountedPrice === '' ? null : variant.discountedPrice,
							}));

							setDisabled(true);
							onSubmit({
								Variants,
								otherFeatures: JSON.stringify(otherFeatures),
								status,
								...restProductForm,
							});

							setDisabled(false);
						}
					})}
					encType='multipart/form-data'
					className={disabled ? 'opacity-50' : ''}
				>
					{product && (
						<>
							<Input
								className='border-none'
								value={formatDate(product.createdAt)}
								label='Fecha de creación'
								readOnly
								disableNonTranslatableFields={disableNonTranslatableFields}
							/>
							<Input
								className='border-none'
								value={formatDate(product.updatedAt)}
								label='Fecha última modificación'
								readOnly
								disableNonTranslatableFields={disableNonTranslatableFields}
							/>
						</>
					)}
					<SelectForm
						name='productForm.type'
						control={control}
						register={register}
						width='w-1/3'
						label='Tipo de producto'
						disableNonTranslatableFields={disableNonTranslatableFields}
						options={[
							{ value: 'shippable', name: 'Enviable' },
							{ value: 'customizable', name: 'Personalizable' },
							{ value: 'budgetable', name: 'Presupuesto' },
						]}
					/>
					{displayInstructionsInput(productType)}

					<AuthorizationBoundary for={['merchant']}>
						<Input
							className='border-none'
							value={translasteStatus(getValues('productForm.status'))}
							label='Estado'
							readOnly
							disableNonTranslatableFields={disableNonTranslatableFields}
						/>
					</AuthorizationBoundary>

					<AuthorizationBoundary for={['admin']}>
						<SelectForm
							name='productForm.status'
							control={control}
							register={register}
							width='w-1/3'
							label='Estado'
							disableNonTranslatableFields={disableNonTranslatableFields}
							options={statusList}
							type='text'
						/>

						{/* En SelectMerchantInput se hace un use-merchant que muestra los merchants y permite cambiar el MerchantId */}
						<SelectMerchantInput
							name='productForm.MerchantId'
							control={control}
							register={register}
							disableNonTranslatableFields={disableNonTranslatableFields}
							width='w-1/3'
							label='Selecciona un asociado'
							required={isRequired()}
						/>
					</AuthorizationBoundary>

					<InputForm
						name='productForm.title'
						control={control}
						register={register}
						label='Nombre del Producto'
						className='border border-coral-300 py-2 px-4 font-semibold'
						placeholder='Nombre del Producto'
						required={isRequired()}
					/>

					<InputForm
						name='productForm.subtitle'
						control={control}
						register={register}
						label='Subtítulo'
						className='mt-1 border border-coral-300 p-4'
						required={isRequired()}
						maxLength='80'
					>
						<p className='ml-4 text-sm italic text-gray-600'>* Máximo 80 caracteres.</p>
					</InputForm>

					<div
						className={`mb-4 flex w-full max-w-screen-md items-center ${
							disableNonTranslatableFields ? 'hidden' : ''
						}`}
					>
						<span className='text-gray-700 w-1/3 text-lg font-semibold'>
							Categoría <span className='text-coral-600'>*</span>
						</span>
						<DropdownTreeForm
							name='productForm.CategoryId'
							control={control}
							initialValue={getValues('productForm.CategoryId')}
							noneSelectedLabel='Seleccione una categoría'
							treeData={categoriesSimpleTree(null, data?.ProductCategories)}
							onChange={handleDropdownTreeForm}
							width='w-1/3'
							required={isRequired()}
						/>
					</div>

					<div className='mb-4 flex w-full max-w-screen-md '>
						<div className='w-1/3'></div>
						<p className='text-sm italic text-gray-600'>
							* Esta característica, no se reflejará en el grado de cumplimentación.
						</p>
					</div>

					<Field label='Otras caracteristicas' width='w-full' fieldWidth='w-full'>
						<div className='w-2/3'>
							<div className='flex px-2 w-full'>
								<p className='w-230px text-sm italic text-gray-600'>Nombre de caracteristica</p>
								<p className='w-230px ml-4 text-sm italic text-gray-600'>Valor de caracteristica</p>
							</div>
							<OtherFeaturesArray
								control={control}
								register={register}
								fields={otherFeatureFields}
								unregister={unregister}
								setValue={setValue}
								getValues={getValues}
							/>
						</div>
					</Field>
					<Editor name='productForm.description' control={control} label='Descripción' />
					<Editor name='productForm.instructions' control={control} label='Modo de Uso' />
					<hr className='my-2 border-0' />
					<VariantsArray
						control={control}
						fields={variantsFields}
						unregister={unregister}
						register={register}
						setValue={setValue}
						getValues={getValues}
						watch={watch}
						isRequired={isRequired}
						isAdmin={user.type === 'admin'}
						disableNonTranslatableFields={disableNonTranslatableFields}
					/>

					<SelectForm
						name='productForm.vat'
						type='number'
						control={control}
						register={register}
						disableNonTranslatableFields={disableNonTranslatableFields}
						width='w-1/3'
						label='Tipo de IVA'
						required={isRequired()}
						options={[
							{ value: 21, name: '21%' },
							{ value: 10, name: '10%' },
							{ value: 4, name: '4%' },
							{ value: 0, name: '0%' },
						]}
					/>

					{/* <Toggle
					name='productForm.sendByMerchant'
					control={control}
					label='Envío exclusivo por cuenta del comercio'
					disabled={disableNonTranslatableFields}
					subtitle='Esta característica, no se reflejará en el grado de cumplimentación.'
					activeText='Activo'
				/> */}

					<hr className='my-8 border-0' />

					<DropImageArea
						label='Imagen destacada'
						Images={[defaultImage]}
						onChange={handleDefaultImage}
						whileIdleText='Pincha o Suelta tus Imágenes aquí'
						onDelete={() => {
							setValue('productForm.DefaultImage', null);
							setDefaultImage(null);
						}}
						required={isRequired()}
					/>

					<DropImageArea
						label='Galería de Imágenes'
						Images={Images}
						onChange={handleGalleryImages}
						whileIdleText='Pincha o Suelta tus Imágenes aquí'
						onDelete={id => setImages(Images => [...Images.filter(i => i?.id !== id)])}
						multiple
					>
						{/* <Button className='my-3'>Añadir imagenes</Button> */}
						<p className='text-sm'>
							Para que su producto transmita confianza al cliente, entre las fotos se deben incluir fotos
							de las etiquetas con primer plano de todas las leyendas legales (Registro Sanitario,
							composición, calorías, origen…).
						</p>
					</DropImageArea>

					<InputForm
						name='productForm.video'
						control={control}
						register={register}
						label='Enlace a Youtube'
						disableNonTranslatableFields={disableNonTranslatableFields}
						className='border border-coral-300 py-2 px-4 font-semibold'
						placeholder='https://...'
					/>
					<div className='accordion w-full mt-4' id='accordionMoreOptions'>
						<div className='accordion-item bg-white border border-coral-500'>
							<h2 className='accordion-header mb-0' id='moreOptions'>
								<button
									className='accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-coral-500 text-left bg-white border-0 rounded-none transition focus:outline-none'
									type='button'
									data-bs-toggle='collapse'
									data-bs-target='#collapseMoreOptions'
									aria-expanded='false'
									aria-controls='collapseMoreOptions'
								>
									<LayoutHeader>Opciones avanzadas</LayoutHeader>
								</button>
							</h2>
							<div
								id='collapseMoreOptions'
								className='accordion-collapse collapse'
								aria-labelledby='moreOptions'
								data-bs-parent='#accordionMoreOptions'
							>
								<div className='accordion-body py-4 px-5'>
									{/* Option for 'admin' to highlight or de-highlight product */}
									<AdminHighlightProduct
										product={product}
										onSubmit={onSubmit}
										addErrorMessage={addErrorMessage}
									/>

									<p className='my-8 text-lg'>
										El SEO es necesario para ayudar a los motores de búsqueda a entender sobre qué
										trata cada página, mejorar el ranking en buscadores y que más personas
										encuentren tu sitio.
									</p>

									<InputForm
										name='productForm.handle'
										control={control}
										register={register}
										label='Url del Producto'
										className='flex-row border border-coral-300 py-2 px-4 font-semibold'
										placeholder='Url del Producto'
										required={isRequired()}
									>
										<p className='ml-4 flex-1 text-sm italic text-gray-600'>{`${
											process.env.REACT_APP_SHOP_URL
										}/${getValues('productForm.handle')}`}</p>
									</InputForm>

									<InputForm
										name='productForm.seoTitle'
										control={control}
										register={register}
										label='Título SEO'
										className='border border-coral-300 py-2 px-4 font-semibold'
										placeholder='Título SEO'
										maxLength='80'
										description={
											<p className='ml-4 text-sm italic text-gray-600'>* Máximo 80 caracteres.</p>
										}
									/>

									<TextareaForm
										name='productForm.seoDescription'
										control={control}
										label='Descripción SEO'
										className='border border-coral-300 py-2 px-4 font-normal'
										placeholder='Descripción SEO'
										maxLength='160'
										description={
											<p className='ml-4 text-sm italic text-gray-600'>
												* Máximo 160 caracteres.
											</p>
										}
									/>

									<InputForm
										name='productForm.brand'
										control={control}
										register={register}
										label='Marca SEO'
										className='border border-coral-300 py-2 px-4 font-normal'
										placeholder='Marca del producto para SEO.'
										maxLength='80'
										description={
											<p className='ml-4 text-sm italic text-gray-600'>* Máximo 80 caracteres.</p>
										}
										disableNonTranslatableFields={disableNonTranslatableFields}
									>
										<span className='pl-4'>Marca del producto</span>
									</InputForm>
								</div>
							</div>
						</div>
					</div>

					<div className='flex gap-10'>
						<Button className='mt-4' disabled={disabled || catsLoading || imagesUploading} type='submit'>
							{product ? 'Actualizar producto' : 'Crear producto'}
						</Button>
						{product?.id && (
							<Button
								as='a'
								bg='border border-coral-500 text-coral-500 hover:none'
								hover='bg-gray-200'
								className='mt-4'
								target='_blank'
								href={`${process.env.REACT_APP_SHOP_URL}/${product.handle}`}
							>
								PREVISUALIZAR PRODUCTO EN TIENDA
							</Button>
						)}
					</div>
				</form>
			</div>
		)
	);
};

const AdminHighlightProduct = ({ product, onSubmit }) => {
	const handleHighlightProduct = e => {
		e.preventDefault();
		if (product) {
			onSubmit({ highlight: !product.highlight });
		}
	};
	return (
		<AuthorizationBoundary for={['admin']}>
			<div className='grid h-full grid-flow-col p-4 pr-8 shadow'>
				<div className='col-start p-4'>
					<p className='text-2xl uppercase'>
						{`¿${product?.highlight ? 'No destacar' : 'Destacar'} este producto?`}
					</p>
					{product ? (
						<p>
							{product?.highlight
								? 'Al pulsar "NO DESTACAR PRODUCTO" desaparecerá de la sección de "Productos Destacados" en la página principal.'
								: 'Al pulsar "DESTACAR PRODUCTO" aparecerá en la sección de "Productos Destacados" en la página principal.'}
						</p>
					) : (
						<p>Luego de crear el producto podrás destacarlo.</p>
					)}
				</div>
				<div className='flex items-center justify-end'>
					<Button
						onClick={handleHighlightProduct}
						disabled={product ? false : true}
						className='float-right mt-4 ml-4'
					>
						{product
							? product?.highlight
								? 'NO DESTACAR PRODUCTO'
								: 'DESTACAR PRODUCTO'
							: 'DESTACAR PRODUCTO'}
					</Button>
				</div>
			</div>
		</AuthorizationBoundary>
	);
};

// Variants
const VariantsArray = ({
	control,
	getValues,
	setValue,
	unregister,
	fields,
	disableNonTranslatableFields,
	register,
	isRequired,
	isAdmin,
}) => {
	const variants = getValues('productForm.Variants') || [];
	return variants?.map((_, i) => (
		<div key={i} className='flex gap-10'>
			<VariantField
				control={control}
				register={register}
				variants={variants}
				setValue={setValue}
				unregister={unregister}
				index={i}
				fields={fields}
				isRequired={isRequired}
				isAdmin={isAdmin}
				disableNonTranslatableFields={disableNonTranslatableFields}
			/>
		</div>
	));
};

// Single fields
const VariantField = ({
	variants,
	control,
	index,
	unregister,
	register,
	setValue,
	fields,
	isRequired,
	props,
	disableNonTranslatableFields,
	isAdmin,
}) => {
	return (
		<Field>
			<div
				className={`flex w-full flex-col ${
					variants.length - 1 !== index ? 'border-b border-dashed border-coral-600' : ''
				}`}
			>
				<div className='flex w-full'>
					<div className='flex w-1/3 flex-col'>
						{variants.length > 1 ? (
							<>
								<p className='text-lg italic text-gray-600'>
									<span className='text-coral-600'>*</span> Nombre de variación ej: XL
								</p>
								<InputForm
									register={register}
									control={control}
									name={`productForm.Variants.${index}.title`}
									type='text'
									placeholder='Nombre'
									width=''
									required={isAdmin ? false : variants.length > 1}
								/>
							</>
						) : (
							<label
								className={`$ font-semibold ${
									props?.disabled ? 'text-gray-500' : 'text-gray-700'
								} text-lg `}
							>
								Precio
							</label>
						)}
					</div>
					<div className='flex gap-8'>
						<span className='flex flex-col'>
							<p className='text-lg italic text-gray-600'>
								<span className='text-coral-600'>*</span> Precio de variación
							</p>
							<InputForm
								name={`productForm.Variants.${index}.price`}
								control={control}
								register={register}
								min='0.00'
								width='ml-8 w-full'
								placeholder='Precio'
								suffix='€'
								required={isRequired()}
								type='number'
								step='0.01'
								disabled={disableNonTranslatableFields}
							/>
						</span>

						<span className='flex flex-col'>
							<p className='text-lg italic text-gray-600'>Precio con descuento</p>
							<InputForm
								name={`productForm.Variants.${index}.discountedPrice`}
								control={control}
								register={register}
								width=''
								placeholder='Precio con descuento'
								suffix='€'
								type='number'
								step='0.01'
								disabled={disableNonTranslatableFields}
							/>
						</span>
						<div className={`flex gap-4 ${disableNonTranslatableFields ? 'hidden' : ''}`}>
							{variants.length !== 1 && (
								<RemoveFields
									key={`rf_${index}`}
									setValue={setValue}
									variants={variants}
									unregister={unregister}
									index={index}
								/>
							)}
							{variants.length - 1 === index && (
								<AddFields
									key={`af_${index}`}
									setValue={setValue}
									variants={variants}
									unregister={unregister}
									index={index}
									fields={fields}
								/>
							)}
						</div>
					</div>
				</div>
				<div className={`flex w-full ${disableNonTranslatableFields ? 'hidden' : ''}`}>
					<div className='flex w-1/3 flex-col'>
						<p className='text-lg italic text-gray-600'>EAN</p>
						<InputForm
							register={register}
							control={control}
							name={`productForm.Variants.${index}.barcode`}
							type='text'
							placeholder='EAN'
							width=''
						/>
					</div>
					<div className='flex gap-8'>
						<span className='flex flex-col'>
							<p className='text-lg italic text-gray-600'>SKU</p>
							<InputForm
								register={register}
								control={control}
								name={`productForm.Variants.${index}.sku`}
								type='text'
								placeholder='SKU'
								width=''
							/>
						</span>

						<span className='flex flex-col pl-2'>
							<p className='text-lg italic text-gray-600'>Stock</p>
							<SelectForm
								name={`productForm.Variants.${index}.isAvailable`}
								control={control}
								options={[
									{ value: true, name: 'Disponible' },
									{ value: false, name: 'No Disponible' },
								]}
								type='boolean'
								width=''
							/>
						</span>
					</div>
				</div>
			</div>
		</Field>
	);
};

const AddFields = ({ index, setValue, variants, unregister, fields }) => {
	const handleAddVariant = e => {
		e.preventDefault();
		if (variants[index].price) {
			setValue('productForm.Variants', [...variants, ...fields]);
			unregister('productForm.Variants', { keepValue: true });
		}
	};

	return (
		<button
			onClick={handleAddVariant}
			className='flex h-8 w-8 items-center justify-center self-center rounded-full bg-coral-300 font-bold'
		>
			+
		</button>
	);
};

const RemoveFields = ({ index, setValue, variants, unregister }) => {
	const handleRemoveVariant = e => {
		e.preventDefault();
		setValue(
			'productForm.Variants',
			variants.filter((_, i) => i !== index)
		);
		unregister('productForm.Variants', { keepValue: true });
	};

	return (
		<button
			onClick={handleRemoveVariant}
			className='flex h-8 w-8 items-center justify-center self-center rounded-full bg-coral-300 font-bold'
		>
			-
		</button>
	);
};

// Other Features
const OtherFeaturesArray = ({ control, getValues, setValue, unregister, fields, register }) => {
	const otherFeatures = getValues('productForm.otherFeatures') || [];
	return otherFeatures?.map((value, i) => {
		return (
			<div key={i} className='flex gap-4'>
				<FeatureField control={control} register={register} index={i} />
				<div className='flex items-center align-middle justify-center h-full'>
					{otherFeatures.length !== 1 && (
						<RemoveFeatureFields
							setValue={setValue}
							otherFeatures={otherFeatures}
							unregister={unregister}
							index={i}
						/>
					)}
					{otherFeatures.length - 1 === i && (
						<AddFeatureFields
							setValue={setValue}
							otherFeatures={otherFeatures}
							unregister={unregister}
							index={i}
							fields={fields}
						/>
					)}
				</div>
			</div>
		);
	});
};

// Single fields
const FeatureField = ({ control, index, register }) => {
	return (
		<div className='flex'>
			<InputForm
				register={register}
				control={control}
				name={`productForm.otherFeatures.${index}.title`}
				type='text'
				className='mr-4'
				placeholder='Nombre'
				width=''
			/>
			<InputForm
				register={register}
				control={control}
				name={`productForm.otherFeatures.${index}.value`}
				type='text'
				placeholder='Valor'
				width=''
			/>
		</div>
	);
};

const AddFeatureFields = ({ index, setValue, otherFeatures, unregister, fields }) => {
	const handleAddFeature = e => {
		e.preventDefault();
		if (otherFeatures[index].title && otherFeatures[index].value !== null) {
			setValue('productForm.otherFeatures', [...otherFeatures, ...fields]);
			unregister('productForm.otherFeatures', { keepValue: true });
		}
	};

	return (
		<button
			onClick={handleAddFeature}
			className={`h-8 w-8 my-1 ${index > 0 ? 'ml-2' : ''} rounded-full bg-coral-300 font-bold`}
		>
			+
		</button>
	);
};

const RemoveFeatureFields = ({ index, setValue, otherFeatures, unregister }) => {
	const handleRemoveFeature = e => {
		e.preventDefault();
		setValue(
			'productForm.otherFeatures',
			otherFeatures.filter((_, i) => i !== index)
		);
		unregister('productForm.otherFeatures', { keepValue: true });
	};

	return (
		<button onClick={handleRemoveFeature} className='h-8 w-8 my-1 rounded-full bg-coral-300 font-bold'>
			-
		</button>
	);
};

const toArrayOfObjects = v => {
	if (!Array.isArray(v)) {
		return JSON.parse(v);
	}
	return v;
};

export default ProductForm;
