import { gql, useQuery } from '@apollo/client';

const STATISTICS_QUERY = gql`
	query Statistics($graphYear: Int, $MerchantId: ID) {
		Statistics(graphYear: $graphYear, MerchantId: $MerchantId) {
			... on StatisticsValues {
				totals {
					totalSales
					totalDiscounts
					totalTax
					numberOfOrders
				}
				salesList {
					month
					orderAmountPerMonth
					orderCountPerMonth
				}
			}
			... on AuthorizationFault {
				message
				user
			}
			... on DatabaseFault {
				message
			}
		}
	}
`;

export const useStatistics = props => useQuery(STATISTICS_QUERY, props);
