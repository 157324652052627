import React from 'react';

import { Link } from 'react-router-dom';

import Button from 'components/Button';
import LoadingView from 'components/LoadingView';
import ContentTable from 'components/ContentTable';
import LayoutHeader from 'components/LayoutHeader';

import useFaqs, { useDeleteFaq } from 'hooks/use-faqs';

const CreateFAQButton = () => (
	<Button as={Link} to='/dashboard/faqs/new'>
		Nueva FAQ
	</Button>
);

const FAQs = () => {
	const { faqs, loading } = useFaqs();

	if (loading) return null;

	return (
		<>
			<LayoutHeader actions={[CreateFAQButton]}>FAQs</LayoutHeader>
			<LoadingView loading={loading} whileLoading='Buscando preguntas frecuentes'>
				<ContentTable
					content={faqs}
					empty='No hay FAQs disponibles'
					columns={['Pregunta', 'Respuesta', <th className='text-right font-bold pr-6'>Acciones</th>]}
					render={(f, index) => (
						<tr key={index} className='text-gray-700 border-t border-dashed border-coral-300 align-top'>
							<td className='px-4 py-4 w-2/5'>{f.question}</td>
							<td className='px-4 py-4 w-2/5'>{f.answer}</td>
							<td className='px-4 py-4 flex justify-end'>
								<Button to={`/dashboard/faqs/edit/${f.id}`} as={Link}>
									<svg viewBox='0 0 20 20' fill='currentColor' className='pencil-alt w-4 h-4'>
										<path d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z'></path>
										<path
											fillRule='evenodd'
											d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z'
											clipRule='evenodd'
										></path>
									</svg>
								</Button>
								<DeleteButton id={f.id} />
							</td>
						</tr>
					)}
				/>
			</LoadingView>
		</>
	);
};

const DeleteButton = ({ id }) => {
	const [deleteFaq] = useDeleteFaq(id);

	return (
		<Button className='ml-2' onClick={() => deleteFaq()}>
			<svg viewBox='0 0 20 20' fill='currentColor' className='trash w-4 h-4'>
				<path
					fillRule='evenodd'
					d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
					clipRule='evenodd'
				></path>
			</svg>
		</Button>
	);
};

export default FAQs;
