import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import { useToast } from 'components/Toast';
import { useCreateProduct } from 'hooks/use-products';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from 'hooks/use-auth';
import ProductForm from './ProductForm';

const ProductNew = () => {
	const navigate = useNavigate();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { user, locale, setLocale, getLazyNotificationCount } = useAuth();

	useEffect(() => {
		// To create new product has to be default locale
		setLocale(process.env.REACT_APP_DEFAULT_LOCALE);
	}, []);

	const [productNewResponse, setProductNewResponse] = useState(null);

	// Status list
	const statusList = [
		{ value: 'active', name: 'Activo' },
		{ value: 'inactive', name: 'Inactivo' },
		{ value: 'revision', name: 'Revisión' },
	];

	// productNewResponse update States
	useEffect(() => {
		if (productNewResponse && productNewResponse.__typename === 'DatabaseFault') {
			addErrorMessage('Crear producto', productNewResponse.message);
		} else if (productNewResponse && productNewResponse.__typename === 'AuthorizationFault') {
			addErrorMessage('Crear producto', productNewResponse.message);
		} // All Errors implement Fault
		else if (productNewResponse && productNewResponse.__typename.endsWith('Fault')) {
			addErrorMessage('Crear producto', productNewResponse.message);
		} else if (productNewResponse && productNewResponse.__typename === 'Product') {
			addSuccessMessage('Producto', `${productNewResponse.title} creado correctamente`);
			if (user?.type === 'admin') {
				getLazyNotificationCount();
			}
			navigate(`/dashboard/catalogo/productos/edit/${productNewResponse.id}`);
		}
	}, [productNewResponse, addSuccessMessage, addErrorMessage, getLazyNotificationCount, navigate, user.type]);

	const [createProduct] = useCreateProduct({
		onCompleted: ({ ProductCreate }) => {
			setProductNewResponse(ProductCreate);
		},
	});

	return (
		<>
			<LanguageTabs disabled={true} />
			<Card>
				<LayoutHeader>Nuevo Producto</LayoutHeader>
				<ProductForm
					onSubmit={input => {
						createProduct({ variables: { input, locale } });
					}}
					statusList={statusList}
					newProduct
				/>
			</Card>
		</>
	);
};

export default ProductNew;
