import Button from 'components/Button';
import DropImageArea from 'components/DropfileArea';
import { useToast } from 'components/Toast';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateConfiguration } from 'hooks/use-configurations';

const ConfigurationImageInput = ({ setDirty = () => {}, imageData, locale }) => {
	const [updateConfiguration, { loading }] = useUpdateConfiguration();
	const [image, setImage] = useState();
	const { addSuccessMessage, addErrorMessage } = useToast();

	const {
		handleSubmit,
		getValues,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			configurationForm: {
				description: '',
				key: '',
				valueType: '',
				ValueConfigurationImageId: '',
			},
		},
	});

	// Load Edit data
	useEffect(() => {
		if (imageData) {
			setValue('configurationForm', {
				description: imageData.description || '',
				key: imageData.key || '',
				valueType: imageData.valueType || '',
				ValueConfigurationImageId: imageData.ValueConfigurationImage?.id || null,
			});
			// Creates a copy of each image element so it can be modified inside the DropfileImage
			setImage({ ...imageData.ValueConfigurationImage });
			setDirty(false);
		}
	}, [imageData, setValue, setDirty]);

	// Set the dirty state used in LanguageTabs
	useEffect(() => {
		if (isDirty) {
			setDirty(isDirty);
		}
	}, [setDirty, isDirty]);

	const handleImage = useCallback(
		([newImage]) => {
			setImage(newImage);
			setValue('configurationForm.ValueConfigurationImageId', newImage.id);
		},
		[setImage, setValue]
	);

	return (
		<form
			onSubmit={handleSubmit(({ configurationForm }) => {
				updateConfiguration({
					variables: {
						configurationId: imageData.id,
						input: configurationForm,
						locale,
					},
				}).then(({ data: { ConfigurationUpdate } }) => {
					if (ConfigurationUpdate && ConfigurationUpdate.__typename === 'Configuration') {
						addSuccessMessage('Configuración', 'actualizado correctamente');
					}
					// All Errors implement Fault
					else if (ConfigurationUpdate && ConfigurationUpdate.__typename.endsWith('Fault')) {
						addErrorMessage('Configuración', ConfigurationUpdate.message);
					}
				});
			})}
		>
			<DropImageArea
				label={getValues('configurationForm.description')}
				Images={[image]}
				onChange={handleImage}
				whileIdleText='Pincha o Suelta tu Imágen aquí'
				onDelete={() => {
					setValue('configurationForm.ValueConfigurationImageId', null);
					setImage(null);
				}}
			/>
			<Button className='mt-5' type='submit' disabled={loading}>
				Guardar imagen
			</Button>
		</form>
	);
};

export default ConfigurationImageInput;
