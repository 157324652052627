import formatDate from 'lib/formatDate';
import React from 'react';
import { Link } from 'react-router-dom';
import ContentTable from './ContentTable';
import LoadingView from 'components/LoadingView';
import printUserName from 'lib/printUserName';
import columnTitleElement from 'lib/columnTitleElement';
import TooltipMenu from 'components/TooltipMenu';
import Modal from 'components/Modal';
import { useCustomerDelete } from 'hooks/use-customers';
import { useToast } from 'components/Toast';

const CustomersList = ({
	data,
	loading,
	orderByDate,
	orderByFirstName,
	orderByEmail,
	getCustomerMenuList,
	openModal,
	setOpenModal,
	customers,
	setCustomers,
}) => {
	const { addErrorMessage, addSuccessMessage } = useToast();
	const [customerDelete] = useCustomerDelete();

	return (
		<LoadingView loading={loading} whileLoading='Buscando clientes…'>
			<ContentTable
				columns={[
					columnTitleElement('Email', orderByEmail),
					columnTitleElement('Nombre', orderByFirstName),
					columnTitleElement('Fecha de alta', orderByDate),
					// columnTitleElement('Cantidad de pedidos'),
				]}
				content={data}
				render={user =>
					user && (
						<tr key={user.id} className='text-gray-700 border-t border-dashed border-coral-300'>
							<td className='px-4 py-2'>
								<Link
									className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
									to={`/dashboard/clientes/${user.id}`}
								>
									{user.email}
								</Link>
							</td>
							<td className='px-4 py-2'>
								<Link
									className='border-b border-dashed border-coral-300 hover:text-coral-500 tranisiton-color duration-100'
									to={`/dashboard/clientes/${user.id}`}
								>
									{printUserName(user)}
								</Link>
							</td>
							<td className='px-4 py-2'>{formatDate(user.createdAt)}</td>
							<td className='px-6'>
								<div className='h-16'>
									<TooltipMenu menuList={getCustomerMenuList(user.id)} />
								</div>
								<Modal
									modalObject={{
										title: 'Confirmación',
										description: (
											<p className='text-lg leading-5 text-gray-500'>{`Al confirmar se eliminará la cuenta asociada al email ${user?.email} y no se podrá anular.`}</p>
										),
										btnText: 'Confirmar',
										cancelBtnText: 'Cancelar',
									}}
									openModal={openModal}
									setOpenModal={setOpenModal}
									handleSubmit={() => {
										customerDelete({
											variables: {
												customerId: user.id,
											},
										}).then(res => {
											if (res.data.CustomerDelete.success) {
												addSuccessMessage('Usuario', `${user.email} eliminado correctamente`);
												const updatedCustomers = customers.List.filter(
													customer => customer.id !== user.id
												);
												setCustomers(prev => ({
													...prev,
													List: updatedCustomers,
												}));
											} else {
												addErrorMessage('Usuario', res.data.CustomerDelete.message);
											}
											setOpenModal(false);
										});
									}}
								/>
							</td>
							{/* <td className='px-4 py-2'>próximamente</td> */}
						</tr>
					)
				}
				empty={<p>No hay usuarios</p>}
			/>
		</LoadingView>
	);
};

export default CustomersList;
