/* eslint-disable no-console */
import { gql, useMutation, useQuery } from '@apollo/client';

const FILEINFO_FRAGMENT = gql`
	fragment MediaInfo on Media {
		id
		filename
		src
		title
		alt
	}
`;

const MEDIA_QUERY = gql`
	query Media($id: ID!, $locale: String) {
		Media(id: $id, locale: $locale) {
			id
			src
			filename
			title
			alt
		}
	}
`;

const UPLOADFILE_MUTATION = gql`
	mutation UploadFile($file: Upload!) {
		UploadFile(file: $file) {
			...MediaInfo
		}
	}
	${FILEINFO_FRAGMENT}
`;

const UPLOADFILES_MUTATION = gql`
	mutation UploadFiles($files: [Upload!]!) {
		UploadFiles(files: $files) {
			...MediaInfo
		}
	}
	${FILEINFO_FRAGMENT}
`;

const DELETEFILES_MUTATION = gql`
	mutation DeleteFile($id: ID!) {
		DeleteFile(id: $id) {
			... on Success {
				success
			}
			... on DatabaseFault {
				message
				obj_id
				databaseIssue
			}
			... on AuthorizationFault {
				message
				user
			}
		}
	}
`;

const SETIMAGEATTRIBUTES_MUTATION = gql`
	mutation SetImageAttributes($id: ID!, $attributes: MediaAttributes, $locale: String) {
		SetImageAttributes(id: $id, attributes: $attributes, locale: $locale) {
			...MediaInfo
		}
	}
	${FILEINFO_FRAGMENT}
`;

export const useMedia = (id, locale) => useQuery(MEDIA_QUERY, { variables: { id, locale }, skip: !id });
export const useSingleFileUpload = () => useMutation(UPLOADFILE_MUTATION);
export const useMultipleFileUpload = props => useMutation(UPLOADFILES_MUTATION, props);
export default useMultipleFileUpload;
export const useDeleteFile = props => useMutation(DELETEFILES_MUTATION, props);
export const useSetImageAttributes = props =>
	useMutation(SETIMAGEATTRIBUTES_MUTATION, { ...props, fetchPolicy: 'no-cache' });
