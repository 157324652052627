import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import { SectionHeader } from 'components/LayoutHeader';
import useAuth from 'hooks/use-auth';
import { useMerchant } from 'hooks/use-merchants';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import MerchantForm from './MerchantForm';

const MerchantEdit = () => {
	const { merchantId } = useParams();
	const { user, locale } = useAuth();

	const [dirty, setDirty] = useState(false);

	const { data: merchantData } = useMerchant(merchantId, locale);

	const alertStatus = status => {
		if (status === 'revision') {
			return (
				<Card className='border border-coral-300 mb-10 text-xl'>
					<p>
						Su cuenta se encuentra en estado de
						<span className='font-semibold capitalize'> revisión</span> en espera de ser activada por el
						Administrador de {process.env.REACT_APP_MARKETPLACE_NAME}.
					</p>
					<p>
						Puede actualizar sus datos (Cuenta, Administrativa y Comercial) pero deberá esperar a que su
						cuenta esté activa para tener acceso al resto de las opciones.
					</p>
				</Card>
			);
		}

		if (status === 'pending') {
			return (
				<Card className='border border-coral-300 mb-10 text-xl'>
					<p>
						Su cuenta se encuentra en estado de
						<span className='font-semibold capitalize'> pendiente</span>.
					</p>
					<p>
						Debe completar el registro en <span className='font-semibold'>Stripe</span> en la Ficha
						Administrativa para ser activada por el Administrador de
						{` ${process.env.REACT_APP_MARKETPLACE_NAME} luego de ser revisada.`}
					</p>
				</Card>
			);
		}
	};

	return (
		<>
			<LanguageTabs dirty={dirty} />
			<Card>
				<SectionHeader className='uppercase mb-8 md:text-4xl'>
					{merchantData?.Merchant?.commercialName || 'Asociado'}
				</SectionHeader>
				{user && user.status && alertStatus(user.status)}

				{merchantData && (
					<MerchantForm data={merchantData} adminLayout={user.type === 'admin'} setDirty={setDirty} />
				)}
			</Card>
		</>
	);
};

export default MerchantEdit;
