import React from 'react';
import TooltipMenu from 'components/TooltipMenu';

const columnTitleElement = (text, list) => (
	<div className='px-4 py-2 text-right' key={`${text}`}>
		<div className='flex flex-row w-full'>
			<p className='float-left text-center mt-1'>{text}</p>
			{list && (
				<TooltipMenu
					menuList={list()}
					chevron={false}
					chevronBlack='/images/list-icons/chevron-down.svg'
					className='relative w-6 h-6 mt-1'
				/>
			)}
		</div>
	</div>
);

export default columnTitleElement;
