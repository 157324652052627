import Button from 'components/Button';
import CheckForm from 'components/CheckForm';
import ContentTable from 'components/ContentTable';
import Currency from 'components/Currency';
import InputForm from 'components/InputForm';
import Modal from 'components/Modal';
import TextareaForm from 'components/TextareaForm';
import { useToast } from 'components/Toast';
import { useUpdateShippingOrderItems } from 'hooks/use-orders';
import useToggle from 'hooks/use-toggle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import translasteStatus from 'lib/translasteStatus';
import { PDFDownloadLink } from '@react-pdf/renderer';
import OrderPdf from 'components/OrderPdf';
import { Link } from 'react-router-dom';

const MerchantShippingDetails = ({
	orderId,
	orderData,
	merchantShipping,
	setTotalAmount,
	merchantShippingIndex,
	getLazyOrder,
}) => {
	const [openModal, setOpenModal] = useToggle();
	const [subtotalAmount, setSubtotalAmount] = useState(0);
	const [isFormFilled, setIsFormFilled] = useState(false);
	const [orderItemFields] = useState([
		{
			id: 0,
			discountedPrice: '',
			price: '',
			productTitle: '',
			quantity: '',
			sent: false,
			substituted: false,
			sentQuantity: null,
			Variant: {
				title: '',
				price: '',
				discountedPrice: '',
			},
		},
	]);
	const [disabled, setDisabled] = useState(
		!(merchantShipping?.status === 'new' || merchantShipping?.status === 'processing')
	);
	const [updateMerchantShipping] = useUpdateShippingOrderItems();

	const {
		unregister,
		register,
		trigger,
		handleSubmit,
		control,
		watch,
		setValue,
		getValues,
		formState: { isValid, errors },
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			merchantShippingForm: {
				orderItems: orderItemFields,
				note: '',
				packages: '',
				weight: '',
				status: '',
			},
		},
	});

	useEffect(() => {
		if (merchantShipping) {
			setValue('merchantShippingForm', {
				orderItems: merchantShipping.OrderItems.map(orderItem => ({
					...orderItem,
					sentQuantity: orderItem.sentQuantity ?? orderItem.quantity,
					oldSentQuantity: orderItem.sentQuantity ?? orderItem.quantity,
					discountedPrice: typeof orderItem.discountedPrice === 'number' ? orderItem.discountedPrice : '',
				})),
				note: merchantShipping.note || null,
				packages: merchantShipping.packages || null,
				weight: merchantShipping.weight || null,
				status: merchantShipping.status,
			});

			setSubtotalAmount(
				merchantShipping.OrderItems.reduce(
					(sum, { discountedPrice, price, sentQuantity, quantity }) =>
						sum + (discountedPrice || price) * (sentQuantity === null ? quantity : sentQuantity),
					0
				)
			);
			setIsFormFilled(true);
		}
	}, [merchantShipping, setValue, setSubtotalAmount]);

	return (
		isFormFilled && (
			<div>
				<div className={`${disabled && 'bg-gray-200'} mb-5 border border-dashed border-coral-300`}>
					<h2 className='mt-2 text-center text-lg font-medium'>
						{merchantShipping?.Merchant.commercialName}
					</h2>
					<p className='mt-2 text-center text-lg'>
						<span className='border border-coral-500 py-1 px-2 text-center font-medium text-coral-500'>
							{translasteStatus(getValues('merchantShippingForm.status'))}
						</span>
					</p>
					<ContentTable
						columns={[
							'Artículo',
							'Coste',
							'Cantidad',
							'Cantidad enviada',
							'Estado envio',
							'Producto Sustituido',
							'Total Compra',
						]}
						content={merchantShipping.OrderItems}
						render={(orderItem, index) => (
							<tr key={index} className='border-t border-dashed border-coral-300 text-sm text-gray-700'>
								<td className='px-4 py-2'>
									{orderItem.Variant && (
										<Link to={`/dashboard/catalogo/productos/edit/${orderItem.Variant.Product.id}`}>
											{orderItem.Variant.Product.title}
										</Link>
									)}
									<div className='text-xs'>{orderItem.Variant?.title}</div>
									<div className='text-xs'>
										{orderItem.Variant?.barcode ? `EAN: ${orderItem.Variant?.barcode}` : ''}
										{orderItem.Variant?.barcode && orderItem.Variant?.sku && ' - '}
										{orderItem.Variant?.sku ? `SKU: ${orderItem.Variant?.sku}` : ''}
									</div>
								</td>
								<td className='px-4 py-2'>
									<Currency>{orderItem.discountedPrice || orderItem.price}</Currency>
								</td>
								<td className='px-4 py-2'>{orderItem.quantity}</td>
								<TdOrderItem
									setSubtotalAmount={setSubtotalAmount}
									setTotalAmount={setTotalAmount}
									orderItem={orderItem}
									orderItemIndex={index}
									control={control}
									register={register}
									setValue={setValue}
									getValues={getValues}
									watch={watch}
									authorizeProductSubstitution={orderData?.authorizeProductSubstitution}
									disabled={disabled}
								/>
							</tr>
						)}
						empty={null}
					/>
					<div className='ml-auto mr-12 w-48'>
						<p className='flex'>
							<span className='flex-1 text-right'>Subtotal:</span>
							<Currency className='flex-1 text-right'>{subtotalAmount}</Currency>
						</p>
					</div>
					<div className='mt-2 flex w-full flex-col border-t border-dashed border-coral-300 pt-2'>
						<MerchantShippingElement
							setDisabled={setDisabled}
							control={control}
							register={register}
							handleSubmit={handleSubmit}
							merchantShipping={merchantShipping}
							orderId={orderId}
							unregister={unregister}
							openModal={openModal}
							setOpenModal={setOpenModal}
							watch={watch}
							setValue={setValue}
							trigger={trigger}
							isValid={isValid}
							errors={errors}
							disabled={disabled}
							updateMerchantShipping={updateMerchantShipping}
							orderData={orderData}
							merchantShippingForm={getValues('merchantShippingForm')}
							merchantShippingIndex={merchantShippingIndex}
							getLazyOrder={getLazyOrder}
						/>
					</div>
				</div>
			</div>
		)
	);
};

const TdOrderItem = ({
	setTotalAmount,
	setSubtotalAmount,
	orderItem,
	orderItemIndex,
	control,
	register,
	setValue,
	getValues,
	watch,
	authorizeProductSubstitution,
	disabled,
}) => {
	const price = orderItem.discountedPrice || orderItem.price;
	const [orderItemAmount, setOrderItemAmount] = useState(price * (orderItem.sentQuantity ?? orderItem.quantity));
	const [disableSubsituted, setDisableSubsituted] = useState(false);

	const returnQuantityStatus = _orderItem => {
		const _sentQuantity = getValues(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`);
		// Changes the status
		if (
			_sentQuantity !== undefined ||
			_sentQuantity !== null ||
			Number.parseInt(_sentQuantity) === _orderItem.quantity
		) {
			return 'Enviado completo';
		} else if (Number.parseInt(_sentQuantity) === 0 || _sentQuantity === '') {
			return 'No disponible';
		} else if (_sentQuantity < _orderItem.quantity) {
			return 'Enviado parcial';
		}
	};

	useEffect(() => {
		// While sentQuantity changes
		const subscription = watch(({ merchantShippingForm }, { name }) => {
			if (name === `merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`) {
				const oldSentQuantity = Number.parseInt(
					merchantShippingForm.orderItems[orderItemIndex].oldSentQuantity
				);
				const newSentQuantity =
					Number.parseInt(merchantShippingForm.orderItems[orderItemIndex].sentQuantity) || 0;

				// Sets sentQuantity field to the requested quantity
				// whenever it is more than the requested quantity
				if (newSentQuantity > orderItem.quantity) {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`, orderItem.quantity);
				}
				// Changes Subsituted value to false whenever `newSentQuantity` is zero
				if (newSentQuantity === 0) {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.substituted`, false);
				}
				if (merchantShippingForm.orderItems[orderItemIndex].sentQuantity === '') {
					setValue(`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`, 0);
				}
				setDisableSubsituted(newSentQuantity === 0);

				// Changes the orderItemAmount
				setOrderItemAmount(price * newSentQuantity);

				// Changes the total and subTotal Amount
				setSubtotalAmount(prev => prev + (newSentQuantity - oldSentQuantity) * price);
				setTotalAmount(prev => prev + (newSentQuantity - oldSentQuantity) * price);

				setValue(`merchantShippingForm.orderItems.${orderItemIndex}.oldSentQuantity`, newSentQuantity);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, orderItem.quantity, orderItemIndex, price, setSubtotalAmount, setTotalAmount, setValue]);

	return (
		<>
			<td className='bg-coral-100 px-4 py-2'>
				<InputForm
					name={`merchantShippingForm.orderItems.${orderItemIndex}.sentQuantity`}
					control={control}
					register={register}
					type='number'
					max={orderItem.quantity}
					min='0'
					width='1'
					required
					disabled={disabled}
				/>
			</td>
			<td className='bg-coral-100 px-4 py-2'>{returnQuantityStatus(orderItem)}</td>
			<td className='bg-coral-100 px-4 py-2 text-center'>
				<CheckForm
					name={`merchantShippingForm.orderItems.${orderItemIndex}.substituted`}
					control={control}
					className='flex justify-center'
					disabled={disabled || disableSubsituted || !authorizeProductSubstitution}
				/>
			</td>
			<td className='px-4 py-2 text-center'>
				<Currency>{orderItemAmount}</Currency>
			</td>
		</>
	);
};

const MerchantShippingElement = ({
	control,
	register,
	handleSubmit,
	merchantShipping,
	setDisabled,
	watch,
	orderId,
	openModal,
	setOpenModal,
	setValue,
	trigger,
	isValid,
	errors,
	disabled,
	updateMerchantShipping,
	orderData,
	merchantShippingForm,
	merchantShippingIndex,
	getLazyOrder,
}) => {
	const [emptyShipping, setEmptyShipping] = useState(false);
	const [updating, setUpdating] = useState(false);
	const { addErrorMessage, addSuccessMessage } = useToast();
	const modalObject = {
		title: 'Confirmación',
		description:
			orderData?.processingMethod === 'shipping' ? (
				<span className='text-lg leading-5 text-gray-500'>{`Al confirmar se enviará la orden de ${merchantShipping?.Merchant.commercialName} a logística y no se podrá anular.`}</span>
			) : (
				<span className='text-lg leading-5 text-gray-500'>{`Al confirmar se enviará el aviso de recogida al cliente de ${merchantShipping?.Merchant.commercialName} y no se podrá anular.`}</span>
			),
		btnText: updating ? 'Preparando...' : 'Confirmar',
		cancelBtnText: 'Cancelar',
	};

	useEffect(() => {
		const subscription = watch(({ merchantShippingForm }) => {
			if (
				merchantShippingForm.orderItems?.every(
					item => Number.parseInt(item.sentQuantity) === 0 || item.sentQuantity === ''
				)
			) {
				setEmptyShipping(true);
			} else {
				setEmptyShipping(false);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		if (emptyShipping) {
			setValue('merchantShippingForm.packages', 0);
			setValue('merchantShippingForm.weight', 0);
		}
	}, [emptyShipping, setValue]);

	const handleModal = async () => {
		if (!isValid && !emptyShipping) {
			// Trigger forces a validation and fills errors
			await trigger(undefined, { shouldFocus: true });
			// We display the required fields
			const requiredFields = errors?.merchantShippingForm
				? Object.keys(errors.merchantShippingForm)
						.map(item => errors.merchantShippingForm[item].message)
						.join(', ')
				: '';
			addErrorMessage('Pedido', `Por favor rellene los campos requeridos: ${requiredFields}`);
		} else {
			setOpenModal(value => !value);
		}
	};

	return (
		<div className='p-6'>
			<div className='flex w-full flex-col lg:flex-row'>
				<TextareaForm
					name='merchantShippingForm.note'
					control={control}
					cols='80'
					className='mt-1 rounded-md border border-coral-300 p-4 text-lg outline-none focus:outline-none'
					placeholder='Escribir aquí posibles incidencias'
					disabled={disabled}
				/>

				<div className='flex w-1/2 flex-col justify-center'>
					<p className='w-full text-left italic text-gray-400'>
						*Número total de bultos que componen la expedición.
					</p>
					<InputForm
						name='merchantShippingForm.packages'
						control={control}
						register={register}
						type='number'
						label='Bultos'
						placeholder='Número de bultos'
						width='1'
						min={emptyShipping ? '0' : 1}
						required={!emptyShipping}
						disabled={disabled || emptyShipping}
					/>
					<p className='w-full text-left italic text-gray-400'>
						*Peso total de la expedición en Kilos sin decimales.
					</p>
					<InputForm
						name='merchantShippingForm.weight'
						control={control}
						register={register}
						type='number'
						label='Peso'
						width='1'
						placeholder='Peso'
						min={emptyShipping ? '0' : 1}
						required={!emptyShipping}
						disabled={disabled || emptyShipping}
					/>
				</div>
			</div>
			<div className='flex justify-end self-end'>
				{!disabled ? (
					<Button className='w-40' disabled={updating} onClick={handleModal}>
						{emptyShipping ? 'Confirma anulación pedido' : 'Pedido listo para recoger'}
					</Button>
				) : (
					<>
						{orderData?.processingMethod === 'shipping' ? (
							<PDFDownloadLink
								document={
									<OrderPdf
										merchantShippingIndex={merchantShippingIndex}
										orderData={orderData}
										merchantShippingData={merchantShippingForm}
									/>
								}
								fileName={`Pedido ${orderData?.MerchantShippings[merchantShippingIndex]?.number}`}
							>
								{({ loading }) =>
									loading ? (
										'Cargando documento...'
									) : (
										<div className='mt-4 block w-40 rounded-md bg-coral-500 py-2 px-3 text-center text-sm font-bold text-white shadow-md transition-all duration-200 hover:bg-coral-700 focus:shadow focus:shadow-outline focus:outline-none'>
											Descargar PDF: {orderData?.MerchantShippings[merchantShippingIndex]?.number}
										</div>
									)
								}
							</PDFDownloadLink>
						) : (
							'Pedido preparado'
						)}
					</>
				)}
			</div>
			<div className='flex justify-end self-end'></div>

			<Modal
				modalObject={modalObject}
				openModal={openModal}
				setOpenModal={setOpenModal}
				disabled={updating}
				handleSubmit={handleSubmit(({ merchantShippingForm }) => {
					setUpdating(true);
					// remove unnecessary value inside orderItem
					merchantShippingForm.orderItems = merchantShippingForm?.orderItems.map(orderItem => ({
						id: orderItem.id,
						substituted: orderItem.substituted,
						sentQuantity: orderItem.sentQuantity,
					}));

					// eslint-disable-next-line no-unused-vars
					const { status, ...merchantShippingData } = merchantShippingForm;
					// send data to API
					updateMerchantShipping({
						variables: {
							OrderId: orderId,
							MerchantId: merchantShipping?.Merchant.id,
							merchantShippingInput: { ...merchantShippingData },
							emptyShipping,
						},
					}).then(({ data }) => {
						setOpenModal(false);
						setUpdating(false);
						if (data.UpdateMerchantShippingAndOrderItems.__typename.endsWith('FTPFault')) {
							addErrorMessage(
								'Pedido',
								<span>
									<p>No se pudo cargar fichero en FTP Logística</p>
									<p>Contactar a Administración</p>
								</span>
							);
						} else if (data.UpdateMerchantShippingAndOrderItems.__typename.endsWith('Fault')) {
							addErrorMessage('Pedido', data.UpdateMerchantShippingAndOrderItems.message);
						} else if (data.UpdateMerchantShippingAndOrderItems.success) {
							setOpenModal(false);
							setValue('merchantShippingForm.status', 'prepared');
							setDisabled(true);
							addSuccessMessage('Pedido', 'Preparado correctamente');
							getLazyOrder();
						}
					});
				})}
			/>
		</div>
	);
};

export default MerchantShippingDetails;
