import React from 'react';
import ProductsList from 'components/ProductsList';

const Products = () => {
	const statusList = [
		{ label: 'Todos', scope: 'defaultScope' },
		{ label: 'Activo', scope: 'active' },
		{ label: 'Inactivo', scope: 'inactive' },
		{ label: 'Revisión', scope: 'revision' },
		{ label: 'Modificado', scope: 'modified' },
		{ label: 'Descatalogado', scope: 'deleted' },
	];

	return <ProductsList statusList={statusList} />;
};

export default Products;
