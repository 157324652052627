import React from 'react';

export const LayoutHeader = ({ children, actions }) => (
	<div className='flex items-center md:mb-4 justify-between'>
		<span className='flex items-center'>
			<SVGIcon />
			<h1 className='md:text-xl md:font-semibold flex-1'>{children}</h1>
		</span>
		{actions && actions.map((action, i) => <div key={`${i}`}>{action()}</div>)}
	</div>
);

export const SectionHeader = ({ className, children }) => (
	<h1 className={`md:text-xl md:font-semibold ${className}`}>{children}</h1>
);

const SVGIcon = ({ className = 'w-4 h-4 mr-2' }) => (
	<svg
		viewBox='0 0 16 16'
		xmlns='http://www.w3.org/2000/svg'
		fillRule='evenodd'
		clipRule='evenodd'
		strokeLinejoin='round'
		strokeMiterlimit='2'
		className={className}
	>
		<path
			d='M16.007 12.042A3.96 3.96 0 0112.047 16H-.002V4A4.007 4.007 0 014.005-.002h12.002v12.045z'
			fill='#ff505b'
		/>
	</svg>
);

export default LayoutHeader;
