import Field from 'components/Field';
import useDebounce from 'lib/useDebounce';
import React, { useState, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { useToast } from 'components/Toast';

const InputForm = ({
	id,
	suffix,
	width = 'w-1/3',
	type = 'text',
	fieldWidth = 'w-full',
	fieldFloat = '',
	children,
	name,
	control,
	register = control.register,
	debounce = false,
	onSubmit = () => {},
	getValues = () => {},
	readOnly,
	...props
}) => {
	const { addErrorMessage } = useToast();
	let { field } = useController({ name, control, rules: { required: props.required ?? false } });
	const [showPassword, setShowPassword] = useState(false);
	const [firstRender, setFirstRender] = useState(true);
	const [bounce, setBouncing] = useState(false);
	const [value, setValue] = useState('');

	if (type === 'number') {
		field = {
			...field,
			...register(name, {
				setValueAs: v => (v === '' || v === null || v === undefined ? '' : Number.parseFloat(v)),
			}),
		};
	}
	const { onChange, ...restField } = field;

	let debouncedInput = null;
	if (debounce) {
		debouncedInput = useDebounce(getValues(name), 1500);
	}

	useEffect(() => {
		setFirstRender(false);
		if (debounce && debouncedInput && !firstRender) {
			onSubmit();
			setBouncing(false);
		}
	}, [debouncedInput]);

	useEffect(() => {
		if (!firstRender && props.required && !value) {
			addErrorMessage(`${props.label}`, 'Campo requerido no puede estar vacío');
			setBouncing(false);
		}
		if (value === debouncedInput) {
			setBouncing(false);
		}
	}, [value?.length]);

	const handleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	// Prevent React Warning about value being null
	if (restField.value === null || restField.value === undefined) {
		restField.value = '';
	}

	if (type === 'password') {
		return (
			<Field width={fieldWidth} float={fieldFloat} {...props}>
				<div
					className={`flex items-center border border-coral-300  ${
						props?.disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
					} rounded-md py-2 px-4 ${width} ${props.className || ''}`}
				>
					<input
						{...restField}
						type={`${showPassword ? 'text' : 'password'}`}
						id={id}
						readOnly={readOnly}
						{...props}
						className={'flex-1 outline-none focus:outline-none'}
						onChange={e => {
							if (!readOnly) {
								onChange(e);
							}
						}}
					/>
					<span onClick={handleShowPassword}>
						<img
							className='w-5'
							alt='toggle password visibility'
							src={`${
								showPassword
									? '/images/form-icons/eye-slash-solid.svg'
									: '/images/form-icons/eye-solid.svg'
							}`}
						/>
					</span>
				</div>
				{children}
			</Field>
		);
	}

	return (
		<Field width={fieldWidth} float={fieldFloat} {...props}>
			<div
				className={`flex items-center border  ${
					props?.disabled ? 'bg-gray-300 text-gray-500' : 'bg-white'
				} rounded-md py-2 px-4 ${width} ${readOnly ? 'border-gray-500 text-gray-500' : 'border-coral-300'} ${
					props.className || ''
				}`}
			>
				<input
					{...restField}
					type={type}
					id={id}
					{...props}
					readOnly={readOnly}
					className='flex-1 outline-none focus:outline-none'
					onChange={e => {
						if (debounce) {
							setValue(e.target.value);
							setBouncing(true);
						}
						if (!readOnly) {
							onChange(e);
						}
					}}
					onWheel={e => {
						if (type === 'number') {
							e.target.blur();
						}
					}}
				/>
				{suffix}
				{bounce && (
					<div>
						<div className='w-4 h-4 border-l-4 border-b-2 border-coral-500 border-solid rounded-full animate-spin'></div>
					</div>
				)}
			</div>
			{children}
		</Field>
	);
};

export default InputForm;
