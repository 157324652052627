import React from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import LayoutHeader from 'components/LayoutHeader';
import DottedElement from 'components/DottedElement';

const Help = () => {
	return (
		<>
			<Card>
				<LayoutHeader>Ayuda</LayoutHeader>
				<div
					style={{ backgroundImage: 'url("/images/help/first-steps-background.svg")' }}
					className='mb-10 flex w-full justify-center border bg-2000 bg-center p-10 shadow'
				>
					<Button className='uppercase'>Primeros pasos gestión plataforma</Button>
				</div>
				<DottedElement
					title='Crear usuario'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Gestionar Mi cuenta'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Devoluciones y reembolso'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Gestionar Mis pedidos'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Cancelar productos o pedidos'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Modificar pedidos en curso'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Resolver problemas con el método de pago'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					button
				/>
				<DottedElement
					title='Añadir y gestionar métodos de pago'
					description='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer gravida massa fringilla risus sodales,
				ultricies aliquet massa vehicula. Curabitur scelerisque velit fermentum libero accumsan, at consectetur
				nibh bibendum.'
					bottom='border-b-2'
					button
				/>
			</Card>
		</>
	);
};

export default Help;
