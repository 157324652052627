import React from 'react';
import NumberFormat from 'react-number-format';

const Currency = props => (
	<span className={props.className}>
		<NumberFormat
			value={props.children}
			displayType={'text'}
			thousandSeparator='.'
			decimalSeparator=','
			fixedDecimalScale={true}
			decimalScale={2}
			suffix=' €'
			{...props}
		/>
	</span>
);

export default Currency;
