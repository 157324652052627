import Button from 'components/Button';
import Card from 'components/Card';
import ContentTable from 'components/ContentTable';
import LayoutHeader from 'components/LayoutHeader';
import LoadingView from 'components/LoadingView';
import TooltipMenu from 'components/TooltipMenu';
import {
	plainCategoriesChildren,
	useProductCategories,
	useProductCategoryDeleteMutation,
} from 'hooks/use-product-categories';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useToast } from 'components/Toast';

const CreateCategoryButton = () => (
	<Button as={Link} to='/dashboard/catalogo/categorias/new'>
		Nueva Categoría
	</Button>
);

const Categories = () => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { data, ...rest } = useProductCategories();

	const [productCategories, setProductCategories] = useState([]);

	const [deleteProductCategory] = useProductCategoryDeleteMutation();

	useEffect(() => {
		if (data && data.ProductCategories) {
			setProductCategories(data.ProductCategories);
		}
	}, [data]);

	const getCategoryMenuList = id => [
		{ title: 'Editar', href: `/dashboard/catalogo/categorias/edit/${id}` },
		{
			title: 'Eliminar',
			onClick: () => {
				deleteProductCategory({ variables: { id: id } }).then(({ data: { ProductCategoryDelete } }) => {
					if (ProductCategoryDelete && ProductCategoryDelete.__typename === 'Success') {
						setProductCategories(productCategories.filter(item => item.id !== id));
						addSuccessMessage('Categoría', 'eliminada correctamente');
					}
					// All Errors implement Fault
					else if (ProductCategoryDelete && ProductCategoryDelete.__typename.endsWith('Fault')) {
						addErrorMessage('Error', ProductCategoryDelete.message);
					}
				});
			},
		},
		{ title: 'Promocionar categoria', onClick: () => {} },
	];

	return (
		<Card>
			<LayoutHeader actions={[CreateCategoryButton]}>Categorías</LayoutHeader>
			<LoadingView whileLoading='Buscando categorías' {...rest}>
				<ContentTable
					columns={['Categoría', '']}
					content={plainCategoriesChildren(productCategories && productCategories)}
					render={(c, index) => (
						<tr
							key={index}
							className={`text-gray-700 border-t border-dashed border-coral-300 ${
								c.Parent === null ? 'font-bold text-base' : 'text-sm'
							}`}
						>
							<td className='px-4 py-2'>
								<Link to={`/dashboard/catalogo/categorias/edit/${c.id}`}>{c.name}</Link>
							</td>
							<td className='px-6'>
								<div className='h-16 mr-2 float-right'>
									<TooltipMenu menuList={getCategoryMenuList(c.id)} />
								</div>
							</td>
						</tr>
					)}
					empty={
						<p className='h-24 flex items-center text-gray-700 my-5 w-full justify-center border border-gray-400 rounded-md shadow-md'>
							Crea tu primera categoría
						</p>
					}
				/>
			</LoadingView>
		</Card>
	);
};

export default Categories;
