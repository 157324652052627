import React, { useEffect, useState } from 'react';
import { useLazyExportProducts } from 'hooks/use-products';
import { CSVDownload } from 'react-csv';
import { useToast } from 'components/Toast';
import Button from 'components/Button';
import formatDate from 'lib/formatDate';
import formatTime from 'lib/formatTime';

const DownloadCSVButton = ({ queryProps }) => {
	const { addSuccessMessage, addErrorMessage } = useToast();
	const [products, setProducts] = useState({});
	const [download, setDownload] = useState(false);
	const [getLazyExportProducts, { data, loading }] = useLazyExportProducts(queryProps);

	useEffect(() => {
		if (data) {
			if (data.Products?.__typename.endsWith('Fault')) {
				addErrorMessage('Productos', data?.Products.message);
			}
			if (data.Products?.__typename === 'ProductList') {
				const productsToExport = [];
				for (const product of data.Products.List) {
					const categories = product.Categories?.map(category => category.name).join('/');
					for (const variant of product.Variants) {
						productsToExport.push({
							Id: product.id,
							Categories: categories,
							Title: product.title,
							VariantTitle: variant.title,
							Price: variant.price,
							DiscountedPrice: variant.discountedPrice,
							EAN: variant.barcode,
							Sku: variant.sku,
							Promote: product.promote,
							Highlight: product.highlight,
							Status: product.status,
							Url: product.handle,
							IsAvailable: variant.isAvailable,
							Unit: product.unit,
							Brand: product.brand,
							Type: product.type,
							Vat: product.vat,
							CreatedAt: `${formatDate(product.createdAt)}, ${formatTime(product.createdAt)}`,
							UpdatedAt: `${formatDate(product.updatedAt)}, ${formatTime(product.updatedAt)}`,
						});
					}
				}
				addSuccessMessage('Productos', 'Fichero creado');
				setProducts(productsToExport);
				setDownload(true);
			}
		}
	}, [data, addErrorMessage, addSuccessMessage, setProducts]);

	const getProducts = () => {
		setDownload(false);
		getLazyExportProducts();
	};

	return (
		<>
			<Button className='mb-4' onClick={getProducts}>
				{loading ? 'Generando CSV...' : 'Exportar productos a CSV'}
			</Button>
			{download && <CSVDownload data={products} target='_blank' />}
		</>
	);
};

export default DownloadCSVButton;
