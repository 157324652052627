import Card from 'components/Card';
import LanguageTabs from 'components/LanguageTabs';
import LayoutHeader from 'components/LayoutHeader';
import { useToast } from 'components/Toast';
import { useProductCategoryCreateMutation } from 'hooks/use-product-categories';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import CategoryForm from './CategoryForm';
import useAuth from 'hooks/use-auth';

const CategoryNew = () => {
	const navigate = useNavigate();
	const { addSuccessMessage, addErrorMessage } = useToast();
	const { locale, setLocale } = useAuth();
	// To create new category has to be default locale
	setLocale(process.env.REACT_APP_DEFAULT_LOCALE);

	const [createCategory, { loading: isSubmitting }] = useProductCategoryCreateMutation({
		onCompleted: ({ ProductCategoryCreate }) => {
			if (ProductCategoryCreate.__typename.endsWith('Fault')) {
				addErrorMessage('Crear categoría', ProductCategoryCreate.message);
			} else if (ProductCategoryCreate.__typename === 'ProductCategory') {
				addSuccessMessage('Categoría', 'creada correctamente');
				navigate(`/dashboard/catalogo/categorias/edit/${ProductCategoryCreate.id}`);
			}
		},
	});

	const handleSubmit = values => {
		createCategory({ variables: { locale, input: values } });
	};

	return (
		<>
			<LanguageTabs disabled={true} />
			<Card>
				<LayoutHeader>Crear Categoría</LayoutHeader>
				<CategoryForm onSubmit={handleSubmit} isSubmitting={isSubmitting} />
			</Card>
		</>
	);
};

export default CategoryNew;
